import React from 'react';

import PropTypes from 'prop-types';

// MaterialUI
import { withStyles } from '@material-ui/core/styles';

// Components
import Spinner from '../../common/Spinner';

const styles = () => ({
  card: {
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '30px 15px',
    transition: '.5s',
    position: 'relative',
    border: '1px solid rgba(9,9,9,.1)',
    "&:hover": {
      boxShadow: '0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22)',
    },
  },
  header: {
    display: 'block',
    position: 'relative',
    zIndex: '0',
    paddingBottom: '15px',
    marginBottom: '15px',
    "&:after": {
      content: '""',
      position: 'absolute',
      height: '1px',
      width: '100%',
      zIndex: '1',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: '#f3f4f6',
    },
  },
  popular: {
    position: 'absolute',
    width: 'auto',
    color: '#fff',
    top: '60px',
    right: '-15px',
    zIndex: '10',
    fontSize: '14px',
    background: '#029cea',
    fontWeight: '500',
    textTransform: 'uppercase',
    padding: '5px 10px',
    "&:after": {
      content: '""',
      position: 'absolute',
      top: '-15px',
      right: '0px',
      borderBottom: '15px solid #029cea',
      borderRight: '15px solid transparent',
    },
  },
  title: {
    color: 'black',
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '30px',
    margin: '0px',
    fontFamily: 'Raleway',
  },
  pricing: {
    position: 'relative',
    zIndex: '0',
    padding: '15px 0px',
    marginBottom: '15px',
    "&:after": {
      content: '""',
      position: 'absolute',
      height: '1px',
      width: '100%',
      zIndex: '1',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: '#f3f4f6',
    },
  },
  price: {
    position: 'relative',
    display: 'inline-block',
    fontSize: '28px',
    lineHeight: '0px',
    margin: '0px auto',
  },
  originalPrice: {
    color: '#999',
    fontWeight: '400',
    marginRight: '5px',
  },
  salePrice: {
    color: '#029cea',
    fontWeight: '400',
    marginRight: '5px',
  },
  currency: {
    fontSize: '24px',
  },
  spinner: {
    textAlign: 'center',
  },
  benefits: {
    padding: '0px 15px',
  },
  listItem: {
    display: 'block',
    width: '100%',
    height: 'auto',
    padding: '10px 0px',
    borderBottom: '1px solid rgba(9,9,9,.04)',
    color: 'black',
    fontSize: '14px',
    fontFamily: 'Raleway',
    fontWeight: '400',
    "&:last-child": {
      border: 'none',
    },
  },
  listItemIcon: {
    color: 'black',
  },
  footer: {
    marginTop: '20px',
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
    backgroundColor: '#029cea',
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
  },
});
class PackageCard extends React.PureComponent {
  render() {
    const {
      ungating,
      classes,
    } = this.props;

    if (!ungating) {
      return (
        <div className={classes.spinner}>
          Loading…<Spinner/>
        </div>
      );
    }

    return (
      <div className={classes.card}>
        { 
          ungating.popular && (
            <div className={classes.popular}>
              Popular
            </div>
          )
        }
        {/* Header */}
        <div className={classes.header}>
          <h2 className={classes.title}>
            {ungating.name}
          </h2>
        </div>

        {/* Pricing */}
        <div className={classes.pricing}>
          <span className={classes.price}>
            <del className={classes.originalPrice}>
              <span className={classes.currency}>$</span>
              {ungating.cost + 100}
            </del>
            <span className={classes.salePrice}>
              <span className={classes.currency}>$</span>
              {ungating.cost}
            </span>
          </span>
        </div>

        {/* Benefits */}
        <div className={classes.benefits}>
          <ul
            className="fa-ul"
            style={{ margin: 0 }}
          >
          {
            ungating.benefits.split("\n").map((row, i) => (
              <li
                key={i}
                className={classes.listItem}
              >
                <i
                  style={{
                    color: 'black',
                    fontSize: '18px',
                    marginRight: '6px',
                  }}
                  className="fas fa-check"
                ></i>{row}
              </li>
            ))
          }
          </ul>
        </div>

        {/* Footer */}
        <div className={classes.footer}>
          <a
            href={`https://www.go-atlas.io/atlas-access-checkout?categoryOrBrandId=${ungating._id}`}
            target="_PARENT"
            className="btn"
            style={{
              width: '100%',
              height: 'auto',
              padding: '12px 25px',
              color: 'white',
              fontSize: '20px',
              fontWeight: '600',
              whiteSpace: 'break-spaces',
            }}
          >
            Get Ungated Now
          </a>
        </div> 
      </div>
    );
  }
}

PackageCard.propTypes = {
  ungating: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackageCard);
