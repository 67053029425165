import axios from 'axios';

import {
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING_PAGE,
  GET_REPORT,
  STOP_LOADING_PAGE
} from './types';

export const skuAnalysisReport = (startdate, enddate) => dispatch => {
    dispatch(clearErrors());
    dispatch(setLoading());
    startdate = new Date(startdate);
    enddate = new Date(enddate);
    axios
      .get('/api/reports/skuanalysisreport/' + startdate + '/' + enddate)
      .then(res =>
        dispatch({
          type: GET_REPORT,
          payload: res.data
        }, dispatch(stopLoading()))
      )
      .catch(err => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
      });
};

export const profitLossReport = (startdate, enddate) => dispatch => {
    dispatch(clearErrors());
    dispatch(setLoading());
    startdate = new Date(startdate);
    enddate = new Date(enddate);
    axios
      .get('/api/reports/profitlossreport/' + startdate + '/' + enddate)
      .then(res =>
        dispatch({
          type: GET_REPORT,
          payload: res.data
        }, dispatch(stopLoading()))
      )
      .catch(err => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
      });
};


export const inventoryValueReport = () => dispatch => {
    dispatch(clearErrors());
    dispatch(setLoading());
    axios
      .get('/api/reports/inventoryValueReport')
      .then(res =>
        dispatch({
          type: GET_REPORT,
          payload: res.data
        }, dispatch(stopLoading()))
      )
      .catch(err => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
      });
};

export const setLoading = () => {
  return {
    type: LOADING_PAGE
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING_PAGE
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
