import React, { PureComponent } from "react";

// MaterialUI
import {
  Dialog,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '100%',
  },
  closeButton: {
    right: theme.spacing.unit * 1,
    top: theme.spacing.unit * 1,
    color: theme.palette.grey[500],
    outline: 'none !important',
  },
});

class CustomModal extends PureComponent {
  render() {
    const {
      classes,
      open,
      children,
      headerText,
      onClose,
    } = this.props;
    return (
      <Dialog open={open}
        onClose={onClose}
      >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6" className={classes.title}>{headerText}</Typography>
          {onClose ? (
            <IconButton aria-label="Close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        {children}
      </Dialog>
    );
  }
}

export default (withStyles(styles)(CustomModal));
