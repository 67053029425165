import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {Typography,DialogActions} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button: {
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
    textTransform: 'none',
    margin: 0,
  },
  input: {
    display: 'none',
  },
  table: {
    width:"100%",
    tableLayout: 'auto'
  },
  cell: {
    verticalAlign: 'top',
    maxWidth:'200px',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "10px",
  },
  closeButton: {
  position: 'absolute',
  right: theme.spacing.unit,
  top: theme.spacing.unit,
  color: theme.palette.grey[500],
},
  paper: {
  position: 'absolute',
  width: theme.spacing.unit * 50,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing.unit * 4,
  outline: 'none',
}
});

class FormDialogComp extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, shipment, inventory } = this.props;
    let totWeight = 0; let weightUnits = 'pounds'; let dimUnits = 'IN';
    let totSize = 0;
    for (let i = 0; i < shipment.shippingInfo.packages.length; i++) {
      shipment.shippingInfo.packages[i].weight?(totWeight += shipment.shippingInfo.packages[i].weight):totWeight += 0;
      weightUnits = shipment.shippingInfo.packages[i].units;
      if (shipment.shippingInfo.packages[i].dimensions){
        dimUnits = shipment.shippingInfo.packages[i].dimensions.Unit
        totSize+=(shipment.shippingInfo.packages[i].dimensions.Height*shipment.shippingInfo.packages[i].dimensions.Width*shipment.shippingInfo.packages[i].dimensions.Length);
      }
    }
    let useWeight = (totSize/166 < totWeight); //taken from fedex
    let skuWeights = 0;
    let skuDims = 0;
    for (let i = 0; i < shipment.skus.length; i++) {
      for (let j = 0; j < inventory.inventory.length; j++) {
        let f = inventory.inventory[j];
        if (f.sku === shipment.skus[i].sku) {
          if (f.packageDimensions) {
            let h = f.packageDimensions.Height.Value;
            let w = f.packageDimensions.Width.Value;
            let l = f.packageDimensions.Length.Value;
            let weight = f.packageDimensions.Weight.Value;
            skuWeights += Number(weight*shipment.skus[i].shipped);
            skuDims += Number(w * l * h*shipment.skus[i].shipped);
          }
        }
      }
    }

    let modalContent;
    let onClose=this.handleClose;
    if (this.state.open){
      modalContent = (
        <Dialog
          TransitionComponent={(props)=>{ return <Slide direction="up" {...props} />;}}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={'xl'}
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Shipment {shipment.shipmentId}</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>

          <DialogContent>
          <div className={classes.holder}>
          <br/>
          <table className={classes.table}>

          <tbody >
          <tr>
          <td className={classes.cell} align="left">
          <strong>Shipment Name:</strong> {shipment.name} <br/>
          <strong>Shipment ID:</strong> {shipment.shipmentId} <br/>
          <strong>Shipment Cost:</strong> ${shipment.shippingInfo.tcost} <br/>
          <strong>FBA Warehouse:</strong> {shipment.fc} <br/>
          </td>
          <td className={classes.cell} align="left">
          <strong>Ship From Address:</strong><br/>
           {shipment.address.name?shipment.address.name:null}<br/>
           {shipment.address.a1?shipment.address.a1:null}&nbsp;
           {shipment.address.a2?shipment.address.a2:null}&nbsp;
           {shipment.address.a3?shipment.address.a3:null}<br/>
           {shipment.address.city?shipment.address.city:null},&nbsp;
           {shipment.address.state?shipment.address.state:null}&nbsp;
           {shipment.address.zip?shipment.address.zip:null}
          </td>
          <td className={classes.cell} align="left">
            <strong>Shipment Type:</strong> {shipment.shippingInfo.tType} <br/>
            <strong>Total {(shipment.shippingInfo.tType && shipment.shippingInfo.tType.includes("LTL"))?'Pallets':'Boxes'}:</strong> {shipment.shippingInfo.packages.length} <br/>
            <strong>Total Weight:</strong> {totWeight.toFixed(2)+' '+weightUnits}
           <br/>
            <strong>Total Size:</strong> <span>{totSize.toFixed(2)+' '+ dimUnits}<sup>3</sup></span> {dimUnits==="IN"?(<span>({(totSize/12/12/12).toFixed(2)} FT<sup>3</sup>)</span>):null} <br/>

          </td>
          </tr>
          </tbody>
          </table>
          <br/><br/>
          <div className="table-responsive">
             <table className={classes.table}>
               <thead>
                 <tr>
                   <th>SKU</th>
                   <th>FNSKU</th>
                   <th>Title</th>
                   <th>Shipped</th>
                   <th>Receieved</th>
                   <th>Cost/Unit</th>
                   <th>Prep</th>
                 </tr>
               </thead>
               <tbody >
               {shipment.skus.map((e,i) => (
                 <tr key={i}>
                     <td className={classes.cell} align="left">{e.sku}</td>
                     <td className={classes.cell} align="left">{e.fnsku}</td>
                     <td className={classes.cell} align="left">{
                       inventory.inventory.map((f,j) => {
                         if (f.sku===e.sku){
                           return f.title
                         } else return null
                       })
                     }</td>
                     <td className={classes.cell} align="left">{e.shipped}</td>
                     <td className={classes.cell} align="left">{e.received}</td>
                     <td className={classes.cell} align="left">{
                       inventory.inventory.map((f,j) => {
                         if (f.sku===e.sku){
                          if (f.packageDimensions) {
                            let h = f.packageDimensions.Height.Value;
                            let w = f.packageDimensions.Width.Value;
                            let l = f.packageDimensions.Length.Value;
                            let weight = f.packageDimensions.Weight.Value;
                            let cpu = weight*e.shipped/skuWeights*shipment.shippingInfo.tcost/e.shipped; //cost per unit
                            let dcpu = (l*h*w)*e.shipped/skuDims*shipment.shippingInfo.tcost/e.shipped;    //cost per dimensional unit
                            cpu = (isFinite(cpu))?cpu:0;
                            dcpu = (isFinite(dcpu))?dcpu:0;
                            return useWeight?('$'+cpu.toFixed(2)):('$'+dcpu.toFixed(2))
                          } else return null
                         } else return null
                       })
                     }</td>
                     <td className={classes.cell} align="left">{(e.prep && e.prep.instruction !== 'undefined'&& e.prep.owns !== 'undefined')?(e.prep.instruction+' by '+e.prep.owns):'None'}</td>
                 </tr>
               ))
               }
               </tbody>
             </table>
             </div>

            </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = (
        <div></div>
      );
    }
    return (
      <div>
        <Button className={classes.button} onClick={this.handleClickOpen}
        variant="contained" color="primary">
          {shipment.name}
        </Button>
        {modalContent}
      </div>
    );
  }
}

FormDialogComp.propTypes = {
  classes: PropTypes.object.isRequired,
};


class RemoveSKUPopComp extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, i, sku, title} = this.props;
    let modalContent;

    let onClose=this.handleClose;
    if (this.state.open){
      modalContent = (
        <Dialog
          TransitionComponent={(props)=>{ return <Slide direction="up" {...props} />;}}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Remove SKU from Shipment</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <DialogContent>
        Are you sure you want to remove SKU:<strong> {sku} ({title.substring(0,30)}...) </strong> from this shipment?
        </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              No
            </Button>
            <Button onClick={()=>{this.props.removeSku(i);this.handleClose();}} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      modalContent = (
        <div></div>
      );
    }
    return (
      <div>
      <Button style={{minWidth:'20px'}}
      className={classes.button}
      onClick={this.handleClickOpen}>
      <i  className="fas fa-times-circle"></i>
      </Button>
      {modalContent}
      </div>

    );
  }
}

RemoveSKUPopComp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const RemoveSKUPop = withStyles(styles)(RemoveSKUPopComp);
const FormDialog = withStyles(styles)(FormDialogComp);
export {RemoveSKUPop,FormDialog};
