import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import {
  toast
} from 'react-toastify';

import {
  GET_ERRORS,
  GET_RESPONSE,
  LOADING_PAGE,
  CLEAR_ERRORS,
  SET_CURRENT_USER,
  STOP_LOADING_PAGE
} from './types';


export const getAffiliatePayments = () => dispatch => {
  dispatch(setLoading());
  dispatch(clearErrors());
  axios
    .get('/api/affiliate/getAffiliatePayments')
    .then(res => {
      dispatch(clearErrors());
      dispatch({
        type: GET_RESPONSE,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      }
    });
};

export const changePaypalEmail = (paypalEmail) => dispatch => {
  dispatch(setLoading());
  dispatch(clearErrors());
  axios
    .post('/api/affiliate/changePaypalEmail',paypalEmail)
    .then(res => {
      // Save to localStorage
      const {
        token
      } = res.data;
      // Set token to ls
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded))
      toast.success("PayPal Email Changed!");
      dispatch({
        type: GET_RESPONSE
      })
    })
    .catch(err => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      }
    });
};


export const affSignUp = () => dispatch => {
  dispatch(setLoading());
  dispatch(clearErrors());
  axios
    .get('/api/affiliate/affSignUp')
    .then(res => {
      // Save to localStorage
      const {
        token
      } = res.data;
      // Set token to ls
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded))
      toast.success("You're an affiliate now!");
      dispatch({
        type: GET_RESPONSE
      })
    })
    .catch(err => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      }
    });
};


export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};


export const setLoading = () => {
  return {
    type: LOADING_PAGE
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING_PAGE
  };
};


// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
