import {
  GET_USER_LEADS,
  LEADS_LOADING,
} from '../actions/types';

const initialState = {
  loading: false,
  leads: {
    flavor: null,
    date: null,
    csv: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LEADS_LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_USER_LEADS:
      return {
        ...state,
        loading: false,
        leads: action.payload
      }
    default:
      return state;
  }
}
