import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button: {
    height: '32px',
    marginTop: '-3px',
    border: '0px',
    textTransform: "none",
    fontWeight: "normal",
    fontSize: '18px',
    lineHeight: '20px',
    // padding:"2px",
    marginLeft: "10px",
    backgroundColor: '#149cea',
    "&:hover": {
      border: '0px',
      backgroundColor: "#132f4a"
    },
    "&:disabled": {
      border: '0px',
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white'

  },
  cell: {
    whiteSpace: "wrap",
    lineHeight: '15px',
    height: '35px',
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "5px",
    maxHeight: '20px',
    whiteSpace: 'nowrap',
    maxWidth: '230px',
  },
  innerCell: {
    whiteSpace: "wrap",
    lineHeight: '15px',
    height: '15px',
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: '20px',
    whiteSpace: 'nowrap',
    minWidth: '220px',
  },
  table: {
    tableLayout: 'auto',
  },
  tableWrapper: {
    padding: '2px',
    overflowX: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  textField: {
    margin: "0px",
    maxWidth: "50px",
    padding: '2px',
  },
});

class AddProductModal extends React.Component {
  state = {
    open: false,
    asinList:[]
  };

  handleClickOpen = () => {
    this.setState({ open: true });
    let {selected} = this.props
    let asinList = []
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].ASIN)
        asinList.push({
          notes:selected[i]['"Variations,pack,size & Other Comment"'],
          title:selected[i].Description,
          sku:this.skuMaker(10),
          asin: selected[i].ASIN,
          buyPrice: parseFloat(selected[i]["Buy Price"].toString().replace(/\$|,/g, '')),
          supplier: selected[i]["Store"],
          listPrice: parseFloat(selected[i]["Amazon Price"].toString().replace(/\$|,/g, '')),
          buyLink: selected[i]["Product Link"],
          batteries:"No",
          hazmat:"No"
        })
    }
    this.setState({asinList})
  };

  skuMaker = (length) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length - 1; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result.substr(0, 2) + '-' + result.substr(2, 4) + '-' + result.substr(5, 6);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    const target = event.target;
    let {asinList} = this.state;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (isNaN(value) && (name === 'buyPrice' || name === 'listPrice')) {
      return
    }
    for (let i = 0; i < asinList.length; i++) {
      if (target.id === asinList[i].asin){
        asinList[i][name] = value;
        this.setState({
          asinList
        })
      }
    }
  }

  render() {
    const { classes,selected,addToInventory,numSelected } = this.props;
    const { asinList } = this.state;
    let modalContent;
    let onClose=this.handleClose;
    if (this.state.open && asinList.length>0){
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Welcome to 1-Click & List!</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>

          <DialogContent>
          <div>
            Look over the info below then hit the <strong>"1-Click & List"</strong> button at the bottom and all
            your products will be added to your Amazon FBA inventory! (You'll still have to purchase them, though)
          </div>
          <div align={'center'}>
            {asinList.map((row, i) => {
              return (
                    <div key ={i+'main'}>
                    <br/>
                    <div key ={i+'prod'} align={'center'} style={{padding:'2px',fontSize:'14px',width:'390px'}} className={classes.tableWrapper}>

                    <table align={'left'} style={{width:'386px'}} key={i+'tab'} className={classes.table} >
                    <thead key={i+'head'}style={{lineHeight:'20px',height:'20px',textOverflow:'hidden',textAlign:'left'}}>
                      <tr>
                        <th className={classes.cell}><div align="right"><strong>{row.asin}: </strong></div></th>
                        <th className={classes.cell}><div align="left" className={classes.innerCell}>{row.title}</div></th>
                      </tr>
                     </thead>
                    <tbody key={i+'body'} style={{padding:'2px',border: '1px solid #dddddd'}}>
                      <tr key={i+'-sku'}>
                        <td className={classes.cell} align="right">SKU:</td>
                        <td className={classes.cell}  align="left"><input
                              id={row.asin}
                              name={"sku"}
                              value={row.sku}
                              onChange={this.handleChange}
                            />
                        </td>
                      </tr>
                      <tr key={i+'-listPrice'}>
                        <td className={classes.cell} align="right">Sell At:</td>
                        <td className={classes.cell}  align="left"><input
                              id={row.asin}
                              className={classes.textField}
                              name={"listPrice"}
                              value={row.listPrice}
                              onChange={this.handleChange}
                            />
                        </td>
                      </tr>
                      <tr key={i+'-buyprice'}>
                        <td className={classes.cell} align="right">Bought At:</td>
                        <td className={classes.cell}  align="left"><input
                              id={row.asin}
                              className={classes.textField}
                              name={"buyPrice"}
                              value={row.buyPrice}
                              onChange={this.handleChange}
                            />
                        </td>
                      </tr>
                      <tr key={i+'-batteries'}>
                        <td className={classes.cell} align="right">Has Batteries:</td>
                        <td className={classes.cell}  align="left">
                        {row.batteries}*
                        </td>
                      </tr>
                      <tr key={i+'-hazmat'}>
                        <td className={classes.cell} align="right">Hazmat Product:</td>
                        <td className={classes.cell}  align="left">
                        {row.hazmat}*
                        </td>
                      </tr>
                    </tbody>
                    </table>
                    </div>
                    </div>
                  )
            })}
            <div style={{fontSize:'11px'}}>
            *If a product has batteries or is hazmat, you will have to add them manually in Seller Central
            </div><br/>
            <Button style={{
              border: '0px',
              textTransform: "none",
              fontWeight: "normal",
              fontSize: '25px',
              width:'80%',
              backgroundColor: '#149cea',
              "&:hover": {
                border: '0px',
                backgroundColor: "#132f4a"
              },
              "&:disabled": {
                border: '0px',
                color: 'white',
                backgroundColor: '#919EAA',
              },
              color: 'white'

            }} onClick={()=>{addToInventory(asinList);this.setState({ open: false });}}>
              1-Click & List
            </Button>
            </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = (
        <div></div>
      );
    }
    return (
      <div>
        <Button className={classes.button} onClick={this.handleClickOpen}>
          1-Click & List {numSelected} Product{numSelected < 2?null:'s'}
        </Button>
        {modalContent}
      </div>
    );
  }
}

AddProductModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddProductModal);
