import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Table,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Link,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    fontSize: "1rem",
  },
  button: {
    fontSize: "14px",
    fontWeight: "normal",
    margin: theme.spacing.unit,
  },
  saveButton: {
    backgroundColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
    },
    color: "white",
    "&:hover": {
      color: "white",
    },
    textTransform: "none",
    marginTop: "5px",
    display: "inline",
    whiteSpace: "wrap",
    overflow: "hidden",
    width: "100%"
  },
  textField: {
    margin: "2px",
    width: "75px",
    fontSize: "1rem",
  },
  input: {
    display: "none",
  },
  table: {
    width: "100%",
    tableLayout: "auto",
    fontSize: "1rem",
  },
  cell: {
    fontSize: "1rem",
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    maxHeight: "10px",
    width: "200px",
    overflow: "hidden",
    padding: "5px",
    height: "10px",
  },
  greencell: {
    color: "green",
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    maxHeight: "10px",
    width: "200px",
    overflow: "hidden",
    padding: "15px",
    height: "10px",
  },
  redcell: {
    color: "red",
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    maxHeight: "10px",
    width: "200px",
    overflow: "hidden",
    padding: "15px",
    height: "10px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },

  orderArr: {
    borderTop: "1px solid #D3D3D3",
  },
  // profit: {
  //   background: 'linear-gradient(0deg, #0d8f15, #12b91d)',
  // },
  // loss: {
  //   background: 'linear-gradient(0deg, #8f0d0d, #b91212)',
  // }
});

const redTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: { primary: { main: "#d32f2f" } },
});
const greenTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: { primary: { main: "#149cea" } },
});

class FormDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  sendBuyPrice = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    let buyPrice = 0;
    let salesTaxCost = 0;
    let shippingFee = 0;
    let miscFee = 0;
    if (!isNaN(data.get("Buy Price"))) {
      buyPrice = data.get("Buy Price");
    }
    if (!isNaN(data.get("Sales Tax"))) {
      salesTaxCost = data.get("Sales Tax");
    }
    if (!isNaN(data.get("Shipping"))) {
      shippingFee = data.get("Shipping");
    }
    if (data.get("Prep / Misc")) {
      miscFee = data.get("Prep / Misc");
    }

    let postData = {
      orderId:this.props.orderInfo.orderId,
      sku: data.get("sku"),
      buyPrice,
      salesTaxCost,
      shippingFee,
      miscFee,
    };
    
    this.props.saveOrder(postData);
  };

  render() {
    const { classes } = this.props;
    let modalContent;
    let onClose = this.handleClose;
    let breakdown = [];
    let inv = {};
    let quantity = 1;
    let totalBuyPrice = 0;
    let totalPurchasePrice = 0;
    for (let i = 0; i < this.props.orderInfo.skus.length; i++) {
      let e = this.props.orderInfo.skus[i];
      if (e.quantity) quantity = e.quantity;
      if (this.props.inventory[i][0]) inv = this.props.inventory[i][0];
      if (e.purchasedPrice) {
        totalPurchasePrice += e.purchasedPrice;
        breakdown.push({
          color: "green",
          label: "Item Total",
          content: e.purchasedPrice,
        });
      } else if (
        inv &&
        inv.pricing &&
        inv.pricing.mine &&
        inv.pricing.mine.price
      ) {
        breakdown.push({
          color: "green",
          label: "Item Total",
          content: Number(inv.pricing.mine.price),
        });
        totalPurchasePrice += Number(inv.pricing.mine.price);
      }
      if (e.shippingCharge)
        breakdown.push({
          color: "green",
          label: "Shipping Charge",
          content: e.shippingCharge,
        });
      if (e.marketplaceTax)
        breakdown.push({
          color: "green",
          label: "Marketplace Tax",
          content: e.marketplaceTax,
        });
      if (e.giftWrap)
        breakdown.push({
          color: "green",
          label: "Gift Wrap",
          content: e.giftWrap,
        });
      // if (e.shippingTax)
      //   breakdown.push({
      //     color: "green",
      //     label: "Shiping Tax",
      //     content: e.shippingTax,
      //   });
      //expenses
      if (e.buyPrice) {
        //if buyprice is in the order, use it, otherwise check inventory?
        totalBuyPrice += -1 * Number(e.buyPrice * quantity);
      }
      breakdown.push({
        color: "red",
        label: "Buy Price",
        content: -1 * Number(e.buyPrice || 0 * quantity),
      });
      //  else if (this.props.inventory && this.props.inventory[i][0] && this.props.inventory[i][0].buyPrice){
      //   totalBuyPrice += -1*Number(this.props.inventory[i][0].buyPrice*quantity);
      //    breakdown.push({color:"red",label:'Buy Price',content:-1*Number(this.props.inventory[i][0].buyPrice*quantity)});
      //  }
      
        breakdown.push({
          color: "red",
          label: "Sales Tax",
          content: -1 * Number(e.salesTaxCost || 0),
        });
      
        breakdown.push({
          color: "red",
          label: "Shipping",
          content: -1 * Number(e.shippingFee || 0),
        });
      
        breakdown.push({
          color: "red",
          label: "Prep / Misc",
          content: -1 * Number(e.miscFee || 0),
        });

      if (e.fbaFee) {
        breakdown.push({ color: "red", label: "FBA Fee", content: e.fbaFee });
      } else if (inv.fees && inv.fees.FBAFees) {
        breakdown.push({
          color: "red",
          label: "FBA Fee",
          content: Number(inv.fees.FBAFees),
        });
      }
      if (e.referalFee) {
        breakdown.push({
          color: "red",
          label: "Referal Fee",
          content: e.referalFee,
        });
      } else if (
        inv.fees &&
        inv.fees.ReferralFee &&
        this.props.status === "Pending"
      )
        breakdown.push({
          color: "red",
          label: "Referal Fee",
          content: inv.fees.ReferralFee,
        });
      if (e.closingFee) {
        breakdown.push({
          color: "red",
          label: "Closing Fee",
          content: e.closingFee,
        });
      } else if (inv.fees && inv.fees.VariableClosingFee) {
        breakdown.push({
          color: "red",
          label: "Closing Fee",
          content: inv.fees.VariableClosingFee,
        });
      }
      if (e.taxCollectFee)
        breakdown.push({
          color: "red",
          label: "Tax Collection Fee",
          content: e.taxCollectFee,
        });
      if (e.giftwrapChargeback)
        breakdown.push({
          color: "red",
          label: "Gift Wrap Chargeback",
          content: e.giftwrapChargeback,
        });
      if (e.shippingChargeback)
        breakdown.push({
          color: "red",
          label: "Shipping Chargeback",
          content: e.shippingChargeback,
        });
      if (e.promotions !== []) {
        e.promotions.map((p, k) => {
          return breakdown.push({
            color: "red",
            label: p.promotionId,
            content: p.promotionAmount,
          });
        });
      }
    }
    let totalProfit = 0;
    for (let i = 0; i < breakdown.length; i++) {
      totalProfit += breakdown[i].content;
    }
    const totalROI = isFinite(totalProfit / (-1 * Number(totalBuyPrice))) ? (
      ((totalProfit / (-1 * Number(totalBuyPrice))) * 100).toFixed(1)
    ) : (
      <i className='fas fa-infinity'></i>
    );

    let buttonContent = (
      <div className={classes.orderArr2}>
        <MuiThemeProvider theme={totalProfit > 0 ? greenTheme : redTheme}>
          <Button
            variant='contained'
            color='primary'
            style={{ width: "70px" }}
            className={classNames(
              totalProfit > 0 ? classes.profit : classes.loss,
              classes.button
            )}
            onClick={this.handleClickOpen}
          >
            <div>
              ${totalProfit.toFixed(2)}
              <br />
              <strong>{totalROI}%</strong> ROI
            </div>
          </Button>
        </MuiThemeProvider>
      </div>
    );

    if (this.props.status === "Canceled") buttonContent = <div>N/A</div>;
    if (this.state.open) {
      let displayRows = this.props.orderInfo.skus.map((e, i) => {
        let profitSum = 0;
        let currRow = breakdown.map((f, j) => {
          profitSum += Number(f.content);
          let thisCell;
          if (
            f.label == "Prep / Misc" ||
            f.label == "Buy Price" ||
            f.label == "Sales Tax" ||
            f.label == "Shipping"
          ) {
            return {type:"purchase",row:(
              <TableRow key={j + "row1"}>
                <TableCell
                  key={j}
                  className={classes.cell}
                  padding='none'
                  align='left'
                >
                  {f.label}
                </TableCell>
                <TableCell
                  key={i + "sub1"}
                  className={classes.cell}
                  padding='none'
                  align='left'
                >
                  -
                  <TextField
                    sku={e.sku}
                    className={classes.textField}
                    name={f.label}
                    defaultValue={Math.abs(f.content && f.content.toFixed(2))}
                    margin='normal'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            )};
          } else
            return {type:f.content<=0?"expense":"revenue",row:(
              <TableRow key={j + "row"}>
                <TableCell
                  key={j}
                  className={f.color === "red" ? classes.cell : classes.cell}
                  padding='none'
                  align='left'
                >
                  {f.label}
                </TableCell>
                <TableCell
                  key={j + "sub"}
                  className={
                    f.color === "red" ? classes.redcell : classes.greencell
                  }
                  style={{ width: "60px" }}
                  padding='none'
                  align='left'
                >
                  {Number(f.content).toFixed(2)}
                </TableCell>
              </TableRow>
            )};
        });
        console.log(currRow[i])
        let thisRow = (
          <TableBody key={i + "body"}>
            <TableRow key={i + "row-revenue"}>
            <TableCell
                key={i}
                className={classes.cell}
                // padding='none'
                align='left'
              >
                <strong>Item Revenue</strong>
              </TableCell>
              </TableRow>

            {currRow.filter(t=>t.type=="revenue").map(t=>t.row)}
            <TableRow key={i + "row-purchase"}>
            <TableCell
                key={i}
                className={classes.cell}
                // padding='none'
                align='left'
              >
                <strong>Purchase Costs</strong>
              </TableCell>
              </TableRow>

              
              {currRow.filter(t=>t.type=="purchase").map(t=>t.row)}
              
            <TableRow key={i + "row-expense"}>
            <TableCell
                key={i}
                className={classes.cell}
                // padding='none'
                align='left'
              >
                <strong>Amazon Fees</strong>
                
              </TableCell>
            </TableRow>

            {currRow.filter(t=>t.type=="expense").map(t=>t.row)}

            <TableRow key={i + "row1"}>
              <TableCell
                key={i}
                className={classes.cell}
                padding='none'
                align='left'
              >
                <strong>Profit</strong>
              </TableCell>
              <TableCell
                key={i + "sub"}
                className={classes.cell}
                padding='none'
                align='left'
              >
                <strong>${profitSum.toFixed(2)}</strong>
                <input type='hidden' id='sku' name='sku' value={e.sku} />
                
              </TableCell>
            </TableRow>
          </TableBody>
        );
        return thisRow;
      });

      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant='h6'>
              Breakdown for:
              <Link
                target='_blank'
                className={classes.link}
                rel='noreferrer'
                href={
                  "https://sellercentral.amazon.com/orders-v3/order/" +
                  this.props.orderInfo.orderId
                }
              >
                {" " + this.props.orderInfo.orderId}
              </Link>
            </Typography>
            {onClose ? (
              <IconButton
                aria-label='Close'
                className={classes.closeButton}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>

          <DialogContent>
            <form
              style={{ display: "inline" }}
              noValidate
              autoComplete='off'
              onSubmit={this.sendBuyPrice}
            >
              <Table>{displayRows}</Table>
              <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.saveButton}
                >
                  Save All
                </Button>
            </form>
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = <div></div>;
    }
    return (
      <div>
        {buttonContent}

        {modalContent}
      </div>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(FormDialog);
