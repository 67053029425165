import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link as Inlink } from "react-router-dom";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { submitPayment, claimCode } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import {
  Grid,
  Link,
  Paper,
  Typography,
  Button,
  Menu,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 768,
      lg: 900,
      xl: 1200,
    },
  },
});

const listOptions = ["Ungated", "Replenishables", "Toys", "Only Software"];

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit * 0.4,
    color: theme.palette.grey[500],
  },
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
  paper: {
    minHeight: "1px",
    maxHeight: "1000px",
    [theme.breakpoints.up(768)]: {
      // minHeight:'383px',
      //maxHeight:'383px',
    },

    position: "relative",
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  button: {
    fontFamily: "Raleway",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "7px",
    margin: "3px",
    fontWeight: 700,
    width: "100%",
    color: "white",
    backgroundColor: "#52AA5E",
    "&:hover": {
      "-webkit-animation": "btnWiggle 3s infinite",
      "-moz-animation": "btnWiggle 3s infinite",
      "-o-animation": "btnWiggle 3s infinite",
      animation: "btnWiggle 3s infinite",
      backgroundColor: "#388659",
      color: "white",
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "#919EAA",
    },
  },
  planButton: {
    position: "absolute",
    right: "16px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "3px",
    color: "#149cea",
    borderColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
      borderColor: "#132f4a",
    },
    "&:disabled": {
      color: "grey",
      backgroundColor: "#919EAA",
    },
    "&:hover": {
      color: "#132f4a",
    },
  },
  headline: {
    fontFamily: "Raleway",
    fontSize: "16px",
    textAlign: "left",
  },
  link: {
    textDecoration: "underline",
    "&:hover": {
      color: "#132f4a",
    },
  },
});

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardholder: "",
      list: props.list || "Ungated",
      plan: "monthly",
      errors: {},
      password: "",
      stripe: null,
      anchorEl: null,
      couponCode: "",
      goodcoupon: false,
      terms: false,
      discount: 0,
    };
  }

  componentDidMount() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (window.Stripe)
        this.setState({
          stripe: window.Stripe("pk_test_yTxtNK50fnYQ20mawyqVWY2y"),
        });
    } else {
      if (window.Stripe)
        this.setState({
          stripe: window.Stripe("pk_live_aszZgWDTsAm84X8YDj7N1H0i"),
        });
    }
    let email;
    if (this.props.auth && this.props.auth.user && this.props.auth.user.email) {
      email = this.props.auth.user.email;
    }
    this.setState({ email });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
    if (
      prevProps.auth !== this.props.auth &&
      this.props.auth.data !== prevProps.auth.data
    ) {
      if (this.props.auth.data.validCode) {
        this.setState({
          discount: this.props.auth.data.discount,
          goodcoupon: true,
        });
      }
    }
  }

  onChange = (e) => {
    if (e.target.name === "couponCode") {
      this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.name === "terms") {
      this.setState({ [e.target.name]: e.target.checked });
    } else this.setState({ [e.target.name]: e.target.value });
  };

  checkcouponCode = (e) => {
    this.setState({
      goodcoupon: false,
      discount: 0,
    });
    if (this.state.couponCode !== "")
      this.props.claimCode({
        action: "check",
        couponCode: this.state.couponCode,
        productType: "leads",
        plan: this.state.plan,
        flavor: this.state.list,
      });
  };

  submitPayment = () => {
    //step 2 payment Information
    let errors = {};
    if (!this.state.terms) {
      errors.payment = "Please accept the Terms and Conditions";
    }
    this.setState({ errors });
    if (Object.keys(errors).length !== 0) return;
    const paymentInfo = {
      password: this.state.password,
      email: this.state.email.toLowerCase(),
      flavor: this.state.list,
      plan: this.state.plan,
      couponCode: this.state.couponCode,
    };
    this.props.submitPayment(paymentInfo, this.props.history);
  };

  cleanCoupon = () => {
    this.setState({ couponCode: "", discount: 0, goodcoupon: false });
  };

  changeList = (e, i) => {
    if (listOptions[i] === "Only Software")
      this.setState({ plan: "monthly" }, () => this.cleanCoupon());
    this.setState({ anchorEl: null, list: listOptions[i] }, () =>
      this.cleanCoupon()
    );
  };

  handleOptionChange = (plan) => {
    this.setState(
      {
        plan,
      },
      () => this.cleanCoupon()
    );
  };

  render() {
    const {
      errors,
      stripe,
      list,
      plan,
      anchorEl,
      cardholder,
      email,
      discount,
    } = this.state;
    const { classes, auth } = this.props;
    const { pending } = auth;
    let listBenefits = [];
    let cost = {};
    cost.monthly = 159 - (discount ? discount : 0) * 159;
    cost.biannual = 115 * 6 - (discount ? discount : 0) * 115 * 6;
    cost.weekly = 39;
    if (list.startsWith("Ungated")) {
      listBenefits = [
        "100% ungated leads",
        "25 leads / week",
        "Maximum 9 subscribers / list",
        "Live chat support",
        // "25% discount w/ prep partners",
        // "Financial dashboard & tools",
      ];
      cost.monthly = 105;
      cost.biannual = 75 * 6;
    }
    if (list.startsWith("Replen"))
      listBenefits = [
        "50% leads in 'Topical' category",
        "50% leads in 'Grocery' category",
        "60 leads / week",
        "Maximum 9 subscribers / list",
        "Live chat support",
        "25% discount w/ prep partners",
        "Financial dashboard & tools",
      ];
    if (list.startsWith("Toys"))
      listBenefits = [
        "100% toys from top brands",
        "60 leads / week",
        "Maximum 9 subscribers / list",
        "Main brands: Lego, Disney, Barbie",
        "Live chat support",
        "25% discount w/ prep partners",
        // "Financial dashboard & tools"
      ];
    if (list.startsWith("Only Software")) {
      listBenefits = [
        "True Profit Tracking",
        "Per SKU Analysis",
        "Keep Track of All Sales",
        "Create Inbound Shipments",
        "In Depth Reports",
        "Sales Tax Calcuation",
      ];
      cost.monthly = 37;
      cost.biannual = "";
      cost.weekly = "";
    }
    if (
      errors.payment &&
      typeof errors.payment === "string" &&
      (errors.payment.indexOf("attached payment source") >= 0 ||
        errors.payment.indexOf("no active card") >= 0)
    ) {
      errors.payment = (
        <span>
          You need to add a payment card in{" "}
          <a className={classes.link} href="/settings">
            Settings
          </a>
        </span>
      );
    }

    return (
      <div className="container">
        <div className="spinner" hidden={!pending}></div>
        <MuiThemeProvider theme={theme}>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <Paper className={classes.paper}>
                <Typography variant="h6" className={classes.headline}>
                  <i className="fas fa-credit-card"></i> Confirm Payment
                  <br />
                  <br />
                </Typography>
                Password
                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                Coupon Code
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "70%",
                      vertialAlign: "top",
                      display: "inline-block",
                    }}
                    className="form-group"
                  >
                    <input
                      className={"form-control margin-bottom"}
                      placeholder="Coupon"
                      name="couponCode"
                      type="couponCode"
                      value={this.state.couponCode}
                      onChange={this.onChange}
                    />
                  </div>
                  <div
                    style={{
                      width: "29%",
                      vertialAlign: "top",
                      display: "inline-block",
                    }}
                  >
                    <Button
                      onClick={this.checkcouponCode}
                      variant="contained"
                      color="primary"
                      disabled={pending}
                      style={{
                        marginTop: -2,
                        height: 38,
                        vertialAlign: "top",
                        width: "100%",
                        textTransform: "none",
                        fontWeight: "normal",
                        padding: "5px",
                        marginLeft: "1%",
                        backgroundColor: "#149cea",
                        color: "white",
                      }}
                    >
                      Apply Code
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "80%",
                    visibility: "visible",
                    color: "red",
                  }}
                >
                  {errors.coupon}
                </div>
                {this.state.goodcoupon ? (
                  <div
                    style={{
                      fontSize: "80%",
                      visibility: "visible",
                      color: "green",
                    }}
                  >
                    Valid Coupon!
                  </div>
                ) : null}
                <Checkbox
                  checked={this.state.terms}
                  onChange={this.onChange}
                  name="terms"
                  inputProps={{
                    "aria-label": "terms checkbox",
                  }}
                />{" "}
                Accept{" "}
                <a
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    "&:hover": {
                      color: "#132f4a",
                    },
                  }}
                  href="https://www.go-atlas.io/atlas-terms-of-service/"
                >
                  Terms and Conditions
                </a>
                <Button
                  onClick={this.submitPayment}
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  style={{
                    width: "100%",
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "normal",
                    padding: "10px",
                    margin: "0px",
                    backgroundColor: "#149cea",
                    "&:hover": {
                      color: "#132f4a",
                    },
                    "&:disabled": {
                      color: "white",
                      backgroundColor: "#919EAA",
                    },
                    color: "white",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  Add {this.state.list} Subscription for $
                  {plan === "monthly"
                    ? cost.monthly
                    : plan === "biannual"
                    ? "" + cost.biannual + "*"
                    : plan === "weekly"
                    ? cost.weekly
                    : ""}
                  {plan === "monthly" ? "/mo" : plan === "weekly" ? "/wk" : ""}
                </Button>
                {errors.card || errors.payment ? (
                  <small className="text-danger">
                    {errors.card}
                    {errors.payment}
                  </small>
                ) : null}
              </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
              <Paper className={classes.paper}>
                <Typography variant="h6" className={classes.headline}>
                  <i className="fas fa-shopping-cart"></i> Order Summary
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    className={classes.planButton}
                    onClick={event => {
                      this.setState({ anchorEl: event.currentTarget });
                    }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    disabled={pending}
                  >
                    Change List
                  </Button> */}
                  {/* <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => {
                      this.setState({ anchorEl: null });
                    }}
                  >
                    {listOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        onClick={event => this.changeList(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu> */}
                </Typography>

                <Typography
                  variant="h6"
                  className={classes.headline}
                  style={{ textAlign: "center", fontSize: "25px" }}
                >
                  {this.state.list}
                </Typography>
                <div
                  id="benefits"
                  style={{
                    display: "flex",
                    margin: "auto",
                  }}
                >
                  <ul style={{ margin: "auto" }} className="fa-ul">
                    {listBenefits.map((row, i) => (
                      <li key={i}>
                        <i
                          style={{ color: "#029cea" }}
                          className="far fa-check-circle"
                        ></i>{" "}
                        {row}
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#029cea",
                    fontFamily: "Raleway",
                    fontWeight: 1000,
                  }}
                >
                  <span style={{ fontSize: "18px" }}>$</span>
                  <span style={{ fontSize: "50px" }}>
                    {plan === "monthly"
                      ? cost.monthly
                      : plan === "biannual"
                      ? "" + cost.biannual + "*"
                      : plan === "weekly"
                      ? cost.weekly
                      : ""}
                  </span>
                  <span style={{ fontSize: "18x", color: "black" }}>
                    {plan === "monthly"
                      ? "/mo"
                      : plan === "weekly"
                      ? "/wk"
                      : ""}
                  </span>
                </div>
                <div
                  hidden={list === "Only Software"}
                  style={{
                    color: "#149cea",
                    textAlign: "center",
                    fontSize: "13px",
                  }}
                  className={classes.link}
                >
                  {/* <div className="switch-toggle switch-ios">
                    <input
                      readOnly
                      id="monthly"
                      name="view"
                      type="radio"
                      checked={this.state.plan === "monthly"}
                    />
                    <label
                      htmlFor="monthly"
                      onClick={() => this.handleOptionChange("monthly")}
                    >
                      Monthly
                    </label>

                    <input
                      readOnly
                      id="biannual"
                      name="view"
                      type="radio"
                      checked={this.state.plan === "biannual"}
                    />
                    <label
                      htmlFor="biannual"
                      onClick={() => this.handleOptionChange("biannual")}
                    >
                      Biannually
                    </label>

                    <a></a>
                  </div>
                  {plan === "monthly" ? (
                    <Link onClick={() => this.handleOptionChange("biannual")}>
                      Change to biannual payment and{" "}
                      <strong>
                        save ${(cost.monthly * 6 - cost.biannual).toFixed(2)}
                      </strong>
                    </Link>
                  ) : plan === "weekly" ? (
                    <Link onClick={() => this.handleOptionChange("biannual")}>
                      Change to biannual payment and{" "}
                      <strong>
                        save ${(cost.weekly * 6 * 4 - cost.biannual).toFixed(2)}
                      </strong>
                    </Link>
                  ) : null} */}
                </div>
                <div style={{ textAlign: "center", fontSize: "13px" }}>
                  {plan === "monthly" || plan === "weekly"
                    ? ""
                    : "*$" +
                      (cost.biannual / 6).toFixed(2) +
                      " per month billed biannually*"}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </div>
    );
  }
}

Checkout.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

class PurchaseModal extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.paymentRes && this.props.auth.paymentRes.success) {
        this.handleClose();
      }
    }
    if (
      prevProps.selectForPurchase !== this.props.selectForPurchase &&
      this.props.selectForPurchase !== null
    ) {
      this.handleClickOpen();
    }
  }

  render() {
    const { classes, auth, errors } = this.props;
    let modalContent;
    let onClose = this.handleClose;
    if (this.state.open) {
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth={"md"}
          fullWidth={true}
        >
          <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">Purchase New List</Typography>
            {onClose ? (
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <DialogContent>
            <br />
            <Checkout
              claimCode={this.props.claimCode}
              submitPayment={this.props.submitPayment}
              auth={auth}
              errors={errors}
              classes={classes}
              list={this.props.selectForPurchase}
            />
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = <span></span>;
    }
    return (
      <span>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={this.handleClickOpen}
        >
          Purchase a New List
        </Button>
        {modalContent}
      </span>
    );
  }
}

PurchaseModal.propTypes = {
  classes: PropTypes.object.isRequired,
  selectForPurchase: PropTypes.string,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(
  connect(mapStateToProps, { submitPayment, claimCode })(
    withStyles(styles)(PurchaseModal)
  )
);
