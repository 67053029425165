import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from '../common/Spinner';
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {Collapse,Menu,MenuItem,Radio, Drawer,Dialog,Grid,Checkbox, Paper, Link,Typography,TextField,Button,Tooltip,DialogActions,ExpansionPanel,ExpansionPanelDetails,ExpansionPanelSummary } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { toast } from 'react-toastify';
import { Link as LinkIn } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {submitContent,acceptOrVoid,getlabels,gettransportcontent} from '../../actions/shipmentActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 2
  },
  paper: {
    position: 'relative',
    padding: theme.spacing.unit * 2,
    color: 'black',

  },
  table: {
    margin: 'auto',
    tableLayout: 'auto',
    textAlign: 'center',
    fontSize: "14px",
    width: '95%'
  },
  tableIn: {
    pading: '20px',
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
    tableLayout: 'auto',
    height: "100%",
    textAlign: 'center',
    fontSize: "14px",
    width: '70%',
    borderBottom: '2px solid #ddd',
  },
  buttonIn:{
    textTransform: 'none',
    paddingTop:0,
    height: 25,
    color:'white',
    backgroundColor: '#149cea',
    '&:hover': {
      backgroundColor: '#132f4a',
    },
  },
  buttonx:{
    textTransform: 'none',
    paddingTop:2,
    height: 25,
    color:'red'
  },
  button: {
    textTransform: 'none',
    margin: 3,
    color:'white',
    backgroundColor: '#149cea',
    '&:hover': {
      backgroundColor: '#132f4a',
    },
  },
  link: {
    color: "blue",
    textDecoration: "underline"
  },
  addressField: {
    margin: "0px",
    width: '150px',
    padding: '2px',
    height: '22px'
  },
  textField: {
    margin: "2px",
    width: '50px',
    padding: '2px',
    height: '22px'
  },
  tableHead:{
    borderBottom: '2px solid #000',
  },
  cell1: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: '250px',
    overflow: 'hidden',
    minHeight: "20px",
    height: "20px",
    maxHeight: "20px",
    fontSize: "14px",
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    margin: "10px",
    color: 'black'
  },
  tooltip: {
  boxShadow: theme.shadows[1],
  fontSize: 14,
}
});

class ShippingInfo extends React.Component {
    state = {
      drawer:false,
      skuList: [],
      casePacked:false,
      errors: {},
      address:{
        a_name:'',
        a_1:'',
        a_2:'',
        a_city:'',
        a_zip:'',
        a_state:'',
        a_phone:'',
        a_country:'US'
      },
      selfPrep:true,
      prep:[],
      removePopOpen:false,
      totalUnits:0,
      shipmentPlans:[{
        workflowStep:1,
        sp:true,
        partnered:true,
        packageList:[],
        skus:[{
        }]
      }],
      updated:false,
      prevPgCreate:false,
      selectedShipment:0,
      anchorEl:[null],

    };

    componentDidMount = () => {
      if (this.props && this.props.location && this.props.location.state) {
        let loc = this.props.location.state;
        if (loc.skuList) {
          let skuList = loc.skuList;
          for (let i = 0;i<skuList.length;i++){
            if (!("shippingCost" in skuList[i])) {skuList[i].shippingCost = 0}
            if (!("prepCost" in skuList[i])) {skuList[i].prepCost = 0}
            if (!("etcCost" in skuList[i])) {skuList[i].etcCost = 0}
          }
          this.setState({
            skuList: loc.skuList
          });
        }
        if (loc.shipmentPlans) {
          let shipmentPlans = loc.shipmentPlans;
          for (let i = 0;i<shipmentPlans.length;i++){
            if (!("sp" in shipmentPlans[i])) {shipmentPlans[i].sp = true}
            if (!("partnered" in shipmentPlans[i])) {shipmentPlans[i].partnered = true}
            if (!("carrier" in shipmentPlans[i])) {shipmentPlans[i].carrier = 'UNITED_STATES_POSTAL_SERVICE'}
            if (!("freightReadyDate" in shipmentPlans[i])) {shipmentPlans[i].freightReadyDate = new Date()}
            if (!("contactName" in shipmentPlans[i])) {shipmentPlans[i].contactName = ''}
            if (!("contactPhone" in shipmentPlans[i])) {shipmentPlans[i].contactPhone = ''}
            if (!("contactEmail" in shipmentPlans[i])) {shipmentPlans[i].contactEmail = ''}
            if (!("contactFax" in shipmentPlans[i])) {shipmentPlans[i].contactFax = ''}
            if (!("sellerDeclaredValue" in shipmentPlans[i])) {shipmentPlans[i].sellerDeclaredValue = ''}
            if (!("workflowStep" in shipmentPlans[i])) {shipmentPlans[i].workflowStep = 1}
            if (!("packageList" in shipmentPlans[i])) {shipmentPlans[i].packageList = [{
              numbIdentical:'',
              cartonName: 'FBA1',
              isStacked: false,
              dimUnit: 'inches',
              length: '',
              width: '',
              height: '',
              weight: '',
              weightUnit: 'pounds',
              trackingId: '',
              items:[]
            }]}
            if (!("skus" in shipmentPlans[i])) {shipmentPlans[i].skus = []}
          }
          this.setState({
            shipmentPlans: loc.shipmentPlans
          });
          this.props.gettransportcontent(loc.shipmentPlans[0]);
        }
        if (loc.address) {
          this.setState({
            address: loc.address
          });
        }
        if (loc.casePacked) {
          this.setState({
            casePacked: loc.casePacked
          });
        }
        if (typeof loc.selfPrep !== 'undefined') {
          this.setState({
            selfPrep: loc.selfPrep
          });
        }
        if (typeof loc.prevPgCreate !== 'undefined') {
          this.setState({
            prevPgCreate: loc.prevPgCreate
          });
        }
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.errors !== this.props.errors) {
        this.setState({
          errors: this.props.errors
        });
      }
      if (prevProps.shipments !== this.props.shipments && this.props.shipments.shipmentPlans && prevProps.shipments.shipmentPlans !== this.props.shipments.shipmentPlans){
        let shipmentPlans = this.props.shipments.shipmentPlans;
        this.setState({
          shipmentPlans
        });
      }
      if (prevProps.shipments !== this.props.shipments && this.props.shipments.acceptedShipment && prevProps.shipments.acceptedShipment !== this.props.shipments.acceptedShipment){
        let shipmentPlans = this.state.shipmentPlans;
        let newShipmentId = this.props.shipments.acceptedShipment.shipmentId
        let labels = this.props.shipments.acceptedShipment.labels;
        console.log(labels)
        if (labels && labels !== ''){
          var link = document.createElement('a');
          link.href = "data:application/pdf;base64," + encodeURI(labels);
          link.download = 'labels.pdf';
          link.dispatchEvent(new MouseEvent('click'));
          //window.open();
        }
        let index = shipmentPlans.findIndex(function(e){
          return e.shipmentId === newShipmentId
        })
        shipmentPlans[index]=this.props.shipments.acceptedShipment;
        this.setState({
          shipmentPlans
        });
      }
    }

    handleClickOpen=()=>{
      this.setState({ removePopOpen: !this.state.removePopOpen });
    }

    removeSku = (i) => {
      let skuList = this.state.skuList;
      skuList.splice(i,1);
      this.setState({
        skuList,
        updated:true
      });
    }

    onChange = (event) => {
      event.preventDefault();
      const target = event.target;
      let skuList = this.state.skuList;
      let shipmentPlans = this.state.shipmentPlans;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      if ((isNaN(value) || (value.indexOf(".") >= 0)) && (name === 'quantity' || name === 'numbIdentical')) {
        return
      }
      if (isNaN(value) && (name === 'weight' || name === 'length' || name === 'width' || name === 'height')) {
        return
      }
      if (name === 'sp' || name === 'partnered'){
        shipmentPlans[this.state.selectedShipment][name]=!shipmentPlans[this.state.selectedShipment][name];
        shipmentPlans[this.state.selectedShipment].workflowStep=1;
      } else if (name === 'numbIdentical' || name === 'weight' || name === 'length' || name === 'width' || name === 'height' || name === 'weightUnit' || name === 'dimUnit' ){
        shipmentPlans[this.state.selectedShipment].packageList[event.target.getAttribute('data-box')][name]=value;
      }else if (name === 'quantity') {
        shipmentPlans[this.state.selectedShipment].packageList[event.target.getAttribute('data-box')].items[event.target.id][name]=value;
      }else{
        shipmentPlans[this.state.selectedShipment][name] = value;
      }
      this.setState({shipmentPlans})
    }

    toggleDrawer = (open) => () => {
      this.setState({
        drawer: open,
      });
    };

    handleDropDown = (event,j) => {

      let anchorEl = this.state.anchorEl;
      anchorEl[j] =  event.currentTarget;
      this.setState({ anchorEl });
    };

    handleClose = (j) => {
      let anchorEl = this.state.anchorEl
      anchorEl[j] = null;
      this.setState({
        anchorEl
      });
    };

    addSKU2Box = j => event => {
      let e = event;
      let shipmentPlans = this.state.shipmentPlans;
      let found = shipmentPlans[this.state.selectedShipment].packageList[j].items.find(function(ele){
        return ele.sku === e.target.id;
      });
      if (!found){
        shipmentPlans[this.state.selectedShipment].packageList[j].items.push({sku:e.target.id,quantity:''})
        this.setState({shipmentPlans})
      }
      this.handleClose(j);
    }

    removeSKUFromBox = j => event => {
      let e = event;
      let shipmentPlans = this.state.shipmentPlans;
      let found = shipmentPlans[this.state.selectedShipment].packageList[j].items.findIndex(function(ele){
        return ele.sku === e.target.id;
      });
      if (found){
        shipmentPlans[this.state.selectedShipment].packageList[j].items.splice(found,1)
        this.setState({shipmentPlans})
      }
    }

    addBox = event => {
        let shipmentPlans = this.state.shipmentPlans;
        let anchorEl = this.state.anchorEl;
        shipmentPlans[this.state.selectedShipment].packageList.push({
          numbIdentical:'',
          cartonName:'FBA'+(shipmentPlans[this.state.selectedShipment].packageList.length+1),
          isStacked:false,
          items:[],
          weight:'',
          length:'',
          width:'',
          height:'',
          weightUnit:'pounds',
          dimUnit:'inches',
        });
        this.setState({shipmentPlans,anchorEl});
    }

    removeBox = j => event => {
        let shipmentPlans = this.state.shipmentPlans;
        let anchorEl = this.state.anchorEl;
        anchorEl.splice(j,1);
        shipmentPlans[this.state.selectedShipment].packageList.splice(j,1);
        this.setState({shipmentPlans,anchorEl});
    }

    submitContent = event => {
      toast.dismiss();
      let shipmentPlan = this.state.shipmentPlans[this.state.selectedShipment];
      let skus = this.state.shipmentPlans[this.state.selectedShipment].skus;
      shipmentPlan.totalCartons = 0;
      for (let j = 0;j<skus.length;j++){
        skus[j].givenQuantity = 0;
      }
      if (!shipmentPlan.sp && (shipmentPlan.contactName === '' || shipmentPlan.contactPhone === '')){
        return toast.error('Please fill in required info for LTL shipment');
      }
      if (!shipmentPlan.sp && (new Date(shipmentPlan.freightReadyDate).getTime() < (new Date().getTime() + 8640000))){
        return toast.error('Please give a Pickup Date further than 24 hours from now.');
      }
      for (let i = 0;i<shipmentPlan.packageList.length;i++){
        let box = shipmentPlan.packageList[i];
        shipmentPlan.totalCartons += Number(box.numbIdentical);
        if (box.weight === '' || box.height === '' || box.width === '' || box.numbIdentical === '' || box['length'] === ''){
          return toast.error('Please fill all blanks for box '+(i+1));
        }
        for (let j = 0;j<box.items.length;j++){
          let index = skus.findIndex(function(ele){
            return ele.sku === box.items[j].sku;
          });
          if (typeof(index)!=='undefined') {
              skus[index].givenQuantity += box.items[j].quantity*box.numbIdentical;
          }
        }
      }
      for (let j = 0;j<skus.length;j++){
        if (Number(skus[j].quantity) !== Number(skus[j].givenQuantity))
          return toast.error('Please make quantity for sku '+skus[j].sku+' equal to '+skus[j].quantity);
      }
      let reqShipment = shipmentPlan;
      reqShipment.labels = '';
      this.props.submitContent(reqShipment);
    }

    getlabels = () => {
      let reqShipment = this.props.shipments.acceptedShipment
      reqShipment.labels = '';
      this.props.getlabels(reqShipment);
    }

    acceptOrVoid = (shipment) => {
      shipment.labels = '';
      this.props.acceptOrVoid({action:shipment.acceptedShipping?'void':'accept',shipment:shipment})
    }
  render() {
    const { errors, shipmentPlans } = this.state;
    const { classes } = this.props;
    const {user} = this.props.auth;
    let loading;
    let properTier;
    if (this.props.shipments.loading){
      loading = (
        <div align='center'><br/><br/>Checking with Amazon...<br/><br/> <Spinner/><br/></div>
      )
    }

    if (!shipmentPlans.length || !shipmentPlans[this.state.selectedShipment]){
      return (
      <div className="editshipment">
        <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs>
          <Paper className={classes.root}>
            Select a Shipment to Edit from the &nbsp;
            <LinkIn className={classes.link} to="/shipments">
            Shipments Page
            </LinkIn>
          </Paper>
          </Grid>
        </Grid>
        </div>
      </div>
      );
    }
    const shipment = shipmentPlans[this.state.selectedShipment];
    let ltlInfo = (
      <div>
      <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
        What is the LTL Shipping Info?
      </Typography>
      <br/>
       <div style={{marginLeft:'30px',textAlign:'left'}} className="table-responsive">
        <table className={classes.table}>
          <tbody>
          <tr>
            <td className={classes.cell1} align="right">
            Pickup Contact Name:*
            </td>
            <td className={classes.cell1} align="left">
           <input className={classes.addressField} name={'contactName'} value={shipment.contactName}
           onChange={this.onChange}/>
           </td>
           </tr>
           <tr>
             <td className={classes.cell1} align="right">
             Phone:*
             </td>
             <td className={classes.cell1} align="left">
            <input className={classes.addressField} name={'contactPhone'} value={shipment.contactPhone}
            onChange={this.onChange}/>
            </td>
            </tr>
            <tr>
              <td className={classes.cell1} align="right">
              Email:
              </td>
              <td className={classes.cell1} align="left">
             <input className={classes.addressField} name={'contactEmail'} value={shipment.contactEmail}
             onChange={this.onChange}/>
             </td>
             </tr>
             <tr>
               <td className={classes.cell1} align="right">
               Fax:
               </td>
               <td className={classes.cell1} align="left">
              <input className={classes.addressField} name={'contactFax'} value={shipment.contactFax}
              onChange={this.onChange}/>
              </td>
            </tr>
            <tr>
                <td className={classes.cell1} align="right">
                Declared Value:
                </td>
                <td className={classes.cell1} align="left">
               <input className={classes.addressField} name={'sellerDeclaredValue'} value={shipment.sellerDeclaredValue}
               onChange={this.onChange}/>
               </td>
            </tr>
            <tr>
                <td className={classes.cell1} align="right">
                Pickup Ready Date:*
                </td>
                <td className={classes.cell1} align="left">
               <input type="date" className={classes.addressField} name={'freightReadyDate'} value={shipment.freightReadyDate}
               onChange={this.onChange}/>
               </td>
            </tr>
          </tbody>
        </table>
        <br/>
        </div>
      </div>
    );
    let carrierStep = (
      <div>
      <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
         What Carrier Do You Want?
      </Typography>
      <div style={{marginLeft:'30px',lineHeight:'48px',textAlign:'left'}}>
        <label style={{lineHeight:'48px'}}>
          <Radio
            type="radio"
            name="partnered"
            checked={shipment.partnered}
            onChange={this.onChange}
          />
          {' '}<span  style={{verticalAlign: 'middle' }}>
          Amazon-Partnered (UPS)</span>
        </label><br/>
        <label>
          <Radio
            type="radio"
            name="partnered"
            checked={!shipment.partnered}
            onChange={this.onChange}
          /><span style={{verticalAlign: 'middle' }}>
          {' '}Use Your Own:{' '}

          <select value={shipment.carrier} name='carrier' onChange={this.onChange}>
             <option value="UNITED_STATES_POSTAL_SERVICE">USPS</option>
             <option value="FEDERAL_EXPRESS_CORP">FedEx</option>
             <option value="DHL_EXPRESS_USA_INC">DHL</option>
             <option value="UNITED_PARCEL_SERVICE_INC">UPS</option>
             <option value="OTHER">Other</option>
           </select>
          </span>
        </label>
        </div>
        </div>
    );
    let boxInfo = (
      <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
         Box Content Information
      </Typography>
      <br/><br/>
      <div className="table-responsive">
         <table className={classes.table}>
           <thead className={classes.tableHead}>
             <tr>
               <th className={classes.cell}>Box Type</th>
               <th className={classes.cell}># of Boxes</th>
               <th className={classes.cell}>Contents</th>
               <th className={classes.cell}>Weight
               </th>
               <th className={classes.cell}>Dimensions </th>

             </tr>
           </thead>
           <tbody>
          {shipment.packageList.map((box,j)=>(
            <tr key={j}>
            <td className={classes.cell} align="center">{(j+1)+'  '}
            <Button style={{minWidth:'20px'}}
            className={classes.buttonx}
            onClick={this.removeBox(j)}>
            <i  className="fas fa-times-circle"></i>
            </Button>

            </td>
            <td className={classes.cell} align="center">
            <input key={box+j} data-box={j} className={classes.textField} name={'numbIdentical'} value={box.numbIdentical}
            onChange={this.onChange}/>
            </td>
            <td className={classes.cell} align="center">
            <table className={classes.table}>
              <thead style={{borderBottom: '1px solid #000',}}>
                <tr>
                  <th className={classes.cell}>SKU</th>
                  <th className={classes.cell}>Quantity</th>
                </tr>
              </thead>
              <tbody>
              {box.items.map((sku,k)=>(
                <tr key={k}>
                  <td key={k}className={classes.cell} align="center">{sku.sku+'  '}
                  <Button style={{minWidth:'20px'}}
                  className={classes.buttonx}
                  onClick={this.removeSKUFromBox(j)}>
                  <i  className="fas fa-times-circle"></i>
                  </Button>

                  </td>
                  <td key={k+'sub'}className={classes.cell} align="center">
                  <input key={sku+k} data-box={j} className={classes.textField} name={'quantity'} id={k} value={sku.quantity}
                  onChange={this.onChange}/>
                  </td>
                </tr>
              ))}
              <tr>
                <td className={classes.cell} align="center">
                <div><Button
                className={classes.buttonIn}
                onClick={(e)=>this.handleDropDown(e,j)}
                >
                  +SKU
                  </Button>
                  <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl[j]}
                  open={Boolean(this.state.anchorEl[j])}
                  onClose={()=>this.handleClose(j)}
                  >
                  {shipment.skus.map((sku,l)=>(
                    <MenuItem key={l} box={j} id={sku.sku} onClick={this.addSKU2Box(j)}>{(sku.sku?sku.sku:null)+' : '+ (sku.title?sku.title.substring(0,50)+'...':null)}</MenuItem>
                  ))}
                  </Menu>
                  </div></td>
                  </tr>
              </tbody>
              </table>
              </td>
              <td className={classes.cell} align="center">
              <input className={classes.textField} name={'weight'} data-box={j} value={box.weight}
              onChange={this.onChange}/>
              {'  '}
              <select value={box.weightUnit} name='weightUnit' data-box={j} onChange={this.onChange}>
                 <option default value="pounds">lb.</option>
                 <option value="kilograms">kg.</option>
               </select>
              </td>
              <td className={classes.cell} align="center">
              <input className={classes.textField} name={'length'} data-box={j} value={box['length']}
              onChange={this.onChange}/>x
              <input className={classes.textField} name={'width'} data-box={j} value={box.width}
              onChange={this.onChange}/>x
              <input className={classes.textField} name={'height'} data-box={j} value={box.height}
              onChange={this.onChange}/>
              {'  '}
              <select value={box.dimUnit} name='dimUnit' data-box={j} onChange={this.onChange}>
                 <option default value="inches">in.</option>
                 <option value="centimeters">cm.</option>
               </select>
              </td>
            </tr>
          ))}

          <tr><td>
          <Button
          className={classes.buttonIn}
          onClick={this.addBox}
          >
            +Box
            </Button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      </Paper>
    );
    let labelInfo = (
      <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
        Shipment Workflow
      </Typography>
      <br/>
      <div align='center'>
        <Button variant="contained"
          className={classes.button}
          onClick={this.submitContent}
          >
          Submit Box Info
        </Button>
        <br/>
        <i className="fas fa-arrow-down"/>
        <br/>
        {shipment.partnered?(
          <div>
            {shipment.estimatedCost?('Inbound Shipping Estimate: $'+shipment.estimatedCost):'Submit Box Info to Get Inbound Shipping Cost'}
          <br/>
          <i className="fas fa-arrow-down"/>
          <br/>
          <Button variant="contained"
            disabled={shipment.workflowStep<2}
            className={classes.button}
            onClick={()=>{this.acceptOrVoid(shipment)}}
            >
            {shipment.acceptedShipping?'Void Shipping Cost':'Accept Shipping Cost'}
          </Button>
          <br/>
          <i className="fas fa-arrow-down"/>
          <br/>
          </div>
        ):null}
        <Button variant="contained"
          className={classes.button}
          onClick={this.getlabels}
          disabled={shipment.workflowStep<3}
          >
          Get Package Labels
        </Button>
      <br/><br/>
      </div>
      </Paper>
    );
    let currStep =(
      <div>
      <br/>
      <Grid container spacing={1}>
       <Grid item xs>
       <Paper className={classes.paper}>
       <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
          How Are These Shipped?
       </Typography>
       <div style={{marginLeft:'30px',lineHeight:'48px',textAlign:'left'}}>
         <label style={{lineHeight:'48px'}}>
           <Radio
             type="radio"
             name="sp"
             checked={shipment.sp}
             onChange={this.onChange}
           />
           {' '}<span  style={{verticalAlign: 'middle' }}>
           Small Parcel (Normal Boxes)</span>
         </label><br/>
         <label>
           <Radio
             type="radio"
             name="sp"
             checked={!shipment.sp}
             onChange={this.onChange}
           /><span style={{verticalAlign: 'middle' }}>
           {' '}LTL (Pallets)</span>
         </label>
         </div>
         {shipment.sp?null:carrierStep}
       </Paper>
       </Grid>
       <Grid item xs>
       <Paper className={classes.paper}>
       {shipment.sp?carrierStep:ltlInfo}
       </Paper>
       </Grid>
       </Grid>
       <Grid container spacing={1}>
        <Grid item xs={9}>
        {boxInfo}
        </Grid>
        <Grid item xs={3}>
        {labelInfo}
        </Grid>
        </Grid>
      </div>
    );
    let pgTitle = this.state.prevPgCreate?(
      "Create a New Shipment to Amazon (Step 4 of 4)"
    ):('Edit Shipment: '+shipment.shipmentId);
    properTier = (

      <div>
      <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h5" id="tableTitle" style={{textAlign:"left"}}>
      {pgTitle}
      </Typography><br/><div>
      {this.state.shipmentPlans.map((shipment,i)=>(
        <Button variant="contained"
          key = {'button'+i}
          className={classes.button}
          onClick={()=>this.setState({selectedShipment:i})}
          >
          {''+shipment.shipment_name}
        </Button>
      ))}
      </div>
      </Paper>
      {(this.props.shipments.loading)?loading:currStep}
      <br/><br/><br/>
      </div>

    );
  return (
    <div className="editshipment">
      <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs>
        {properTier}
        </Grid>
      </Grid>
      </div>
    </div>
  );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  shipments: state.shipments,
});

ShippingInfo.propTypes = {
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  shipments: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, { submitContent, acceptOrVoid,getlabels,gettransportcontent })(withStyles(styles)(ShippingInfo)))
