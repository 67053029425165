import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Actions
import { changePayment } from '../../actions/authActions';

// Stripe
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe,
} from 'react-stripe-elements';

// MaterialUI
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Components
import ConfirmationModal from '../admin/ungating/ConfirmationModal';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 768,
      lg: 900,
      xl: 1200
    }
  }
});

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
  paper: {
    minHeight: '1px',
    maxHeight: '1000px',
    [theme.breakpoints.up(768)]: {
      // minHeight:'383px',
      //maxHeight:'383px',
    },
    position: 'relative',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  button: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "2px",
    margin: "3px",
    width: '100%',
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    "&:disabled": {
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
  },
  planButton: {
    position: 'absolute',
    right: '16px',
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "3px",
    color: '#149cea',
    borderColor: '#149cea',
    "&:hover": {
      color: "#132f4a",
      borderColor: '#132f4a',
    },
    "&:disabled": {
      color: 'grey',
      backgroundColor: '#919EAA',
    },
    "&:hover": {
      color: "#132f4a"
    },
  },
  headline: {
    fontFamily: 'Raleway',
    fontSize: '16px',
    textAlign: "left"
  },
  link: {
    textDecoration: 'underline',
    "&:hover": {
      color: "#132f4a"
    }
  }
});
class _CardForm extends React.Component {
  constructor() {
    super();
    this.state = {
      termError: '',
      cardholder: '',
    };
  }

  onChange = (e) => {
    if (e.target.type !== 'checkbox') {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.checked });
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    if (this.state.cardholder.length < 1 || this.props.email.length < 1) {
      this.setState({ termError: 'Please add cardholder name' });
      return;
    }
    this.setState({ termError: '' });
    this.props.stripe.createToken({
      name: this.state.cardholder,
      email: this.props.email.toLowerCase(),
    }).then(token => {
      if (token.error) {
        return this.setState({ termError: token.error.message });
      }
      const data = {
        token
      };
      this.props.changePayment(data);
    });
  };

  render() {
    const { errors, pending } = this.props;
    const { termError } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div style={{ width: '100%' }}>
          Cardholder Name:
          <input disabled={pending}
            name="cardholder"
            type="cardholder"
            value={this.state.cardholder}
            onChange={this.onChange}
            className="form-control"
            style={{
              base: {
                fontFamily: 'Quicksand',
                fontSize: '17px',
                lineHeight: '1.429',
              },
            }}
          />
          Card Details:
          <CardElement disabled={pending}
            className="form-control"
            {...this.props}
            style={{
              base: {
                fontFamily: 'Quicksand',
                fontSize: '17px',
                lineHeight: '1.429',
              },
            }}
          />
        </div>
        {
          (errors.card || errors.payment) ?
            <small className="text-danger">{errors.card} {errors.payment}</small>
            : null
        }
        {
          (termError !== '') ?
            <small className="text-danger">{termError}</small>
            : null
        }
        <br />

        <Button type="submit"
          variant="contained"
          color="primary"
          disabled={pending}
          style={{
            width: '100%',
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "normal",
            padding: "10px",
            margin: "0px",
            backgroundColor: '#149cea',
            "&:hover": {
              color: "#132f4a"
            },
            "&:disabled": {
              color: 'white',
              backgroundColor: '#919EAA',
            },
            color: 'white',
            "&:hover": {
              color: "white"
            }
          }}
        >
          Save Payment Info
        </Button>
      </form>
    )
  }
}

const CardForm = injectStripe(_CardForm);

class ChangeCard extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      errors: {},
      stripe: null,
      stripeErrors: null,
      showErrorsModal: false,
      showSuccessModal: false,
    };
  }

  handleClickOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false, showSuccessModal: false });

  handleErrorModalClose = () => this.setState({ showErrorsModal: false, stripeErrors: null });

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.paymentRes) {
        if (this.props.auth.paymentRes.success === true) {
          this.setState({
            showSuccessModal: true,
          });
        } else if (this.props.auth.paymentRes.success === false) {
          this.setState({
            showErrorsModal: true,
            stripeErrors: this.props.auth.paymentErrors.payment,
          });
        }
      }
    }
  }

  componentDidMount() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      if (window.Stripe) {
        this.setState({ stripe: window.Stripe('pk_test_yTxtNK50fnYQ20mawyqVWY2y') });
      }
    } else {
      if (window.Stripe) {
        this.setState({ stripe: window.Stripe('pk_live_aszZgWDTsAm84X8YDj7N1H0i') });
      }
    }
  }

  render() {
    const { classes, auth } = this.props;
    const {
      errors,
      stripe,
      showErrorsModal,
      showSuccessModal,
      stripeErrors,
    } = this.state;
    const { pending } = auth;
    let modalContent, paymentContent;
    let onClose = this.handleClose;
    if (this.state.open) {
      paymentContent = (
        <div>
          <div >
            <StripeProvider stripe={stripe}>
              <Elements fonts={[{ cssSrc: "https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700" }]}>
                <CardForm
                  email={auth.user.email}
                  errors={errors}
                  pending={pending}
                  changePayment={this.props.changePayment}
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      );

      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth={'sm'}
          fullWidth={true}
        >
          <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">
              <i className="fas fa-user"></i>  Payment Information
            </Typography>
            {onClose ? (
              <IconButton 
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <DialogContent>
            <div className="container">
              <div className="spinner" hidden={!pending}></div>
              <div className={classes.root}>
                <MuiThemeProvider theme={theme}>
                  {paymentContent}
                </MuiThemeProvider>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = (
        <span></span>
      );
    }
    return (
      <>
        <span>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleClickOpen}
          >
            Add/Change Payment Card
          </Button>
          {modalContent}
        </span>
        {/* Modals */}
        {
          (showErrorsModal) ?
            <ConfirmationModal open={showErrorsModal}
              onClose={this.handleErrorModalClose}
              headerText="Credit card update"
              confirmationText={stripeErrors}
              confirmLabel="Ok"
              severity="error"
            /> :
            null
        }
        {
          (showSuccessModal) ?
            <ConfirmationModal open={showSuccessModal}
              onClose={this.handleClose}
              headerText="Credit card update"
              confirmationText="Credit card successfully updated."
              confirmLabel="Ok"
              severity="success"
            /> :
            null
        }
      </>
    );
  }
}

ChangeCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps, {
  changePayment,
})(withStyles(styles)(ChangeCard)));
