import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// MaterialUI
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Button } from '@material-ui/core';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';

// Components
import Spinner from '../common/Spinner';
import ConfirmationModal from './ungating/ConfirmationModal';

// Actions
import {
  getUngatingTypes,
  deleteUngatingType,
} from '../../actions/ungatingActions';

const styles = (theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  table: {
    fontFamily: "Quicksand,serif",
    margin: 'auto',
    tableLayout: 'auto',
    height: "100%",
    textAlign: 'center',
    fontSize: "14px",
    width: '100%'
  },
  cell: {
    minHeight: "30px",
    height: "30px",
    maxHeight: "30px",
    fontSize: "14px",
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
  },
  iconButton: {
    color: theme.palette.grey[500],
    outline: 'none !important',
  },
  newButton: {
    border:'0px',
    textTransform: "none",
    fontWeight: "normal",
    fontSize:'18px',
    lineHeight:'20px',
    backgroundColor: '#149cea',
    outline: 'none !important',
    "&:hover": {
      border:'0px',
      backgroundColor: "#132f4a"
    },
    "&:disabled":{
      border:'0px',
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
  },
});
class UngatingTypes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ungatings: [],
      showConfirmationModal: false,
      ungatingSelected: null,
    };
  }

  componentDidMount() {
    this.props.getUngatingTypes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ungating.ungatings !== this.props.ungating.ungatings) {
      this.setState({
        ungatings: this.props.ungating.ungatings,
      });
    }
  }

  handleShowConfirmationModal = (ungatingSelected) => {
    this.setState({
      showConfirmationModal: true,
      ungatingSelected: ungatingSelected,
    });
  };

  handleCloseConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
    }, () => {
      this.setState({
        ungatingSelected: null,
      });
    });
  };

  handleEditUngatingNavigation = (ungatingSelected) => {
    this.props.history.push(`/admin/ungating/${ungatingSelected._id}/edit`);
  };

  handleNewUngatingNavigation = () => {
    this.props.history.push("/admin/ungating/new");
  };

  handleDeleteCategory = () => {
    const { ungatingSelected } = this.state;
    this.props.deleteUngatingType(ungatingSelected._id);
  };

  render() {
    const {
      classes,
      ungating,
    } = this.props;
    const {
      ungatings,
      ungatingSelected,
      showConfirmationModal,
    } = this.state;
    return (
      <>
        <Typography className={classes.title}
          variant="h6"
          id="tableTitle"
        >
          Ungating Types
          <Button variant="contained"
            color="primary"
            className={classes.newButton}
            aria-label="Create"
            onClick={this.handleNewUngatingNavigation}
          >
            New
          </Button>
        </Typography>
        {ungating.loading ?
          <div style={{ textAlign: 'center' }}>
            <br />Loading...<Spinner />
          </div> :
          <div className="table-responsive">
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Visible</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {ungatings.map((row) => (
                  <tr key={row._id}>
                    <td className={classes.cell}
                      style={{ color: 'black' }}
                      padding="none"
                      align="center"
                    >
                      {row.name}
                    </td>
                    <td className={classes.cell}
                      style={{ color: 'black' }}
                      padding="none"
                      align="center"
                    >
                      {row.visible ? 'Yes' : 'No'}
                    </td>
                    <td className={classes.cell}
                      style={{ color: 'black' }}
                      padding="none"
                      align="center"
                    >
                      {row.createdAt.slice(0, 10)}
                    </td>
                    <td className={classes.cell}
                      style={{ color: 'black' }}
                      padding="none"
                      align="center"
                    >
                      {row.updatedAt.slice(0, 10)}
                    </td>
                    <td className={classes.cell}
                      style={{ color: 'black' }}
                      padding="none"
                      align="center"
                    >
                      <IconButton aria-label="Delete"
                        className={classes.iconButton}
                        onClick={() => this.handleShowConfirmationModal(row)}
                      >
                        <DeleteTwoToneIcon />
                      </IconButton>
                      <IconButton aria-label="Edit"
                        className={classes.iconButton}
                        onClick={() => this.handleEditUngatingNavigation(row)}
                      >
                        <EditTwoToneIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
        {/* Modals */}
        {
          (ungatingSelected && showConfirmationModal) ?
            <ConfirmationModal open={showConfirmationModal}
              onClose={this.handleCloseConfirmationModal}
              confirmationText={`Do you want to remove ${ungatingSelected.name} category?`}
              onConfirm={this.handleDeleteCategory}
              confirmLabel="Accept"
              cancelLabel="Cancel"
            /> :
            null
        }
      </>
    );
  }
}

UngatingTypes.propTypes = {
  ungating: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  ungating: state.ungating,
});

export default withRouter(connect(mapStateToProps, {
  getUngatingTypes,
  deleteUngatingType,
})(withStyles(styles)(UngatingTypes)));
