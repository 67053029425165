import React, { Component } from "react";

// PropTypes
import PropTypes from "prop-types";

// Router DOM
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { submitPayment, claimCode } from "../../actions/authActions";

// MaterialUI
import { Grid, Paper, Typography, Button, Checkbox } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// Components
import TextFieldGroup from "../common/TextFieldGroup";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 768,
      lg: 900,
      xl: 1200,
    },
  },
});

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit * 0.4,
    color: theme.palette.grey[500],
  },
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
  paper: {
    minHeight: "1px",
    maxHeight: "1000px",
    [theme.breakpoints.up(768)]: {
      // minHeight:'383px',
      //maxHeight:'383px',
    },
    position: "relative",
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  button: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "2px",
    margin: "3px",
    backgroundColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "#919EAA",
    },
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
  planButton: {
    position: "absolute",
    right: "16px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "3px",
    color: "#149cea",
    borderColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
      borderColor: "#132f4a",
    },
    "&:disabled": {
      color: "grey",
      backgroundColor: "#919EAA",
    },
    "&:hover": {
      color: "#132f4a",
    },
  },
  headline: {
    fontFamily: "Raleway",
    fontSize: "16px",
    textAlign: "left",
  },
  link: {
    textDecoration: "underline",
    "&:hover": {
      color: "#132f4a",
    },
  },
});

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      couponCode: "",
      errors: {},
      stripe: null,
      goodcoupon: false,
      terms: false,
      discount: 0,
    };
  }

  componentDidMount() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (window.Stripe) {
        this.setState({
          stripe: window.Stripe("pk_test_yTxtNK50fnYQ20mawyqVWY2y"),
        });
      }
    } else {
      if (window.Stripe) {
        this.setState({ stripe: window.Stripe('pk_live_aszZgWDTsAm84X8YDj7N1H0i') });
      }
    }
    let email;
    if (this.props.auth && this.props.auth.user && this.props.auth.user.email) {
      email = this.props.auth.user.email;
    }
    this.setState({ email });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
    if (
      prevProps.auth !== this.props.auth &&
      this.props.auth.data !== prevProps.auth.data
    ) {
      if (this.props.auth.data.validCode) {
        let discount = this.props.auth.data.discount;
        if (this.props.auth.data.discountType === "PERCENT") {
          discount *= this.props.categoryOrBrand.cost;
        }
        this.setState({
          discount,
          goodcoupon: true,
        });
      }
    }
  }

  onChange = (e) => {
    if (e.target.name === "couponCode") {
      this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.name === "terms") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  checkcouponCode = (e) => {
    const { categoryOrBrand } = this.props;
    this.setState({
      goodcoupon: false,
      discount: 0,
    });
    if (this.state.couponCode !== "") {
      this.props.claimCode({
        action: "check",
        couponCode: this.state.couponCode,
        productType: "ungating",
        restrictTo: categoryOrBrand._id,
      });
    }
  };

  submitPayment = () => {
    //step 2 payment Information
    let errors = {};
    const { terms } = this.state;
    if (!terms) {
      errors.payment = "Please accept the Terms and Conditions.";
    }
    this.setState({ errors });
    if (Object.keys(errors).length !== 0) {
      return;
    }
    const { password, email, couponCode } = this.state;
    const { categoryOrBrand } = this.props;
    const paymentInfo = {
      password,
      categoryOrBrand,
      couponCode,
      ungating: true,
      email: email.toLowerCase(),
    };
    this.props.submitPayment(paymentInfo);
  };

  render() {
    const { errors, discount, password, couponCode, goodcoupon, terms } =
      this.state;
    const { classes, auth, categoryOrBrand } = this.props;
    const costAfterDiscount = categoryOrBrand.cost - discount;
    if (
      errors.payment &&
      typeof errors.payment === "string" &&
      (errors.payment.indexOf("attached payment source") >= 0 ||
        errors.payment.indexOf("no active card") >= 0)
    ) {
      errors.payment = (
        <span>
          You need to add a payment card in{" "}
          <a className={classes.link} href="/settings">
            Settings
          </a>
        </span>
      );
    }
    return (
      <div className="container">
        <div className="spinner" hidden={!auth.pending}></div>
        <MuiThemeProvider theme={theme}>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <Paper className={classes.paper}>
                <Typography variant="h6" className={classes.headline}>
                  <i className="fas fa-credit-card" /> Confirm Payment
                  <br />
                  <br />
                </Typography>
                Password
                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                Coupon Code
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "70%",
                      vertialAlign: "top",
                      display: "inline-block",
                    }}
                    className="form-group"
                  >
                    <input
                      className={"form-control margin-bottom"}
                      placeholder="Coupon"
                      name="couponCode"
                      type="couponCode"
                      value={couponCode}
                      onChange={this.onChange}
                    />
                  </div>
                  <div
                    style={{
                      width: "29%",
                      vertialAlign: "top",
                      display: "inline-block",
                    }}
                  >
                    <Button
                      onClick={this.checkcouponCode}
                      variant="contained"
                      color="primary"
                      disabled={auth.pending}
                      style={{
                        marginTop: -2,
                        height: 38,
                        vertialAlign: "top",
                        width: "100%",
                        textTransform: "none",
                        fontWeight: "normal",
                        padding: "5px",
                        marginLeft: "1%",
                        backgroundColor: "#149cea",
                        color: "white",
                      }}
                    >
                      Apply Code
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "80%",
                    visibility: "visible",
                    color: "red",
                  }}
                >
                  {errors.coupon}
                </div>
                {goodcoupon ? (
                  <div
                    style={{
                      fontSize: "80%",
                      visibility: "visible",
                      color: "green",
                    }}
                  >
                    Valid Coupon!
                  </div>
                ) : null}
                <Checkbox
                  checked={terms}
                  onChange={this.onChange}
                  name="terms"
                  inputProps={{
                    "aria-label": "terms checkbox",
                  }}
                />{" "}
                Accept{" "}
                <a
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    "&:hover": {
                      color: "#132f4a",
                    },
                  }}
                  href="https://www.go-atlas.io/atlas-terms-of-service/"
                >
                  Terms
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    "&:hover": {
                      color: "#132f4a",
                    },
                  }}
                  href="https://www.go-atlas.io/atlas-access-terms-of-service/"
                >
                  Ungating Policy
                </a>
                <Button
                  onClick={this.submitPayment}
                  variant="contained"
                  color="primary"
                  disabled={auth.pending}
                  style={{
                    width: "100%",
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "normal",
                    padding: "10px",
                    margin: "0px",
                    backgroundColor: "#149cea",
                    "&:hover": {
                      color: "#132f4a",
                    },
                    "&:disabled": {
                      color: "white",
                      backgroundColor: "#919EAA",
                    },
                    color: "white",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  Get Ungated in {categoryOrBrand.name} for ${costAfterDiscount}
                </Button>
                {errors.card || errors.payment ? (
                  <small className="text-danger">
                    {errors.card}
                    {errors.payment}
                  </small>
                ) : null}
              </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
              <Paper className={classes.paper}>
                <Typography variant="h6" className={classes.headline}>
                  <i className="fas fa-shopping-cart"></i> Order Summary
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.headline}
                  style={{ textAlign: "center", fontSize: "25px" }}
                >
                  {categoryOrBrand.name}
                </Typography>
                <div
                  id="benefits"
                  style={{ paddingLeft: "19%", fontSize: "18px" }}
                >
                  <ul style={{ margin: 0 }} className="fa-ul">
                    {categoryOrBrand.benefits.split("\n").map((row, i) => (
                      <li key={i}>
                        <i
                          style={{ color: "black" }}
                          className="far fa-check-circle"
                          style={{ color: "#029cea" }}
                        ></i>{" "}
                        {row}
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#029cea",
                    fontFamily: "Raleway",
                    fontWeight: 1000,
                  }}
                >
                  <span style={{ fontSize: "18px" }}>$</span>
                  <span style={{ fontSize: "50px" }}>{costAfterDiscount}</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </div>
    );
  }
}

Checkout.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

class PurchaseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
      if (this.props.errors.ungating === "Not Purchased") {
        this.handleClickOpen();
      }
    }
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.paymentRes && this.props.auth.paymentRes.success) {
        this.handleClose();
      }
    }
  }

  render() {
    const { classes, auth, errors, ungating, claimCode, submitPayment } =
      this.props;
    const { open } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        maxWidth={"md"}
        fullWidth={true}
      >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">
            Purchase Atlas Access Ungating Service for {ungating.name}
          </Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>
          <Checkout
            categoryOrBrand={ungating}
            claimCode={claimCode}
            submitPayment={submitPayment}
            auth={auth}
            errors={errors}
            classes={classes}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

PurchaseModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(
  connect(mapStateToProps, { submitPayment, claimCode })(
    withStyles(styles)(PurchaseModal)
  )
);
