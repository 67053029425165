import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper,Switch,Radio} from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';
import {Chart} from 'react-google-charts';
import Spinner from '../common/Spinner';
import Select from 'react-select'

const pieColorArray = [
  '#264c99', '#a52a0d', '#bf7200',
  '#0c7012', '#720072', '#007294',
  '#b72153', '#4c7f00', '#8a2222',
  '#244a6f', '#723372', '#197f72',
  '#7f7f0c', '#4c2699', '#ac5600',
  '#680505', '#4b0c4d', '#256d49',
  '#3f577c', '#2c2e81', '#895619',
  '#10a017', '#8a0e62', '#d30b79',
  '#754227', '#7e930e', '#1f5969',
  '#4c6914', '#8e7b0e', '#084219',
  '#57270c'
];
const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "initial"
  })
};
const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button: {
    fontSize: "14px",
    fontWeight: "normal",
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  table: {
    margin:'auto',
    //minWidth: "100px",
    tableLayout: 'auto',
    height:"100%",
    textAlign:'center',
    fontSize: "14px",
    width: '100%'
  },
  tableRow:{
    minWidth: "75%",
    width:"75%",
    maxWidth:'332px',
    textAlign:'center',
    minHeight: "30px",
    height:"30px",
    maxHeight:"30px",
    fontSize: "14px",
  },
  cell: {
    minHeight: "30px",
    height:"30px",
    maxHeight:"30px",
    fontSize: "14px",
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "150px",
    overflow: "hidden",
  },
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: "332px",
  },
  paperSmall: {
    fontSize: "14px",
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: "82px",
    maxHeight: "82px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  skuSelector:{
    textAlign: 'left',
    fontSize: "14px",
    margin: "5px",
    width:"90%"
  },
  radio: {
    color: '#149cea',
    '&$checked': {
      color: '#1084c7',
    },
  },
  checked: {},
  colorSwitchBase: {
    color: '#149cea',
    '&$colorChecked': {
      color: '#149cea',
      '&$colorBar': {
        backgroundColor: '#b8b8b8',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

class ReportDialog extends React.Component {
  state = {
    selectedOption:[],
    rockerChecked:true,
    radioValue:'revenue',
    growthData: [],
    tableData:[],
    mOmGrowth:0,
  };

  createGrowthData = (data) => {
    if (data === null || data.length < 1) return 0
    let today = new Date();
    let firstDayThis = new Date(today.getFullYear(), today.getMonth(), 1);
    let firstDayLast = new Date(today.getFullYear(), today.getMonth() - 1 , 1);
    let daysinMonth = new Date(today.getFullYear(), today.getMonth()+1, 0).getDate();
    let dayinLast = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    let maxDays = Math.max(daysinMonth,dayinLast);
  
    let growthData = [['Day of Month','Last Month','This Month']]
      for (let j = 1; j < maxDays+1; j++) {
        growthData.push([j,0,0])
        if (j>today.getDate()){
          growthData[j][2]=null
        }
        if (j>dayinLast){
          growthData[j][1]=null
        }
      }
      for (let i = 1; i < data.length; i++) {
  
        if (new Date(data[i][0]).getMonth() === today.getMonth()){
  
        }
        if (firstDayThis.getMonth() === new Date(data[i][0]).getMonth()){
          growthData[new Date(data[i][0]).getDate()][2] += Number(data[i][1]);
          if (new Date(data[i][0]).getDate() === today.getDate()){
          }
        }
        if (firstDayLast.getMonth() === new Date(data[i][0]).getMonth()){
          growthData[new Date(data[i][0]).getDate()][1] += Number(data[i][1]);
        }
      }
      let profitLast = 0;
      let profitSoFar = 0
      for (let k = 1; k < today.getDate(); k++) {
        profitLast += Number(growthData[k][1]);
        profitSoFar += Number(growthData[k][2]);
      }
      let mOmGrowth = profitSoFar/profitLast
      
      return {growthData,mOmGrowth}
  }

multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(selected => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
    const val = `${label}${labelSuffix}`;
  return val;
};

chooseSku = selectedOption => {
  this.setState({ selectedOption });
};


inventoryValueGraphData = (data) => {
  let perItem = [
    ['Title', 'Sale Value', 'Purchase Costs']
  ];
 let totalCOGS = 0;
 let totalSalePrice = 0;
 

  if (Object.values(data).length === 0 || data.constructor !== Array) return perItem
  data.sort((b, a) => parseFloat(a.stock * a.buyPrice + a.stock * a.salePrice) - parseFloat(b.stock * b.buyPrice + b.stock * b.salePrice));
  for (let i = 0; i < data.length; i++) {
    if (data[i].stock * data[i].salePrice !== 0 || data[i].stock * data[i].buyPrice !== 0){
      totalCOGS += data[i].stock * data[i].buyPrice;
      totalSalePrice += data[i].stock * data[i].salePrice;
      perItem.push([data[i].title.substring(0, 100) + "...", (data[i].stock * data[i].salePrice), (data[i].stock * data[i].buyPrice)])
    }
  }

  let totalValue = [
    [ 
      "Title",
      "Sale Value (amount you’ll potentially make when items sell on Amazon)",
      'Purchase Costs (amount you’ve already spent buying items from retailers)',  
    ],
     ["",totalSalePrice,totalCOGS*-1],
  ]
  return {perItem, totalValue}
}

skuAnalysisReport = (orders, startDate, endDate,rockerChecked,radioValue) => {
  let numbDays = Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / (24 * 60 * 60 * 1000)));
  const {selectedOption} = this.state;
  let reply = {}
  reply.skuOptions = [];
  reply.tableObj = {};
  reply.graphArray = [["Date"]];
  reply.pieArray = [["SKU","Revenue"]];
  if (orders.length < 1) return reply
  //create dates for array
  for (let d = 1; d < numbDays + 1; d++) {
    let result = new Date(startDate);
    result.setDate(result.getDate() + d);
    reply.graphArray.push([new Date(result.setHours(0, 0, 0, 0))])
    if (selectedOption !== null) {
      for (let s = 0; s < selectedOption.length; s++) {
        reply.graphArray[d].push(0)
      }
    }
  }
  //Loop over all orders //
  for (let i = 0; i < orders.length; i++) {
    let thisSku = orders[i].sku;
    if (!orders[i].purchasedPrice) orders[i].purchasedPrice = 0;
    let testArr = reply.skuOptions.filter(skuSet => (skuSet.value === thisSku));
    if (testArr.length === 0) { //see if we haven't added this sku to the selector
      reply.skuOptions.push({
        value: thisSku,
        label: orders[i].title.substring(0, 50) + '...'
      }) //add to selector
      if (selectedOption !== null && selectedOption.filter(skuSet => (skuSet.value === thisSku)).length !== 0) { //see if this sku has been selected
        //if it has, add it to the arrays
        let profit = (isNaN(orders[i].purchasedPrice)?0:orders[i].purchasedPrice) - Math.abs(isNaN(orders[i].buyPrice)?0:orders[i].buyPrice) - Math.abs(isNaN(orders[i].fbaFee)?0:orders[i].fbaFee) - Math.abs(isNaN(orders[i].referalFee)?0:orders[i].referalFee);
        if (orders[i].promotions && orders[i].promotions.length > 0) {
          let totalFee =
            ((typeof orders[i].shippingCharge === "undefined") ? 0 : Number(orders[i].shippingCharge)) +
            ((typeof orders[i].shippingChargeback === "undefined") ? 0 : Number(orders[i].shippingChargeback));
          for (let k = 0; k < orders[i].promotions.length; k++) {
            let currPromo = orders[i].promotions[k];
            if (currPromo.promotionAmount) {
              totalFee += currPromo.promotionAmount;
            }
          }
          profit += totalFee;
        }
        reply.tableObj[thisSku] = {
          title: orders[i].title,
          sku: orders[i].sku,
          asin: orders[i].asin,
          quantity: orders[i].quantity,
          revenue: isNaN(orders[i].purchasedPrice)?0:orders[i].purchasedPrice,
          profit:isNaN(profit)?0:profit
        };

        //Graph Array ---------------//

        reply.graphArray[0].push(thisSku);
        let thisOrderDate = new Date(orders[i].purchaseDate)
        thisOrderDate = new Date(thisOrderDate.setHours(0,0,0,0))
        let whichDate = reply.graphArray.findIndex(e => new Date(e[0]).getTime() === thisOrderDate.getTime())
        let whichSku = reply.graphArray[0].findIndex(e => e === thisSku)
        if (whichDate >= 0 && whichSku >= 0) reply.graphArray[whichDate][whichSku] += radioValue==='profit'?profit:radioValue === 'revenue'?orders[i].purchasedPrice:orders[i][radioValue];

        //--------------------------//

      }
    } else if (reply.tableObj[thisSku]) {

      let profit = (isNaN(orders[i].purchasedPrice)?0:orders[i].purchasedPrice) - Math.abs(isNaN(orders[i].buyPrice)?0:orders[i].buyPrice) - Math.abs(isNaN(orders[i].fbaFee)?0:orders[i].fbaFee) - Math.abs(isNaN(orders[i].referalFee)?0:orders[i].referalFee);
      if (orders[i].promotions && orders[i].promotions.length > 0) {
        let totalFee =
          ((typeof orders[i].shippingCharge === "undefined") ? 0 : Number(orders[i].shippingCharge)) +
          ((typeof orders[i].shippingChargeback === "undefined") ? 0 : Number(orders[i].shippingChargeback));
        for (let k = 0; k < orders[i].promotions.length; k++) {
          let currPromo = orders[i].promotions[k];
          if (currPromo.promotionAmount) {
            totalFee += currPromo.promotionAmount;
          }
        }
        profit += totalFee;
      }
      //if this sku has already been added to the arrays and is selected, we need to add up the totals
      reply.tableObj[thisSku].revenue += isNaN(orders[i].purchasedPrice)?0:orders[i].purchasedPrice;
      reply.tableObj[thisSku].profit += isNaN(profit)?0:profit;
      reply.tableObj[thisSku].quantity += orders[i].quantity;

      //-------------Graph Array ---------------//

      let thisOrderDate = new Date(orders[i].purchaseDate)
      thisOrderDate = new Date(thisOrderDate.setHours(0,0,0,0))
      let whichDate = reply.graphArray.findIndex(e => new Date(e[0]).getTime() === thisOrderDate.getTime())
      let whichSku = reply.graphArray[0].findIndex(e => e === thisSku)
      if (whichDate >= 0 && whichSku >= 0) reply.graphArray[whichDate][whichSku] += radioValue==='profit'?profit:radioValue === 'revenue'?orders[i].purchasedPrice:orders[i][radioValue];
      //---------------------------------------//
    }

  }
  //sort alphabetically//
  reply.skuOptions.sort(function(a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  //----------------//
  //Create Pi Array //
    Object.keys(reply.tableObj).map((row, i) => {
        reply.pieArray.push(['('+reply.tableObj[row].sku+') '+reply.tableObj[row].title.substring(0, 40) + '...',reply.tableObj[row][radioValue]]);
        return 0
    })
    //----------------//
    //If SUM SKUs is on....//

    if (!rockerChecked){
      let summedArray = [["Date","Total"]];
      for (let i = 1; i<reply.graphArray.length;i++){
        let totalSum = 0;
        for (let j = 1; j<reply.graphArray[i].length;j++){
          totalSum +=reply.graphArray[i][j];
        }
        summedArray[i] = [reply.graphArray[i][0],totalSum];
      }
      reply.graphArray = summedArray;
    }
    if (reply.graphArray[0].length < 2) reply.graphArray = []

    //-------------------//
  return reply;
}

rockerChange = name => event => {
  this.setState({ [name]: event.target.checked });
}

radioChange = event => {
  this.setState({ radioValue: event.target.value });
};

  render() {
    const { classes, selectedReport, reqReport, loading, startDate, endDate ,tableData } = this.props;
    let reportGraph,invReportGraph,reportPieChart,reportTable,reportData,skuSelectorComponent,reportRocker,reportRadio,tableTotal;
    if (loading) {
      reportGraph = (
        <div>Crunching the Numbers...<Spinner/></div>
      )
    } else {
      
       if (selectedReport === 'Inventory Value') {
        reportData = this.inventoryValueGraphData(reqReport);
        
        if (Object.values(reportData).length===0 || reportData.constructor !== Object) reportData = {perItem:[],totalValue:{}}
        
        invReportGraph = (
            <div>
            Inventory Sale Value & Purchase Costs (per-item) 
            <Chart
            formatters={[
              {
                 type: "NumberFormat",
                 column: 1,
                 options: {
                    prefix: "$",
                  }
               },{
                type: "NumberFormat",
                column: 2,
                options: {
                   prefix: "$",
                 }
              }
           ]}
            
            width = {"100%"}
            height={'280px'}
            chartType="BarChart"
            loader={<div>Crunching the Numbers...<Spinner/></div>}
            data={reportData.perItem}
            options={{
                      allowHtml: true,
                      fontName:"Quicksand,serif",
                      isStacked: true,
                      legend:{
                        position:'top'
                      },
                      tooltip:{
                        showColorCode: true,
                        ignoreBounds:true,
                        isHtml:true,
                        text:'value',
                        textStyle:{fontSize:14}
                      },
                      animation:{
                        startup:true,
                        duration: 500,
                        easing: 'out',
                      },
                      chartArea:{width:"75%",height:"75%"},
                      vAxis: { textPosition: 'none' },
                      hAxis:{format: '$#,###.##',gridlines: { count: 30 }},
                      hAxes: {0: {
                                gridlines: {color: '#D3D3D3'}}
                              },
                    }}
          chartPackages={["corechart", "controls"]}
            rootProps={{ 'data-testid': '1' }}
            chartEvents = {
              [{
                eventName: "error",
                callback: (a) => {
                  //hide dumb error about gettime being null
                  //a.google.visualization.errors.removeError(a.eventArgs[0].id);
                }
              }]
            }
          />
          </div>
        );
        reportPieChart = (
          <div>Breakdown of Inventory (by sale value)
           <Chart
             legend= {{position: 'bottom'}}
             width={'100%'}
             height={280}
             chartType="PieChart"
             loader={<div>Loading Chart</div>}
             data={reportData.perItem}
             formatters={[
              {
                 type: "NumberFormat",
                 column: 1,
                 options: {
                    prefix: "$",
                  }
               }
           ]}
             options={{
               colors: pieColorArray,
              fontSize:12,
               pieHole: 0.33,
               fontName:"Quicksand,serif",
               //is3D: true,
               legend:{
                 position:'bottom',
                 textStyle: {fontSize: 13}
               },
               chartArea:{right:0,left:0,top:10
               },
               pieSliceText:'percentage',
               tooltip:{
                 showColorCode: true,
                 ignoreBounds:false,
                 isHtml:true,
                 text:'both',
                 textStyle:{fontSize:14},
                 trigger:'selection'
               }

             }}
             rootProps={{ 'data-testid': '1' }}
           />
          </div>
        );
        reportTable = (
          <div>Inventory Stats
          <div className="table-responsive">
           <table className={classes.table}>
             <thead>
               <tr>
                <th></th>
                 <th>Title</th>
                 <th>SKU</th>
                 <th>Stock</th>
                 <th>Sale Value</th>
                 <th>Purchase Costs</th>
               </tr>
             </thead>
             <tbody >
                  {(Object.values(reqReport).length===0 || reqReport.constructor !== Array)?null:
                  reqReport.map((row,i) => (
                    <tr key={row.sku}>
                    <td className={classes.cell} style={{color: pieColorArray[i]}} padding="none" align="center"><i className="fas fa-square-full"></i></td>
                     <td className={classes.cell} style={{color: 'black'}}  padding="none" align="center">{row.title}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{row.sku}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{row.stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${Number(row.stock*row.salePrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${Number(row.stock*row.buyPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    </tr>
                  ))}
             </tbody>
           </table>
           </div>
          </div>
        );
        reportGraph = (
          <div>
          Total Inventory Value
          <Chart
          width = {"100%"}
          height={'280px'}
          chartType="BarChart"
          loader={<div>Crunching the Numbers...<Spinner/></div>}
          data={reportData.totalValue}
          formatters={[
            {
               type: "NumberFormat",
               column: 1,
               options: {
                  prefix: "$",
                }
             },{
              type: "NumberFormat",
              column: 2,
              options: {
                 prefix: "$",
               }
            }
         ]}
          options={{
                    allowHtml: true,
                    colors: ['green', 'red'],
                    fontName:"Quicksand,serif",
                    isStacked: false,
                    legend: {position: 'top'},
                    vAxis: { textPosition: 'none' },
                    tooltip:{
                      showColorCode: true,
                      ignoreBounds:false,
                      isHtml:true,
                      text:'value',
                      textStyle:{fontSize:14}
                    },
                    animation:{
                      startup:true,
                      duration: 500,
                      easing: 'out',
                    },
                    chartArea:{width:"75%",height:"75%"},
                    hAxis:{format: '$#,###.##',gridlines: { count: 30 }},
                    hAxes: {0: {
                              gridlines: {color: '#D3D3D3'},}
                            },
                  }}
          chartPackages={["corechart", "controls"]}
          rootProps={{ 'data-testid': '1' }}
          chartEvents = {
            [{
              eventName: "error",
              callback: (a) => {
                //hide dumb error about gettime being null
                //a.google.visualization.errors.removeError(a.eventArgs[0].id);
              }
            }]
          }
        />
        </div>
      );
        
      }
      else if (selectedReport === 'MoM Report'){
        console.log(this.props.tableData)
        reportData = this.createGrowthData(this.props.tableData);
        if (Object.values(reportData).length===0) reportData = [];
            reportGraph = (
            <div>
            <Chart
            width = {"100%"}
            height={'250px'}
            chartType="LineChart"
            loader={<div>Crunching the Numbers...<Spinner/></div>}
            data={reportData.growthData}
            options={{
                      fontName:"Quicksand,serif",
                      curveType: 'function',
                      legend:{
                        position:'top'
                      },
                      tooltip:{
                        showColorCode: true,
                        ignoreBounds:true,
                        isHtml:true,
                        text:'value',
                        textStyle:{fontSize:14}
                      },
                      animation:{
                        startup:true,
                        duration: 500,
                        easing: 'out',
                      },
                      explorer: {
                        actions: ['dragToZoom', 'rightClickToReset'],
                        axis: 'horizontal',
                        keepInBounds: true,
                        maxZoomIn: 5,
                        maxZoomOut: 8.0
                        },
                      chartArea:{width:"80%",height:"75%"},
                      colors: ['#BEBEBE', '#149cea'],
                      vAxis: { title: 'Profit',viewWindowMode: "explicit", viewWindow:{ min: 0 },titleTextStyle:{italic:false} },
                      vAxes: {0: {
                                gridlines: {color: '#D3D3D3'},title: 'Profit'}
                              },
                      hAxis: { title: 'Day of Month' },
                      seriesType: 'line'
                    }}
          chartPackages={["corechart", "controls"]}
            rootProps={{ 'data-testid': '1' }}
            chartEvents = {
              [{
                eventName: "error",
                callback: (a) => {
                  //hide dumb error about gettime being null
                  a.google.visualization.errors.removeError(a.eventArgs[0].id);
                }
              }]
            }
          />
          Current Growth Rate of <strong>{Number((reportData.mOmGrowth-1)*100).toFixed(3)}%</strong> over last month
          </div>
          );
        
      }
      else if (selectedReport === 'SKU Analysis Report'){
        reportData = this.skuAnalysisReport(reqReport,startDate, endDate,this.state.rockerChecked,this.state.radioValue);
        tableTotal = {quantity:0,revenue:0,profit:0,margin:0}
        Object.keys(reportData.tableObj).map((row) => {
            tableTotal.quantity += Number(reportData.tableObj[row].quantity)
            tableTotal.revenue += Number(reportData.tableObj[row].revenue)
            tableTotal.profit += Number(reportData.tableObj[row].profit)
            return 0;
         })
        if (Object.values(reportData).length===0) reportData = {}
        skuSelectorComponent = (
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paperSmall}>
            <Select
              isMulti
              name="skuSelector"
              maxHeight={48}
              options={reportData.skuOptions}
              defaultValue={this.state.selectedOption}
              className={classes.skuSelector}
              classNamePrefix="select"
              onChange={this.chooseSku}
              closeMenuOnSelect={false}
              placeholder="Select SKU(s)"
              hideSelectedOptions={false}
              components={{
                MultiValueContainer: this.multiValueContainer
                // Option: CustomOption,
              }}
              styles={customStyles}
            />
            </Paper>
          </Grid>
        );
        reportRadio = (
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paperSmall}>
            Revenue
            <Radio
              checked={this.state.radioValue === 'revenue'}
              onChange={this.radioChange}
              value="revenue"
              name="radio-button"
              aria-label="Revenue"
              classes={{
                root: classes.radio,
                checked: classes.checked,
              }}
            />
            Profit
            <Radio
              checked={this.state.radioValue === 'profit'}
              onChange={this.radioChange}
              value="profit"
              name="radio-button"
              aria-label="Profit"
              classes={{
                root: classes.radio,
                checked: classes.checked,
              }}
            />
            Units
            <Radio
              checked={this.state.radioValue === 'quantity'}
              onChange={this.radioChange}
              value="quantity"
              name="radio-button"
              aria-label="Quantity"
              classes={{
                root: classes.radio,
                checked: classes.checked,
              }}
            />
            </Paper>
          </Grid>
        )
        reportRocker = (
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paperSmall}>
            Sum SKUs
            <Switch
              margin="none"
              checked={this.state.rockerChecked}
              onChange={this.rockerChange('rockerChecked')}
              value="rockerChecked"
              color="default"
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked,
                bar: classes.colorBar,
              }}
            />
            Compare SKUs
            </Paper>
          </Grid>
        )
        reportGraph = (
            <div>
            Graph
            <Chart
            width = {"100%"}
            height={'280px'}
            chartType="LineChart"
            loader={<div>Crunching the Numbers...<Spinner/></div>}
            data={reportData.graphArray}
            options={{
                      fontName:"Quicksand,serif",
                      curveType: 'function',
                      colors: pieColorArray,
                      isStacked: true,
                      legend: {position: 'none'},
                      tooltip:{
                        showColorCode: true,
                        ignoreBounds:false,
                        isHtml:true,
                        text:'value',
                        textStyle:{fontSize:14}
                      },
                      animation:{
                        startup:true,
                        duration: 500,
                        easing: 'out',
                      },
                      chartArea:{width:"75%",height:"75%"},
                      vAxis: { textPosition: 'left' },
                      hAxes: {0: {
                                gridlines: {color: '#D3D3D3'},title: 'Date'}
                              },
                    }}
            chartPackages={["corechart", "controls"]}
            rootProps={{ 'data-testid': '1' }}
            chartEvents = {
              [{
                eventName: "error",
                callback: (a) => {
                  //hide dumb error about gettime being null
                  a.google.visualization.errors.removeError(a.eventArgs[0].id);
                }
              }]
            }
          />
          </div>
        );
        reportPieChart = (
          <div>Pie Chart
           <Chart
             legend= {{position: 'bottom'}}
             width={'100%'}
             height={280}
             chartType="PieChart"
             loader={<div>Loading Chart</div>}
             data={reportData.pieArray}
             options={{
               colors: pieColorArray,
              fontSize:12,
               pieHole: 0.33,
               fontName:"Quicksand,serif",
               //is3D: true,
               legend:{
                 position:'bottom',
                 textStyle: {fontSize: 13}
               },
               chartArea:{right:0,left:0,top:10
               },
               pieSliceText:'percentage',
               tooltip:{
                 showColorCode: true,
                 ignoreBounds:false,
                 isHtml:true,
                 text:'both',
                 textStyle:{fontSize:14},
                 trigger:'selection'
               }

             }}
             rootProps={{ 'data-testid': '1' }}
           />
          </div>
        );
        reportTable = (
          <div>Table
          <div className="table-responsive">
           <table className={classes.table}>
             <thead>
               <tr>
                 <th></th>
                 <th>Title</th>
                 <th>SKU</th>
                 <th>ASIN</th>
                 <th>Units</th>
                 <th>Revenue</th>
                 <th>Profit</th>
                 <th>Margin</th>
               </tr>
             </thead>
             <tbody >
                 {Object.keys(reportData.tableObj).map((row, i) => {

                   return (
                   <tr key={i}>
                      <td className={classes.cell} style={{color: pieColorArray[i]}} padding="none" align="center"><i className="fas fa-square-full"></i></td>
                     <td className={classes.cell} style={{color: 'black'}}  padding="none" align="center">{reportData.tableObj[row].title}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{reportData.tableObj[row].sku}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{reportData.tableObj[row].asin}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{Number(reportData.tableObj[row].quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${Number(reportData.tableObj[row].revenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${Number(reportData.tableObj[row].profit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                     <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{Number(reportData.tableObj[row].profit/reportData.tableObj[row].revenue*100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</td>
                    </tr>)
                  })}
                  <tr key='total'>
                  <td className={classes.cell} style={{fontWeight: 'bold',color: 'black'}} padding="none" align="center">TOTAL</td>
                  <td className={classes.cell} style={{color: 'black'}} padding="none" align="center"></td>
                  <td className={classes.cell} style={{color: 'black'}} padding="none" align="center"></td>
                  <td className={classes.cell} style={{color: 'black'}} padding="none" align="center"></td>
                  <td className={classes.cell} style={{fontWeight: 'bold',color: 'black'}} padding="none" align="center">{Number(tableTotal.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td className={classes.cell} style={{fontWeight: 'bold',color: 'black'}} padding="none" align="center">${Number(tableTotal.revenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td className={classes.cell} style={{fontWeight: 'bold',color: 'black'}} padding="none" align="center">${Number(tableTotal.profit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td className={classes.cell} style={{fontWeight: 'bold',color: 'black'}} padding="none" align="center">{Number(tableTotal.profit/tableTotal.revenue*100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</td>
                  </tr>
             </tbody>
           </table>
           </div>
          </div>
        );
      }
    }

    return (
      <div>
      <Grid container spacing={1}>
      {selectedReport === 'SKU Analysis Report'?skuSelectorComponent:null}
      {selectedReport === 'SKU Analysis Report'?reportRadio:null}
      {selectedReport === 'SKU Analysis Report'?reportRocker:null}
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
          <div>
          {reportGraph}
          </div>
          </Paper>
        </Grid>
        {reportPieChart &&<Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
          {reportPieChart}
          </Paper>
        </Grid>}
        {selectedReport === 'Inventory Value'?<>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
          {invReportGraph}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
          {reportTable}
          </Paper>
        </Grid>
        </>
        :
        reportTable && <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
          {reportTable}
          </Paper>
        </Grid>}
      </Grid>

      </div>
    );
  }
}

ReportDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportDialog);
