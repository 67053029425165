import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { inventoryValueReport,skuAnalysisReport } from '../../actions/reportActions';
import { getTableData } from '../../actions/orderActions';

import Spinner from '../common/Spinner';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {Chart} from 'react-google-charts';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as LinkIn } from 'react-router-dom';
import ReportDialog  from './ReportDialog';

let counter = 0;
function createtabData( name, revenue, margin, profit, units ) {
  counter += 1;
  return { id: counter, name ,revenue, margin, profit, units };
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit *2,
    flexGrow: 1,
    marginBottom: theme.spacing.unit *2,
},
table: {
  fontFamily:"Quicksand,serif",
  margin:'auto',
  //minWidth: "100px",
  tableLayout: 'auto',
  height:"100%",
  textAlign:'center',
  fontSize: "14px",
  width: '100%'
},
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  datePick:{
    fontSize: "14px",
    fontWeight: "normal",
  },
  button:{
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "normal",
    padding:"2px",
    margin:"3px"
  },
   link:{
      color:"blue",
      textDecoration: "underline"
    },
    datepicker:{
      fontFamily:"Quicksand,serif",
      border: "1px solid grey",
      maxWidth:"70px",
      height:"26px",
      width:"100% !important",
      borderStyle:"none",
    },
    dateWrapper:{
      display:"inline",
      textTransform: "none",
      fontSize: "12px",
      fontWeight: "normal",
      padding:"2px",
      margin:"3px"
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    tableRow:{
      minWidth: "75%",
      width:"75%",
      maxWidth:'332px',
      textAlign:'center',
      minHeight: "30px",
      height:"30px",
      maxHeight:"30px",
      fontSize: "14px",
    },
    cell: {
      minHeight: "30px",
      height:"30px",
      maxHeight:"30px",
      fontSize: "14px",
      borderTop: '1px solid #ddd',
      borderBottom: '1px solid #ddd',
    },
    title: {
      flex: '0 0 auto',
      aligh: "center"
    }
});

class Reports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(new Date() - 1000 * 60 * 60 * 24 * 7),
      endDate: new Date(),
      orders: [],
      errors: {},
      reqReport: [],
      reports:[],
      tableData:[],
      chartChanger: 5,
      selectedReport:''
    };
  }

componentDidUpdate(prevProps, prevState) {
  if (prevProps.errors !== this.props.errors) {
    this.setState({
      errors: this.props.errors
    });
  }
  if(prevProps.reports!==this.props.reports && this.props.reports.reqReport){
    this.setState({
        reports:  this.props.reports
      });
    if (prevProps.reports.reqReport !== this.props.reports.reqReport) {
      this.setState({
          reqReport: this.props.reports.reqReport,
        });
    }
  }
  if(prevProps.orders!==this.props.orders && this.props.orders.tableData){
    let tableData = this.props.orders.tableData;
    this.setState({
        tableData
      });
  }
}

 componentDidMount() { 
   this.inventoryValueReport()
 }

  setNewDates = (startDate, endDate) => {
    startDate = startDate.setHours(0, 0, 0, 0);
    endDate = endDate.setHours(0, 0, 0, 0);
    const {selectedReport} = this.state;
    if (startDate > endDate){
      toast.error("Start Date must be older than End Date!");
      return 0;
    }
    this.setState({startDate:(new Date(startDate)),endDate:new Date(endDate)});
    if (selectedReport === 'SKU Analysis Report') this.props.skuAnalysisReport(startDate, endDate);
  }



  inventoryValueReport = () => {
    this.setState({selectedReport:'Inventory Value', reqReport: ''}, () => {
      this.props.inventoryValueReport(this.state.startDate, this.state.endDate);
    });
  }

  momReport = () => {
    this.setState({selectedReport:'MoM Report', reqReport: ''}, () => {
      this.props.getTableData(this.state.tableData);
    });
  }

  skuAnalysisReport = () => {
    this.setState({selectedReport:'SKU Analysis Report', reqReport: ''}, () => {
      this.props.skuAnalysisReport(this.state.startDate, this.state.endDate);
    });
  }

  render() {
    const { user } = this.props.auth;
    const errors = this.props.errors;
    const { classes } = this.props;
    const { loading } = this.props.reports;
    const { reqReport,chartChanger } = this.state;
    const errorTest = Object.keys(errors).length;
    let reportsContent;
    let reportTypePicker = (
      <div>
      <Button variant="outlined" className={classes.button} onClick={this.inventoryValueReport}>
      Inventory Value
      </Button>
      <Button variant="outlined" className={classes.button} onClick={this.skuAnalysisReport}>
      SKU Analysis
      </Button>
      <Button variant="outlined" className={classes.button} onClick={this.momReport}>
      Month over Month Growth
      </Button>
      </div>
    );
    let datePicker = (
      <div>
      <Button disabled={(loading&&!errorTest) ? true:false} variant="outlined" className={classes.button} onClick={() => {
        let startDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 7);
        let endDate = new Date();
        this.setNewDates(startDate, endDate)
      }}>
        7 Days
      </Button>
      <Button disabled={loading ? true:false} variant="outlined" className={classes.button} onClick={() => {
        let startDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
        let endDate = new Date();
        this.setNewDates(startDate, endDate)
      }}>
        30 Days
      </Button>
      <Button disabled={loading ? true:false} variant="outlined" className={classes.button} onClick={() => {
        let startDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 90);
        let endDate = new Date();
        this.setNewDates(startDate, endDate)
      }}>
        90 Days
      </Button>
      {this.state.showDatePicker?<div className={classes.dateWrapper}><div className={classes.dateWrapper}>
      <DatePicker
        disabled={loading ? true:false}
        className={classes.datepicker}
        selected={this.state.startDate}
        maxDate={this.state.endDate}
        onChange={(e) => {this.setNewDates(e, this.state.endDate)}}
        />
        </div>
        <div className={classes.dateWrapper}>
          <i className="fas fa-arrow-right"></i>
        </div>
        <div className={classes.dateWrapper}>
        <DatePicker
          disabled={loading ? true:false}
          className={classes.datepicker}
          selected={this.state.endDate}
          minDate={this.state.startDate}
          maxDate={new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()+1)}
          onChange={(e) => {this.setNewDates(this.state.startDate, e)}}
          />
      </div>
      </div>:<Button disabled={loading ? true:false} variant="outlined" className={classes.button} onClick={() => {
        this.setState({showDatePicker:true})
      }}>
        Custom
      </Button>}
      </div>
    )

    if(!user.sellerID){
      reportsContent = (
      <Paper className={classes.root}>
        To view your reports, you need to authenticate with Amazon in &nbsp;
        <LinkIn className={classes.link} to="/settings">
        Settings
        </LinkIn>
      </Paper>
    );
  } else {
      reportsContent = (
          <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
              <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left",marginLeft:"20px"}}>
                    Reports
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
              {reportTypePicker}
              </Grid>
                <Grid item xs={12} sm={6}>
                {this.state.selectedReport==='SKU Analysis Report'?datePicker:null}
                </Grid>
              </Grid>
            </Paper>
            </Grid>
          </Grid>
          {this.state.selectedReport === ''?null:
            <ReportDialog
            startDate = {this.state.startDate}
            endDate = {this.state.endDate}
            selectedReport = {this.state.selectedReport}
            reqReport = {this.state.reqReport}
            loading = {loading}
            tableData = {this.state.tableData}
            />
          }

        </div>
      );
}
      return (
        <div className="reports">
          {reportsContent}
        </div>
      );
    }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  reports: state.reports,
  orders:state.orders
});

Reports.propTypes = {
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  reports:PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, { inventoryValueReport,skuAnalysisReport, getTableData })(withStyles(styles)(Reports)))
