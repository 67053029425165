import {
  LOADING_PAGE,
  GET_ORDERS,
  GET_GRAPH_DATA,
  GET_PIE_DATA,
  GET_TABLE_DATA
} from '../actions/types';

const initialState = {
  orders: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_PAGE:
      return {
        ...state,
        loading: true
      }
    case GET_ORDERS:
      return {
        ...state,
        loading: false,
        orders: action.payload
      }
    case GET_GRAPH_DATA:
      return {
        ...state,
        loading: false,
        graphData: action.payload
      }
    case GET_PIE_DATA:
      return {
        ...state,
        loading: false,
        pieData: action.payload
      }
    case GET_TABLE_DATA:
      return {
        ...state,
        loading: false,
        tableData: action.payload
      }
    default:
      return state;
  }
}
