import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { forgotPassword, resetPassword } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
      stage: 1,
      newpassword: "",
      newpassword2: "",
      token: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPassSubmit = this.onPassSubmit.bind(this);
  }

  onPassSubmit(e) {
    e.preventDefault();

    const newUser = {
      token: this.state.token,
      newpassword: this.state.newpassword,
      newpassword2: this.state.newpassword2,
    };
    this.props.resetPassword(newUser, this.props.history);
  }

  componentDidMount() {
    if (this.props.match.params.token)
      if (this.props.match.params.token === "wait") {
        this.setState({ stage: 2 });
      } else if (this.props.match.params.token === "success") {
        this.setState({ stage: 4 });
      } else {
        this.setState({ stage: 3, token: this.props.match.params.token });
      }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.match.params.token){
      if (nextProps.match.params.token === "success") {
        this.setState({ stage: 4 });
      }
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const newUser = {
      email: this.state.email.toLowerCase(),
    };
    this.props.forgotPassword(newUser, this.props.history);
  }

  render() {
    const { errors, stage } = this.state;
    let forgotContent;
    if (stage === 2) {
      forgotContent = (
        <div className="col-md-8 m-auto">
          <br />
          <h1 className="text-center">Forgot Password</h1>
          <p className="lead text-center">
            We sent you a link to reset your password.
            <br />
            Be sure to check your spam folder if you don't see it.
          </p>
        </div>
      );
    } else if (stage === 1) {
      forgotContent = (
        <div className="col-md-8 m-auto">
          <br />
          <h1 className="text-center">Forgot Password</h1>
          <p className="lead text-center">
            Reset your password by entering your email
          </p>
          <form noValidate onSubmit={this.onSubmit}>
            <TextFieldGroup
              placeholder="Email"
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.onChange}
              error={errors.email || errors.user}
            />
            <input
              type="submit"
              value="Request Password Reset"
              className="btn btn-info btn-block mt-4"
            />
          </form>
        </div>
      );
    } else if (stage === 3) {
      forgotContent = (
        <div className="col-md-8 m-auto">
          <br />
          <h1 className="text-center">Forgot Password</h1>
          <p className="lead text-center">Enter a New Password</p>
          <form noValidate onSubmit={this.onPassSubmit}>
            <TextFieldGroup
              placeholder="Password"
              name="newpassword"
              type="password"
              value={this.state.newpassword}
              onChange={this.onChange}
              error={errors.newpassword}
            />
            <TextFieldGroup
              placeholder="Confirm Password"
              name="newpassword2"
              type="password"
              value={this.state.newpassword2}
              onChange={this.onChange}
              error={errors.newpassword2}
            />
            {errors.token}
            {errors.reset}
            <input
              type="submit"
              value="Reset Password"
              className="btn btn-info btn-block mt-4"
            />
          </form>
        </div>
      );
    }
    if (stage === 4) {
      forgotContent = (
        <div className="col-md-8 m-auto">
          <br />
          <h1 className="text-center">Forgot Password</h1>
          <br/><br/>
          <p className="lead text-center">
            <p className="text-success">Success. Password Reset.</p>
            <Link to={`/login`} style={{ textDecoration: "underline" }}>
              {" "}
              Please log in with your new password.
            </Link>
          </p>
        </div>
      );
    }
    return (
      <div className="forgotPassword">
        <div className="container">
          <div className="row">{forgotContent}</div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { forgotPassword, resetPassword })(
  withRouter(ForgotPassword)
);
