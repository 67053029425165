import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../common/Spinner";
import spinner from "../common/spinner.gif";
import { getOrders, saveOrder } from "../../actions/orderActions";
import { getInventory } from "../../actions/inventoryActions";

import FormDialog from "./Modal";
import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Link,
  TextField,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { Link as LinkIn } from "react-router-dom";

let counter = 0;
function createData(orderId, skus, displayDate, status) {
  counter += 1;
  return { id: counter, orderId, skus, displayDate, status };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "displayDate", disablePadding: false, label: "Purchase Date" },
  { id: "status", disablePadding: false, label: "Status" },
  { id: "orderId", disablePadding: false, label: "Order Number" },
  { id: "title", disablePadding: false, label: "Title" },
  { id: "skus", disablePadding: false, label: "Identifiers" },
  { id: "quantity", disablePadding: false, label: "Qty" },
  { id: "profit", disablePadding: false, label: "Profit" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={"center"}
                padding={"none"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={row.label}
                  placement={"bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={
                      row.id === "displayDate" ||
                      row.id === "title" ||
                      row.id === "status"
                        ? this.createSortHandler(row.id)
                        : null
                    }
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    fontFamily: "Quicksand,serif",
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontFamily: "Quicksand,serif",
    flex: "0 0 auto",
    textAlign: "center",
  },
  search: {
    marginLeft: "auto",
    marginRight: "20px",
  },
  button: {
    margin: theme.spacing.unit,
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    classes,
    value,
    handleSearch,
    rowCount,
    getSelectedOrders,
    loading,
    rangePicked,
  } = props;
  let loadingBar;
  if (loading) {
    loadingBar = (
      <img
        src={spinner}
        style={{ width: "30px", margin: "0", display: "inline" }}
        alt='Loading...'
      />
    );
  } else {
    loadingBar = null;
  }
  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant='h6' id='tableTitle'>
        You've had <strong>{rowCount}</strong> orders in the past &nbsp;
        <select
          disabled={loading ? true : false}
          value={rangePicked}
          onChange={getSelectedOrders}
        >
          <option value={3}>3 Days</option>
          <option value={7}>7 Days</option>
          <option value={14}>14 Days</option>
          <option value={30}>30 Days</option>
          <option value={90}>90 Days</option>
        </select>
      </Typography>
      {loadingBar}
      <div align='right' className={classes.search}>
        <TextField placeholder='Search' onChange={handleSearch} value={value} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    fontFamily: "Quicksand,serif",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  cell: {
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    overflow: "hidden",
    padding: "10px",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
  table: {
    tableLayout: "auto",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "50%",
  },
  orderArr2: {
    height: "60px",
    maxHeight: "60px",
  },
  orderArr: {
    borderTop: "1px solid #D3D3D3",
    margin: "auto",
    maxHeight: "60px",
    height: "60px",
  },
  statusStyle: {
    background: "#149cea", //blue
    borderRadius: 6,
    color: "white",
    //boxShadow: '3px 5px 2px rgba(0,0,0, .3)',
    width: "80px",
    height: "30px",
    lineHeight: "30px",
    maxWidth: "70%",
    margin: "0 auto",
    textAlign: "center",
  },
  statusStylePending: {
    color: "white",
    background: "#919EAA", //grey
    borderRadius: 6,
    //boxShadow: '3px 5px 2px rgba(0,0,0, .3)',
    width: "80px",
    height: "30px",
    lineHeight: "30px",
    maxWidth: "70%",
    margin: "0 auto",
    textAlign: "center",
  },
});

class Orders extends React.Component {
  state = {
    order: "desc",
    orderBy: "displayDate",
    selected: [],
    orders: [],
    inventory: [],
    filteredOrders: [],
    page: 0,
    rowsPerPage: 50,
    query: "none",
    startDate: new Date(
      new Date() -
        1000 *
          60 *
          60 *
          24 *
          (JSON.parse(localStorage.getItem("rangePicked")) || 7)
    ),
    endDate: new Date(),
    rangePicked: JSON.parse(localStorage.getItem("rangePicked")) || 3,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({
      order,
      orderBy,
    });
  };
  getSelectedOrders = (event) => {
    let rangePicked = Number(event.target.value);
    let startDate = new Date(new Date() - 1000 * 60 * 60 * 24 * rangePicked);
    let endDate = new Date();
    this.getOrders(startDate, endDate);
    this.setState(
      {
        rangePicked,
      },
      () => {
        localStorage.setItem(
          "rangePicked",
          JSON.stringify(this.state.rangePicked)
        );
      }
    );
  };

  handleKeyDown = (event, id) => {
    this.handleClick(event, id);
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selected: newSelected,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleSearch = (event) => {
    let input, filter, a, b, i, j, k, txtValue, filteredData;
    input = event.target.value;
    filter = event.target.value.toUpperCase();
    filteredData = [];
    const { orders } = this.state;
    const { inventory } = this.state;
    for (i = 0; i < orders.length; i++) {
      a = orders[i];
      for (j = 0; j < orders[i].skus.length; j++) {
        for (k = 0; k < inventory.length; k++) {
          if (inventory[k].sku === orders[i].skus[j].sku) {
            b = inventory[k];
          }
        }
      }
      txtValue = JSON.stringify(a) + JSON.stringify(b);
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        filteredData.push(orders[i]);
      }
    }

    this.setState({
      filteredOrders: filteredData,
      query: input,
    });
  };
  getInventory = (inv) => {
    this.props.getInventory(inv);
  };

  getOrders = (startdate, enddate, ord) => {
    this.props.getOrders(startdate, enddate, ord);
  };
  sortAndSetOrders = () => {
    if (this.props.orders && this.props.orders.orders) {
      let ord = [];
      for (let i = 0; i < this.props.orders.orders.length; i++) {
        let tempOrders = this.props.orders.orders;
        let displayDate;
        if (tempOrders[i].purchaseDate) {
          displayDate =
            "" +
            new Date(tempOrders[i].purchaseDate).getFullYear() +
            "/" +
            ("0" + (new Date(tempOrders[i].purchaseDate).getMonth() + 1)).slice(
              -2
            ) +
            "/" +
            ("0" + new Date(tempOrders[i].purchaseDate).getDate()).slice(-2) +
            "\n" +
            new Date(tempOrders[i].purchaseDate).toString().slice(16, 16 + 8);
        } else if (tempOrders[i].postedDate) {
          displayDate =
            "" +
            new Date(tempOrders[i].postedDate).getFullYear() +
            "/" +
            ("0" + (new Date(tempOrders[i].postedDate).getMonth() + 1)).slice(
              -2
            ) +
            "/" +
            ("0" + new Date(tempOrders[i].postedDate).getDate()).slice(-2) +
            "\n" +
            new Date(tempOrders[i].postedDate).toString().slice(16, 16 + 8);
        }
        ord.push(
          createData(
            tempOrders[i].orderId,
            tempOrders[i].skus,
            displayDate,
            tempOrders[i].status
          )
        );
      }
      this.setState({
        orders: ord,
        filteredOrders: ord,
      });
    }
    if (this.props.inventory !== this.props.inventory.inventory) {
      this.setState({
        inventory: this.props.inventory.inventory,
      });
    }
  };
  componentDidMount = () => {
    if (
      this.props.auth &&
      this.props.auth.user &&
      this.props.auth.user.sellerID &&
      this.props.auth.user.sellerID !== null
    ) {
      this.getOrders(
        this.state.startDate,
        this.state.endDate,
        this.props.orders.orders
      );
      this.getInventory(this.props.inventory.inventory);
      this.sortAndSetOrders();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
    if (prevProps.orders !== this.props.orders && this.props.orders.orders) {
      let ord = [];
      for (let i = 0; i < this.props.orders.orders.length; i++) {
        let tempOrders = this.props.orders.orders;
        let displayDate;
        if (tempOrders[i].purchaseDate) {
          displayDate =
            "" +
            new Date(tempOrders[i].purchaseDate).getFullYear() +
            "/" +
            ("0" + (new Date(tempOrders[i].purchaseDate).getMonth() + 1)).slice(
              -2
            ) +
            "/" +
            ("0" + new Date(tempOrders[i].purchaseDate).getDate()).slice(-2) +
            " " +
            new Date(tempOrders[i].purchaseDate).toString().slice(16, 16 + 8);
        } else if (tempOrders[i].postedDate) {
          displayDate =
            "" +
            new Date(tempOrders[i].postedDate).getFullYear() +
            "/" +
            ("0" + (new Date(tempOrders[i].postedDate).getMonth() + 1)).slice(
              -2
            ) +
            "/" +
            ("0" + new Date(tempOrders[i].postedDate).getDate()).slice(-2) +
            " " +
            new Date(tempOrders[i].postedDate).toString().slice(16, 16 + 8);
        }
        ord.push(
          createData(
            tempOrders[i].orderId,
            tempOrders[i].skus,
            displayDate,
            tempOrders[i].status
          )
        );
      }
      this.setState({
        orders: ord,
        filteredOrders: ord,
      });
    }

    if (prevProps.inventory !== this.props.inventory) {
      this.setState({
        inventory: this.props.inventory.inventory,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      orders,
      filteredOrders,
      order,
      orderBy,
      rowsPerPage,
      page,
      inventory,
    } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage);
    const { user } = this.props.auth;
    const { loading } = this.props.orders;
    let ordersContent;
    if (orders === null || this.props.orders.orders === null) {
      ordersContent = <Spinner />;
    } else if (!user.sellerID) {
      ordersContent = (
        <Paper className={classes.root}>
          To view your orders, you need to authenticate with Amazon in &nbsp;
          <LinkIn className={classes.link} to='/settings'>
            Settings
          </LinkIn>
        </Paper>
      );
    } else {
      ordersContent = (
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            rangePicked={this.state.rangePicked}
            loading={loading}
            handleSearch={this.handleSearch}
            value={this.searchValue}
            getSelectedOrders={this.getSelectedOrders}
            rowCount={filteredOrders.length}
          />

          <div className={classes.tableWrapper} style={{ height: "60vh" }}>
            <Table className={classes.table} aria-labelledby='tableTitle'>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={filteredOrders.length}
                className={classes.cell}
              />
              <TableBody>
                {stableSort(
                  this.state.filteredOrders,
                  getSorting(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        aria-checked={isSelected}
                        tabIndex={0}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          <div>
                            <span>{n.displayDate.slice(0, 10)}</span>
                            <br />
                            <span>{n.displayDate.slice(10, 25)}</span>
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          <div
                            className={
                              n.status === "Shipped"
                                ? classes.statusStyle
                                : classes.statusStylePending
                            }
                          >
                            {n.status}
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          <Link
                            style={{ color: "black" }}
                            target='_blank'
                            rel='noreferrer'
                            href={
                              "https://sellercentral.amazon.com/orders-v3/order/" +
                              n.orderId
                            }
                          >
                            {n.orderId}
                          </Link>
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='left'
                        >
                          {n.skus.map((e, i) => {
                            let foundTitle = "n/a";
                            inventory.find(function (element) {
                              if (element.sku === e.sku) {
                                return (foundTitle = element.title);
                              } else return null;
                            });
                            if (i > 0) {
                              return (
                                <div
                                  className={classes.orderArr}
                                  key={i}
                                  style={{
                                    whiteSpace: "wrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {" "}
                                  {foundTitle}
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  className={classes.orderArr2}
                                  key={i}
                                  style={{
                                    whiteSpace: "wrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {" "}
                                  {foundTitle}
                                </div>
                              );
                            }
                          })}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='left'
                        >
                          {n.skus.map((e, i) => {
                            let foundAsin = "n/a";
                            let asinlink = "https://www.amazon.com";
                            inventory.find(function (element) {
                              if (element.sku === e.sku) {
                                foundAsin = element.asin;
                                asinlink =
                                  "https://www.amazon.com/gp/product/" +
                                  foundAsin;
                                return { foundAsin, asinlink };
                              } else return null;
                            });
                            if (i > 0) {
                              return (
                                <div className={classes.orderArr} key={i}>
                                  <span>SKU: </span>
                                  <span>{e.sku}</span>
                                  <br /> ASIN:{" "}
                                  <Link
                                    target='_blank'
                                    className={classes.link}
                                    rel='noreferrer'
                                    href={asinlink}
                                  >
                                    {foundAsin}
                                  </Link>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  className={classes.orderArr2}
                                  key={"0" + i}
                                >
                                  <span>SKU: </span>
                                  <span>{e.sku}</span>
                                  <br /> ASIN:{" "}
                                  <Link
                                    target='_blank'
                                    className={classes.link}
                                    rel='noreferrer'
                                    href={asinlink}
                                  >
                                    {foundAsin}
                                  </Link>
                                </div>
                              );
                            }
                          })}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          {n.skus.map((e, i) => {
                            if (i > 0) {
                              return (
                                <div className={classes.orderArr} key={i}>
                                  {e.quantity}
                                </div>
                              );
                            } else {
                              return (
                                <div className={classes.orderArr2} key={i}>
                                  {e.quantity}
                                </div>
                              );
                            }
                          })}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          <FormDialog
                            orderInfo={n}
                            status={n.status}
                            saveOrder={(order) =>
                              this.props.saveOrder(
                                this.state.startDate,
                                this.state.endDate,
                                order
                              )
                            }
                            inventory={n.skus.map((e, i) => {
                              let foundSkus = [];
                              inventory.find(function (element) {
                                if (element.sku === e.sku) {
                                  return foundSkus.push(element);
                                } else return null;
                              });
                              return foundSkus;
                            })}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 0 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component='div'
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      );
    }
    return <div className={classes.root}>{ordersContent}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  orders: state.orders,
  inventory: state.inventory,
});

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, { getOrders, getInventory, saveOrder })(
    withStyles(styles)(Orders)
  )
);
