import React, { PureComponent } from "react";

// MaterialUI
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Components
import WysiwygEditor from './WysiwygEditor';

const styles = () => ({
  editorContainer: {
    borderRadius: "4px",
    border: "1px solid lightgrey",
    fontFamily: "Quicksand,serif",
    padding: "10px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  textField: {
    width: "25%",
  },
});
class UngatingStepEditor extends PureComponent {
  // Refs
  mailEditor = null;

  constructor(props) {
    super(props);
    this.state = {
      stepEditor: { ...props.values }
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    this.setState({
      stepEditor: {
        ...this.state.stepEditor,
        [name]: value,
      },
    });
  };

  getDataToStore = () => {
    if (this.mailEditor) {
      const { stepEditor } = this.state;
      const content = this.mailEditor.getDataToStore();
      return {
        title: stepEditor.title,
        content,
      };
    }
  };

  render() {
    const { stepEditor } = this.state;
    const { classes, editorId, stepNumber } = this.props;
    return (
      <>
        <div className={classes.editorContainer}>
          <TextField
            id="title"
            label={`Step ${stepNumber} Title`}
            value={stepEditor.title}
            name="title"
            onChange={this.handleInputChange}
            className={classes.textField}
          />
          <WysiwygEditor
            editorId={editorId}
            innerRef={r => this.mailEditor = r}
            initialState={stepEditor.content}
            placeholder={`Step ${stepNumber} Content`}
          />
        </div>
      </>
    );
  }
}

export default (withStyles(styles)(UngatingStepEditor));
