import axios from 'axios';

import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_ANNOUNCE,
  ANNOUNCE_LOADING,
} from "./types";

const BASE_URL = '/api/announce';

function clearAnnounce() {
  return {
    type: GET_ANNOUNCE,
    payload: {
      success: false,
      text: '',
    }
  };
}

function clearErrors() {
  return {
    type: CLEAR_ERRORS
  };
}

// Get last announce
export const getAnnounce = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get(BASE_URL)
    .then(res =>
      dispatch({
        type: GET_ANNOUNCE,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      dispatch(clearAnnounce());
    });
};

export const createAnnounce = (postData) => dispatch => {
  dispatch(clearErrors());
  dispatch({
    type: ANNOUNCE_LOADING,
  });
  axios
    .post(BASE_URL, postData)
    .then(res =>
      dispatch({
        type: GET_ANNOUNCE,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      dispatch(clearAnnounce());
    });
};
