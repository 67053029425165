import axios from "axios";

import {
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING_PAGE,
  GET_ORDERS,
  GET_GRAPH_DATA,
  GET_PIE_DATA,
  GET_TABLE_DATA,
  STOP_LOADING_PAGE,
} from "./types";

export const saveOrder = (startdate, enddate, order) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  startdate = new Date(startdate);
  enddate = new Date(enddate);
  axios
    .post("/api/orders/saveOrder/" + startdate + "/" + enddate,order)
    .then((res) =>
      dispatch(
        {
          type: GET_ORDERS,
          payload: res.data,
        },
        dispatch(stopLoading())
      )
    )
    .catch((err) => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
    });
};

export const getOrders = (startdate, enddate, ord) => (dispatch) => {
  if (typeof ord === "undefined" || ord.length === 0) {
    dispatch(clearErrors());
    dispatch(setLoading());
    startdate = new Date(startdate);
    enddate = new Date(enddate);
    axios
      .get("/api/orders/orderpage/" + startdate + "/" + enddate)
      .then((res) =>
        dispatch(
          {
            type: GET_ORDERS,
            payload: res.data,
          },
          dispatch(stopLoading())
        )
      )
      .catch((err) => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
      });
  }
};

export const getGraphData = (startdate, enddate, data) => (dispatch) => {
  if (typeof data === "undefined" || data.length === 0) {
    dispatch(clearErrors());
    dispatch(setLoading());
    startdate = new Date(startdate);
    enddate = new Date(enddate);
    axios
      .get("/api/orders/graphdata/" + startdate + "/" + enddate)
      .then((res) =>
        dispatch(
          {
            type: GET_GRAPH_DATA,
            payload: res.data,
          },
          dispatch(stopLoading())
        )
      )
      .catch((err) => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
      });
  }
};

export const getTableData = (data) => (dispatch) => {
  if (typeof data === "undefined" || data.length === 0) {
    dispatch(clearErrors());
    dispatch(setLoading());
    let startdate = new Date(new Date() - 1000 * 60 * 60 * 24 * 90);
    let enddate = new Date();
    axios
      .get("/api/orders/graphdata/" + startdate + "/" + enddate)
      .then((res) =>
        dispatch(
          {
            type: GET_TABLE_DATA,
            payload: res.data,
          },
          dispatch(stopLoading())
        )
      )
      .catch((err) => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
      });
  }
};

export const getPieData = (startdate, enddate, data) => (dispatch) => {
  if (typeof data === "undefined" || data.length === 0) {
    dispatch(clearErrors());
    dispatch(setLoading());
    startdate = new Date(startdate);
    enddate = new Date(enddate);
    axios
      .get("/api/orders/piedata/" + startdate + "/" + enddate)
      .then((res) =>
        dispatch(
          {
            type: GET_PIE_DATA,
            payload: res.data,
          },
          dispatch(stopLoading())
        )
      )
      .catch((err) => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
      });
  }
};

export const setLoading = () => {
  return {
    type: LOADING_PAGE,
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING_PAGE,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
