import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as qs from 'query-string';

// Actions
import { confirmUser, resendConfirmationEmail } from '../../actions/authActions';

// Styles
import './ConfirmEmail.css';

class ConfirmEmail extends Component {
  state = {
    redirectTo: '',
    success: null,
    code: null,
  };

  componentDidMount() {
    const parsed = qs.parse(this.props.location.search);
    if (parsed.code) {
      this.props.confirmUser(parsed.code, this.props.history);
      this.setState({ code: parsed.code });
    } else {
      this.setState({ redirectTo: '/' });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        success: false,
      });
    }
    if (nextProps.auth && nextProps.auth.email) {
      this.setState({
        success: nextProps.auth.email.success,
        emailSent: nextProps.auth.email.sent,
      });
    }
  }

  resendEmail = (event) => {
    event.preventDefault();
    this.props.resendConfirmationEmail({ code: this.state.code });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />
    }

    const { errors, success, emailSent } = this.state;

    return (
      <div className="confirmEmail">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto"><br/>
              <h1 className="text-center">Confirm Email</h1>
              {success === null && (
                <p>
                  Sending data for verification...
                </p>
              )}
              {success === false && Object.keys(errors).length > 0 && (
                <p className="text-danger">
                  {errors.code || errors.user || errors.message}
                </p>
              )}
              {success === true && (
                <p className="text-success">
                  {emailSent ? 'Confirmation email sent successfully' : 'Email confirmed successfully.'}
                </p>
              )}
              <div className="btn-container">
                <a href="/oaleads" className="btn btn-info">Go to App</a>
                {errors && errors.code && (
                  <button onClick={this.resendEmail} disabled={emailSent} className="btn btn-info">
                    Send new code
                  </button>
                )}
              </div>
            </div>
          </div>
      </div>

      </div>
    );
  }
}

ConfirmEmail.propTypes = {
  confirmUser: PropTypes.func.isRequired,
  resendConfirmationEmail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { confirmUser, resendConfirmationEmail })(ConfirmEmail);
