import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const ignorePaths = [
  '/login', '/forgotpassword', '/confirm', '/validate', '/signup', '/ungatingsignup', '/ungatingpackages', '/loading',
  '/register',
];

export function shouldIgnoreRedirect() {
  return ignorePaths.some(p => window.location.pathname.startsWith(p));
}

class ErrorHandler extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors === 'Unauthorized' && this.props.errors !== nextProps.errors) {
      if (this.props.location.pathname !== '/' && !shouldIgnoreRedirect()) {
        let goTo = '/login?loginRedirect=' + this.props.location.pathname.replace(/^\//, '');
        nextProps.history.replace(goTo);
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({ errors: state.errors });

export default withRouter(connect(mapStateToProps)(ErrorHandler));