import {
  LOADING_PAGE,
  GET_INVENTORY
} from '../actions/types';

const initialState = {
  inventory: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_PAGE:
      return {
        ...state,
        loading: true
      }
    case GET_INVENTORY:
      return {
        ...state,
        loading: false,
        inventory: action.payload
      }
    default:
      return state;
  }
}
