import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import TextFieldGroup from "../common/TextFieldGroup";
import { withStyles } from "@material-ui/core/styles";
import FormDialog from "./Modal";
import Spinner from "../common/Spinner";
import { getAllUsers, adminLogin, getSubInfo } from "../../actions/authActions";
import { createAnnounce } from "../../actions/announceActions";
import UngatingTypes from "./UngatingTypes";

const styles = (theme) => ({
  root: {
    fontFamily: "Quicksand,serif",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: "332px",
  },
  paperAuto: {
    padding: theme.spacing.unit * 2,
    //textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "normal",
    padding: "2px",
    margin: "3px",
  },
  buttonAnnounce: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "2px",
    margin: "3px",
    backgroundColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "#919EAA",
    },
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
  table: {
    fontFamily: "Quicksand,serif",
    margin: "auto",
    //minWidth: "100px",
    tableLayout: "auto",
    height: "100%",
    textAlign: "center",
    fontSize: "14px",
    width: "100%",
  },
  cell: {
    minHeight: "30px",
    height: "30px",
    maxHeight: "30px",
    fontSize: "14px",
    borderTop: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
  },
});

function getSubscriptionFlavor(flavor) {
  if (flavor === "Replens splitted 1") {
    return "Replens A";
  }
  if (flavor === "Replens splitted 2") {
    return "Replens B";
  }
  if (flavor === "Replens splitted 3") {
    return "Replens A_2";
  }
  if (flavor === "Replens splitted 4") {
    return "Replens B_2";
  }
  return flavor;
}

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      subInfo: [],
      errors: {},
      announceText: props.announce.text || "",
    };
  }
  componentDidMount = () => {
    this.props.getAllUsers();
    this.props.getSubInfo();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.allUsers && this.props.auth.allUsers.length > 0) {
        this.setState({
          allUsers: this.props.auth.allUsers,
        });
      }
      if (this.props.auth.subInfo && this.props.auth.subInfo.length > 0) {
        this.setState({
          subInfo: this.props.auth.subInfo,
        });
      }
    }
    if (prevProps.announce.text !== this.props.announce.text) {
      this.setState({
        announceText: this.props.announce.text,
      });
    }
  }

  handleAnnounceChange = (e) => {
    this.setState({
      announceText: e.target.value,
    });
  };

  onCreateAnnounce = (e) => {
    e.preventDefault();
    const { announceText } = this.state;
    if (this.props.createAnnounce) {
      this.props.createAnnounce({ announce: announceText });
    }
  };

  render() {
    const { classes } = this.props;
    const { user } = this.props.auth;
    let testimonials = [];
    for (let i = 0; i < this.state.allUsers.length; i++) {
      if (
        this.state.allUsers[i].testimonials &&
        this.state.allUsers[i].testimonials.length >= 1
      ) {
        for (let j = 0; j < this.state.allUsers[i].testimonials.length; j++) {
          testimonials.push(this.state.allUsers[i].testimonials[j]); //get all testimonials
        }
      }
    }
    testimonials.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    let adminContent;
    if (user.admin === true) {
      adminContent = (
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                style={{ textAlign: "left" }}
              >
                Subscription Totals
              </Typography>
              {this.props.auth.pending ? (
                <div style={{ textAlign: "center" }}>
                  <br />
                  Loading...
                  <Spinner />
                </div>
              ) : (
                <div className="table-responsive">
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th>List Flavor</th>
                        <th>Total Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subInfo.map((row, i) => {
                        const flavorName = getSubscriptionFlavor(row.flavor);
                        const isTotal =
                          flavorName === "Total Replens" ||
                          flavorName === "Total";
                        return (
                          <tr key={i}>
                            <td
                              className={classes.cell}
                              style={{ color: "black" }}
                              padding="none"
                              align="center"
                            >
                              {isTotal ? (
                                <strong>{flavorName}</strong>
                              ) : (
                                flavorName
                              )}
                            </td>
                            <td
                              className={classes.cell}
                              style={{ color: "black" }}
                              padding="none"
                              align="center"
                            >
                              {isTotal ? (
                                <strong>{row.total}</strong>
                              ) : (
                                row.total
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                style={{ textAlign: "left" }}
              >
                Testimonials
              </Typography>
              <div
                style={{ overflow: "auto", maxHeight: "268px" }}
                className="table-responsive"
              >
                <table className={classes.table}>
                  <tbody>
                    {testimonials.map((testimonial, j) => (
                      <tr key={j}>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="left"
                        >
                          {testimonial.testimonialType}:{" "}
                          <strong>"{testimonial.testimonial}"</strong>
                          {" - " +
                            testimonial.name +
                            " on " +
                            testimonial.date.slice(0, 10)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paperAuto}>
              <UngatingTypes />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paperAuto}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                style={{ textAlign: "left" }}
              >
                Announcements
              </Typography>
              <form
                style={{ position: "relative" }}
                onSubmit={this.onCreateAnnounce}
              >
                <TextFieldGroup
                  placeholder="Announce Text"
                  name="announceText"
                  type="text"
                  value={this.state.announceText}
                  onChange={this.handleAnnounceChange}
                  error={this.state.errors.user}
                />
                <div style={{ color: "red" }}>
                  {this.state.errors.user ? this.state.errors.user : null}
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.buttonAnnounce}
                  disabled={
                    this.props.announce.loading ||
                    (this.state.announceText || "").trim() ===
                      this.props.announce.text
                  }
                >
                  {this.props.announce.loading ? "Sending..." : "Submit"}
                </Button>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                style={{ textAlign: "left" }}
              >
                User List:
              </Typography>
              <div className="table-responsive">
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Authed?</th>
                      <th>Tier</th>
                      <th>Cancel Data</th>
                      <th>Register Date</th>
                      <th>Last Login</th>
                      <th>Login As</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allUsers.map((row, i) => (
                      <tr key={row.date}>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          {row.name}
                        </td>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          {row.email}
                        </td>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          {row.mwsAuthed ? "True" : "False"}
                        </td>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          {row.tier}
                        </td>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          {row.cancelData && row.cancelData.length > 0 ? (
                            <FormDialog cancelData={row.cancelData} />
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          {row.date
                            ? new Date(row.date).toLocaleString()
                            : null}
                        </td>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          {row.lastLogin
                            ? new Date(row.lastLogin).toLocaleString()
                            : null}
                        </td>
                        <td
                          className={classes.cell}
                          style={{ color: "black" }}
                          padding="none"
                          align="center"
                        >
                          <Button
                            variant="contained"
                            className={classes.button}
                            disabled={row.accepted}
                            onClick={() =>
                              this.props.adminLogin(this.state.allUsers[i])
                            }
                          >
                            Login
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Paper>
          </Grid>
        </Grid>
      );
    } else {
      adminContent = (
        <Grid container spacing={1}>
          <Grid item xs>
            <Paper className={classes.paper}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                style={{ textAlign: "left" }}
              >
                Admin
              </Typography>
              You're not logged into an admin account. Your current login is:{" "}
              {user.email}
            </Paper>
          </Grid>
        </Grid>
      );
    }
    return (
      <div className="admin">
        <div className={classes.root}>{adminContent}</div>
      </div>
    );
  }
}

Admin.propTypes = {
  leads: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  announce: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  leads: state.leads,
  errors: state.errors,
  announce: state.announce,
});

export default withRouter(
  connect(mapStateToProps, {
    getAllUsers,
    adminLogin,
    getSubInfo,
    createAnnounce,
  })(withStyles(styles)(Admin))
);
