import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import helpGuide from './helpGuide.png'


const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button:{
    display:'inline',
    // position:'absolute',
    // right:0,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding:"2px",
    margin:"3px",
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    "&:disabled":{
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
  },
  closeButton: {
  position: 'absolute',
  right: theme.spacing.unit,
  top: theme.spacing.unit,
  color: theme.palette.grey[500],
}
});


class HelpModal extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    let modalContent;
    let onClose=this.handleClose;
    if (this.state.open){
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={this.state.step === 4?'md':'sm'}
          fullWidth={true}
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Authentication Guide</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <DialogContent>
        <img src={helpGuide} alt="Help Guide for MWS" />
        </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = (
        <span></span>
      );
    }
    return (
      <span>
      <Button variant="contained" color="primary"
      className={classes.button} onClick={this.handleClickOpen}>Need Help?</Button>
      {modalContent}
      </span>
    );
  }
}

HelpModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HelpModal);
