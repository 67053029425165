import {
  LOADING_PAGE,
  GET_REPORT,
  STOP_LOADING_PAGE
} from '../actions/types';

const initialState = {
  reqReport: {},
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case STOP_LOADING_PAGE:
      return {
        ...state,
        loading: false,
      }
    case LOADING_PAGE:
      return {
        ...state,
        loading: true
      }
    case GET_REPORT:
      return {
        ...state,
        loading: false,
        reqReport: action.payload
      }
    default:
      return state;
  }
}
