import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// MaterialUI
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Switch,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';

// DropzoneArea
import { DropzoneArea } from 'material-ui-dropzone';

// Actions
import {
  getUngatingType,
  updateUngatingType,
  createUngatingType,
  getUngatingTypes,
} from '../../../actions/ungatingActions';

// Components
import UngatingStepEditor from './UngatingStepEditor';

// Utils
import {
  upload,
  cleanCloudTempFolder,
} from '../../../utils/fileUpload';

// Validations
import isEmpty from '../../../validation/is-empty';

// Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styles
import './UngatingEditor.scss';

const styles = (theme) => ({
  root: {
    fontFamily: "Quicksand,serif",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    border: '0px',
    textTransform: "none",
    fontWeight: "normal",
    fontSize: '18px',
    lineHeight: '20px',
    backgroundColor: '#149cea',
    outline: 'none !important',
    "&:hover": {
      border: '0px',
      backgroundColor: "#132f4a"
    },
    "&:disabled": {
      border: '0px',
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
  },
  formControlLabel: {
    marginLeft: '0px',
    marginRight: '0px',
  },
  dropzoneClass: {
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formField: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  requiredFields: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  textAreaFormField: {
    width: '100%',
  },
});
const baseSteps = [
  { title: "", content: null },
  { title: "", content: null },
  { title: "", content: null },
  { title: "", content: null },
  { title: "", content: null },
];
class UngatingEditor extends PureComponent {

  // Refs
  ungatingStepEditorRefs = [];
  firstAvatarRender = true;

  constructor(props) {
    super(props);
    this.state = {
      edition: true,
      saving: false,
      saveDisabled: false,
      ungating: {
        _id: null,
        avatar: null,
        name: "",
        visible: true,
        steps: baseSteps,
        benefits: "",
        cost: 0,
      },
      errors: {
        name: {
          error: false,
          messages: [],
        },
        benefits: {
          error: false,
          messages: [],
        },
        cost: {
          error: false,
          messages: [],
        },
      },
    };
    const { match } = props;
    const { id } = match.params;
    const { ungatings } = props.ungating;
    if (ungatings.length === 0) {
      props.getUngatingTypes();
    }
    if (id) {
      let ungatingFromStore = ungatings.find((currentUngating) =>
        currentUngating._id === id
      );
      if (!ungatingFromStore) {
        props.getUngatingType(id);
      } else {
        this.state.ungating = { ...ungatingFromStore };
      }
    } else {
      this.state.edition = false;
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextUngatings = nextProps.ungating;
    const prevUngatings = this.props.ungating;
    if (!nextUngatings.loadingGet && prevUngatings.loadingGet) {
      const { lastUngating } = nextUngatings;
      if (!lastUngating) {
        this.props.history.push("/not-found");
      } else {
        this.setState({
          ungating: { ...lastUngating },
        });
      }
    }
    if (!nextUngatings.loadingUpdate && prevUngatings.loadingUpdate) {
      this.setState({
        saving: false,
      });
      toast.success("Ungating type successfully updated.");
      window.scrollTo({ top: 0, behavior: 'smooth'});
    }
    if (!nextUngatings.loadingCreate && prevUngatings.loadingCreate) {
      this.setState({
        saving: false,
      });
      toast.success("Ungating type successfully created.");
      window.scrollTo({ top: 0, behavior: 'smooth'});
      const lastElement = nextUngatings.ungatings[nextUngatings.ungatings.length - 1];
      if (lastElement) {
        this.props.history.replace(`/admin/ungating/${lastElement._id}/edit`);
        this.setState({
          ungating: lastElement,
          edition: true,
        });
      }
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  componentWillUnmount() {
    cleanCloudTempFolder();
  }

  handleBackNavigation = () => {
    this.props.history.goBack();
  };

  isValidFieldValue = (field, value) => {
    let validField = true;
    switch (field) {
      case "name": {
        if (
          this.isEmptyField(field, value) ||
          this.isAlreadyExistingValue(field, value)
        ) {
          validField = false;
        }
        break;
      }
      case "cost": {
        if (
          this.isEmptyField(field, value) ||
          !this.isPositiveValue(field, value)
        ) {
          validField = false;
        }
        break;
      }
      case "benefits": {
        if (
          this.isEmptyField(field, value)
        ) {
          validField = false;
        }
        break;
      }
      default: {
        break;
      }
    }
    return validField;
  };

  isPositiveValue = (field, value) => {
    if (parseInt(value) <= 0) {
      this.setState(oldState => ({
        errors: {
          ...oldState.errors,
          [field]: {
            error: true,
            messages: [
              ...oldState.errors[field].messages,
              "This value should be positive."
            ],
          },
        },
        saveDisabled: true,
      }));
      return false;
    }
    return true;
  };

  isAlreadyExistingValue = (field, value) => {
    if (!this.isUngatingTypeNameAvailable(value)) {
      this.setState(oldState => ({
        errors: {
          ...oldState.errors,
          [field]: {
            error: true,
            messages: [
              ...oldState.errors[field].messages,
              "Ungating type name already exist."
            ],
          },
        },
        saveDisabled: true,
      }));
      return true;
    }
    return false;
  };

  isEmptyField = (field, value) => {
    if (isEmpty(value)) {
      this.setState(oldState => ({
        errors: {
          ...oldState.errors,
          [field]: {
            error: true,
            messages: [
              ...oldState.errors[field].messages,
              "This field can not be empty."
            ],
          },
        },
        saveDisabled: true,
      }));
      return true;
    }
    return false;
  };

  isUngatingTypeNameAvailable = (name) => {
    const { _id } = this.state.ungating;
    const { ungatings } = this.props.ungating;
    const result = ungatings.find((ungating) =>
      _id ?
        ungating.name === name && ungating._id !== _id :
        ungating.name === name
    );
    return !result;
  };

  isFormValid = () => {
    const { name, cost, benefits } = this.state.ungating;
    const isNameValid = this.isValidFieldValue("name", name);
    const isCostValid = this.isValidFieldValue("cost", cost);
    const isBenefitsValid = this.isValidFieldValue("benefits", benefits);
    return isNameValid && isCostValid && isBenefitsValid;
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid()) {
      this.setState({
        saving: true,
      });
      const { ungating } = this.state;
      this.ungatingStepEditorRefs.forEach((ref, index) => {
        if (ref) {
          const dataToStore = ref.getDataToStore();
          ungating.steps[index] = { ...dataToStore };
        }
      });
      ungating._id ?
        this.props.updateUngatingType(ungating._id, ungating) :
        this.props.createUngatingType(ungating);
    }
  };

  handleFileChange = (file) => {
    if (this.firstAvatarRender) {
      this.firstAvatarRender = false;
    } else {
      const avatar = file.length === 1 ? file[0] : null;
      if (avatar) {
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        const data = new FormData();
        data.append('file', avatar);
        upload(data, headers)
          .then(res => {
            const { success } = res.data;
            if (success) {
              const { result } = res.data;
              this.setAvatarIntoState(result.secure_url);
            } else {
              const { error } = res.data;
              console.log("Avatar upload error", error);
              this.setAvatarIntoState(null);
            }
          })
          .catch(err => {
            console.log("Avatar upload error", err);
            this.setAvatarIntoState(null);
          });
      } else {
        this.setAvatarIntoState(null);
      }
    }
  };

  setAvatarIntoState = (avatar) => {
    this.setState({
      ungating: {
        ...this.state.ungating,
        avatar,
      },
    });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    this.setState({
      ungating: {
        ...this.state.ungating,
        [name]: value,
      },
    });
    this.clearFieldErrors(name);
    this.isValidFieldValue(name, value);
  };

  clearFieldErrors = (field) => {
    const errors = {
      ...this.state.errors,
      [field]: {
        error: false,
        messages: [],
      },
    };
    this.setState({
      errors,
      saveDisabled: false,
    });
  };

  handleSwitchChange = (event) => {
    const { target } = event;
    const { checked, name } = target;
    this.setState({
      ungating: {
        ...this.state.ungating,
        [name]: checked,
      },
    });
  };

  render() {
    const { classes } = this.props;
    const {
      edition,
      ungating,
      saving,
      errors,
      saveDisabled,
    } = this.state;

    return (
      <>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Typography className={classes.title}
                  variant="h6"
                  id="tableTitle"
                >
                  {edition ? 'Edit Ungating Type' : 'Create Ungating Type'}
                  <Button variant="contained"
                    color="primary"
                    className={classes.button}
                    aria-label="Back to Admin"
                    onClick={this.handleBackNavigation}
                  >
                    Back to Admin
                  </Button>
                </Typography>
                <form onSubmit={this.handleOnSubmit}
                  className={classes.root}
                >
                  <div className={`dropzone-container${ungating.avatar ? ' with-image' : ''}`}>
                    <DropzoneArea
                      key={ungating._id}
                      Icon={AddIcon}
                      dropzoneText="Avatar"
                      onChange={this.handleFileChange}
                      filesLimit={1}
                      acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
                      initialFiles={edition && ungating.avatar ? [ungating.avatar] : []}
                      dropzoneClass={classes.dropzoneClass}
                      previewGridClasses={{
                        container: "PreviewGridClassContainer",
                        item: "PreviewGridClassItem",
                      }}
                    />
                  </div>
                  <div className={classes.formField}>
                    <TextField
                      id="name"
                      label="Name *"
                      error={errors.name.error}
                      helperText={errors.name.messages}
                      value={ungating.name}
                      disabled={edition}
                      name="name"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className={classes.formField}>
                    <FormControlLabel
                      id="visible"
                      name="visible"
                      className={classes.formControlLabel}
                      control={
                        <Switch size="small"
                          color="primary"
                          checked={ungating.visible}
                          name="visible"
                          onChange={this.handleSwitchChange}
                        />
                      }
                      label="Visible"
                      labelPlacement="start"
                    />
                  </div>
                  <div className={classes.formField}>
                    <TextField
                      id="cost"
                      label="Cost *"
                      error={errors.cost.error}
                      helperText={errors.cost.messages}
                      value={ungating.cost}
                      name="cost"
                      onChange={this.handleInputChange}
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.formField}>
                    <TextField
                      className={classes.textAreaFormField}
                      id="benefits"
                      label="Benefits *"
                      error={errors.benefits.error}
                      helperText={errors.benefits.error ? errors.benefits.messages : "One benefit per line."}
                      value={ungating.benefits}
                      name="benefits"
                      onChange={this.handleInputChange}
                      multiline
                      rows={10}
                      variant="outlined"
                    />
                  </div>
                  {
                    ungating.steps.map((step, index) =>
                      <UngatingStepEditor key={`${ungating._id}_${index}`}
                        editorId={`UngatingStepEditor_${index}`}
                        stepNumber={index + 1}
                        values={{ title: step.title, content: step.content }}
                        innerRef={r => this.ungatingStepEditorRefs[index] = r}
                      />
                    )
                  }
                  <div>
                    <Button type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={saving || saveDisabled}
                    >
                      Save
                    </Button>
                  </div>
                  <div className={classes.requiredFields}>(*) Required fields</div>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ungating: state.ungating,
});

export default withRouter(connect(mapStateToProps, {
  getUngatingType,
  updateUngatingType,
  createUngatingType,
  getUngatingTypes,
})(withStyles(styles)(UngatingEditor)));
