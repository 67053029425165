import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from '../common/Spinner';
import { getShipments} from '../../actions/shipmentActions';
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, Link,TableHead, TablePagination, TextField,TableRow,TableSortLabel,Toolbar,Typography,Paper,Checkbox,Button,IconButton,Tooltip} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import  {FormDialog}  from './Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as LinkIn } from 'react-router-dom';
import { getInventory } from '../../actions/inventoryActions';

let counter = 0;
function createData(number,status, name, shipmentId,skuNumb,shipped,received, fc,address,skus,prep,cases,fees,shippingInfo) {
  counter += 1;
  return { id: counter,number, status, name, shipmentId,skuNumb,shipped,received, fc,address,skus,prep,cases,fees,shippingInfo };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'number',  disablePadding: false, label: 'Created Order' },
  { id: 'name',  disablePadding: false, label: 'Name' },
  { id: 'shipmentId',  disablePadding: false, label: 'Shipment ID' },
  { id: 'skuNumb', disablePadding: false, label: 'SKUs' },
  { id: 'shipped', disablePadding: false, label: 'Shipped' },
  { id: 'received', disablePadding: false, label: 'Received' },
  { id: 'fees', disablePadding: false, label: 'Inbound Cost' },
  { id: 'fc', disablePadding: false, label: 'Destination' },
  { id: 'status',  disablePadding: false, label: 'Status' }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
        { /*<TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>*/}
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={'center'}
                padding={'checkbox'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={(row.id==='fc' || row.id==='number' ||row.id==='fees'||row.id==='shipped'||row.id==='received'|| row.id==='status') ? ("Sort by "+row.label): row.label}
                  placement={'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    hideSortIcon = {row.id==='name' ? true : false}
                    active={orderBy === row.id}
                    direction={order}
                    onClick={(row.id==='fc' || row.id==='number' ||row.id==='fees' ||row.id==='shipped'||row.id==='received'|| row.id==='status') ? this.createSortHandler(row.id) : null}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
    aligh: "center"
  },
  search:{
    display:'inline',
    marginLeft: 'auto',
    marginRight: '20px',
  },
  button: {
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
  }
});

let EnhancedTableToolbar = props => {

  const { numSelected, classes, value, handleSearch, rowCount,history} = props;
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.actions}>
        {numSelected > 0
          ? <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          : null
            }
      </div>
      <Typography className={classes.title} variant="h6" id="tableTitle">
            Shipments ({rowCount})
          </Typography>

          <Button
          style={{marginLeft:'15px',textTransform:'none'}}
          variant="contained" color="primary"
          className={classes.button}
          onClick={()=>{
            toast.info("Select products you want to ship and click 'Create Shipment'", {
              position: toast.POSITION.TOP_CENTER
            });
            history.push({pathname: '/inventory'})
          }}>
          Create a New Shipment
          </Button>

      <div align="right" className={classes.search}>
          <TextField  placeholder="Search" onChange={handleSearch} value={value}/>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit *2,
    marginBottom: theme.spacing.unit *2,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  date:{
    width: "90%",
    maxWidth: "75px",
    whiteSpace: "wrap"
  },
  tableRow:{
    minHeight: "100px",
    height:"100px",
    maxHeight:"100px"
  },
  cell: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "200px",
      overflow: "hidden",
      padding: "7px",
      minHeight: "100px",
      height:"100px",
      maxHeight:"100px"
    },
    table: {
      tableLayout: 'auto'
    },
    textField: {
      margin:"2px",
      width: "75%",
    },
    lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 13,
  },
  link:{
    color:"blue",
    textDecoration: "underline"
  },
  button: {
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
  }
});

class Shipments extends React.Component {
    state = {
      order: 'desc',
      orderBy: 'number',
      selected: [],
      shipments: [],
      filteredShipments: [],
      page: 0,
      rowsPerPage: 50,
      query: 'none',
    };

    handleRequestSort = (event, property) => {
      const orderBy = property;
      let order = 'desc';

      if (this.state.orderBy === property && this.state.order === 'desc') {
        order = 'asc';
      }

      this.setState({
        order,
        orderBy
      });
    };

    handleSelectAllClick = event => {
      if (event.target.checked) {
        this.setState(state => ({
          selected: state.shipments.map(n => n.id)
        }));
        return;
      }
      this.setState({
        selected: []
      });
    };

    handleKeyDown = (event, id) => {

      this.handleClick(event, id);

    };

    handleClick = (event, id) => {
      const {
        selected
      } = this.state;
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      this.setState({
        selected: newSelected
      });
    };

    handleChangePage = (event, page) => {
      this.setState({
        page
      });
    };

    handleChangeRowsPerPage = event => {
      this.setState({
        rowsPerPage: event.target.value
      });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleSearch = event => {
      let input, filter, a, i, txtValue, filteredData;
      input = event.target.value;
      filter = event.target.value.toUpperCase();
      filteredData = [];
      const {
        shipments
      } = this.state;
      for (i = 0; i < shipments.length; i++) {
        a = shipments[i];
        txtValue = JSON.stringify(a);
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          filteredData.push(shipments[i])
        }
      }
      this.setState({
        filteredShipments: filteredData,
        query: input
      })
    }

    getShipments = (ships) => {
      this.props.getShipments(ships);
    }

    componentDidMount = () => {
      if (this.props.auth && this.props.auth.user && this.props.auth.user.sellerID && this.props.auth.user.sellerID !== null) {
        this.getShipments(this.props.shipments.shipments);
        this.props.getInventory(this.props.inventory.inventory);
        this.sortAndSetShipments();
      }
    }

    editShipment = (n) => {
      for (let i = 0; i < n.skus.length; i++) {
        n.skus[i].quantity = n.skus[i].shipped;
        n.skus[i].title = 'NA';
        for (let j = 0; j < this.props.inventory.inventory.length; j++) {
          if (this.props.inventory.inventory[j].sku === n.skus[i].sku) {
            n.skus[i].title = this.props.inventory.inventory[j].title;
          }
        }

      }
      this.props.history.push({
        pathname: '/shipments/edit',
        state: {
          prevPgCreate: false,
          shipmentPlans: [{
            shipmentId:n.shipmentId,
            shipment_name: n.name,
            sp: (n.shippingInfo.tType && n.shippingInfo.tType.includes("LTL")) ? false : true,
            partnered: (n.shippingInfo.tType && n.shippingInfo.tType.includes("Partnered")) ? true : false,
            estimatedCost: n.shippingInfo.tcost,
            skus: n.skus
          }],
          skuList: n.skus,
          address: n.address,
          casePacked: false,
          selfPrep: true
        }
      })

    }

    sortAndSetShipments = () => {
      if (this.props.shipments && this.props.shipments.shipments.length > 0) {
        let inv = [];
        for (let i = 0; i < this.props.shipments.shipments.length; i++) {
          let skuNumb = 0; let shipped = 0; let received = 0; let fees = 0;
          let tempinv = this.props.shipments.shipments;
          if (tempinv[i].fees && tempinv[i].fees.total) fees += tempinv[i].fees.total;
          if (tempinv[i].shippingInfo && tempinv[i].shippingInfo.tcost) fees += tempinv[i].shippingInfo.tcost;
          if (tempinv[i].skus){
             skuNumb = tempinv[i].skus.length;
             for (let j = 0; j < skuNumb; j++) {
               if (tempinv[i].skus[j].shipped) shipped += tempinv[i].skus[j].shipped;
               if (tempinv[i].skus[j].received) received += tempinv[i].skus[j].received;
             }
           }
          inv.push(createData(i,tempinv[i].status, tempinv[i].name, tempinv[i].shipmentId, skuNumb,shipped,received,tempinv[i].fc,tempinv[i].address,tempinv[i].skus,tempinv[i].prep,tempinv[i].cases,fees,tempinv[i].shippingInfo));
        }
        this.setState({
          shipments: inv,
          filteredShipments: inv
        });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.errors !== this.props.errors) {
        if (this.props.errors.bulkbuy) {
          toast.error(this.props.errors.bulkbuy);
        }
        this.setState({
          errors: this.props.errors
        });
      }
      if (prevProps.shipments !== this.props.shipments) {
        this.sortAndSetShipments();
      }
      if (prevProps.inventory !== this.props.inventory) {
        this.setState({
          inventory: this.props.inventory.inventory,
        });
      }
    }

  render() {
    const { classes } = this.props;
    const {user} = this.props.auth;
    const { shipments, filteredShipments, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, shipments.length - page * rowsPerPage);
    //const errors = this.props.errors;
    const { loading } = this.props.shipments;
    let shipmentsContent;
    if (shipments === [] || loading || this.props.shipments.shipments === []) {
      shipmentsContent = (
        <Paper className={classes.root}>
        Looking for Shipments...<Spinner />
        </Paper>
      );
    } else if(!user.sellerID){
      shipmentsContent = (
      <Paper className={classes.root}>
        To view your shipments, you need to authenticate with Amazon in &nbsp;
        <LinkIn className={classes.link} to="/settings">
        Settings
        </LinkIn>
      </Paper>
    );
  } else {
      shipmentsContent = (
      <Paper className={classes.root}>
      <EnhancedTableToolbar postBulkBuy={this.props.postBulkBuy} numSelected={selected.length} handleSearch={this.handleSearch}
          value={this.searchValue}
          rowCount={filteredShipments.length}
          history={this.props.history} />

        <div className={classes.tableWrapper} style={{ height: 'calc(60vh)' }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle">
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={this.handleSelectAllClick}
          onRequestSort={this.handleRequestSort}
          rowCount={filteredShipments.length}
          className={classes.cell}
        />
            <TableBody>
              {stableSort(this.state.filteredShipments, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id+n.shipmentId}
                      selected={isSelected}
                    >
                    { /* <TableCell padding="checkbox">
                        <Checkbox onClick={event => this.handleClick(event, n.id)} checked={isSelected} />
                      </TableCell> */}
                      <TableCell className={classes.cell} style={{width:'10px'}} padding="none" align="center">#{n.number+1}</TableCell>
                      <TableCell className={classes.cell} padding="default" align="center">
                      <FormDialog
                        shipment={n}
                        inventory={this.props.inventory}
                      />
                      </TableCell>
                      <TableCell className={classes.cell} padding="none" align="center">
                      <Link target="_blank"
                      className={classes.link}
                      rel="noreferrer"
                      href={'https://sellercentral.amazon.com/gp/fba/inbound-shipment-workflow/index.html#'+n.shipmentId+'/summary'}>
                        {n.shipmentId}
                      </Link>

                      </TableCell>
                      <TableCell className={classes.cell} padding="none" align="center">{n.skuNumb}</TableCell>
                      <TableCell className={classes.cell} padding="none" align="center">{n.shipped}</TableCell>
                      <TableCell className={classes.cell} padding="none" align="center">{n.received}</TableCell>
                      <TableCell className={classes.cell} padding="none" align="center">${n.fees.toFixed(2)}</TableCell>
                      <TableCell className={classes.cell} padding="none" align="center">{n.fc}</TableCell>
                      <TableCell className={classes.cell} padding="none" align="center">
                        <Button
                        variant="contained" color="primary"
                        className={classes.button}
                        onClick={()=>this.editShipment(n)}>
                        {n.status}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={shipments.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
  return (
    <div>
      {shipmentsContent}
    </div>
  );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  shipments: state.shipments,
  inventory: state.inventory,
});

Shipments.propTypes = {
  classes: PropTypes.object.isRequired,
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  shipments: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, { getShipments,getInventory })(withStyles(styles)(Shipments)))
