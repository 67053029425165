import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';

import { Provider } from 'react-redux';
import store from './store';

import PrivateRoute from './components/common/PrivateRoute';

import Loading from './components/loading';
import Sidebar from './components/layout/Sidebar';
import Landing from './components/layout/Landing';
import CheckoutUngating from './components/checkout/CheckoutUngating';
import Checkout from './components/checkout/Checkout';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ConfirmEmail from './components/auth/ConfirmEmail';
import Login from './components/auth/Login';
import Dash from './components/dash/Dash';
import NotFound from './components/not-found/NotFound';
import Settings from './components/settings/Settings';
import Firsttime from './components/settings/Firsttime';
import Admin from './components/admin/Admin';
import UngatingEditor from './components/admin/ungating/UngatingEditor';
import Oaleads from './components/oaleads/Oaleads';
import Ungating from './components/ungating/Ungating';
import UngatingPackages from './components/ungating/packages/UngatingPackages';
// import Oacourse from './components/oacourse/Oacourse';
import Inventory from './components/inventory/Inventory';
import Orders from './components/orders/Orders';
import Shipments from './components/shipments/Shipments';
import NewShipment from './components/shipments/NewShipment';
import ShippingInfo from './components/shipments/ShippingInfo';
import Reports from './components/reports/Reports';
import Upgrade from './components/common/Upgrade';
import Affiliate from './components/affiliate/Affiliate';
import ReactGA from 'react-ga';
import ErrorHandler, { shouldIgnoreRedirect } from './ErrorHandler';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Quicksand,serif",
  },
})


ReactGA.initialize('UA-22233060-11');

//forward traffic from beta to app
var full = window.location.host
//window.location.host is subdomain.domain.com
var parts = full.split('.')
var sub = parts[0]
if (sub === 'beta') {
  window.location = 'https://app.go-atlas.io'
}

// Check for token
let user;
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    if (!shouldIgnoreRedirect()) {
      window.location.href = '/login';
    }
  }
  user = decoded
}

class App extends Component {
  render() {
    let routes = (
      <div className="container-fluid">
        <Switch>
          <PrivateRoute exact path="/affiliate" component={Affiliate} />
          <PrivateRoute exact path="/firsttime" component={Firsttime} />
          <PrivateRoute exact path="/admin" component={Admin} />
          <PrivateRoute exact path="/admin/ungating/new" component={UngatingEditor} />
          <PrivateRoute exact path="/admin/ungating/:id/edit" component={UngatingEditor} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/dashboard" component={Dash} />
          <PrivateRoute exact path="/oaleads" component={Oaleads} />
          <PrivateRoute exact path="/ungating" component={Ungating} />
          {/* <PrivateRoute exact path="/oacourse" component={Oacourse} /> */}
          <PrivateRoute exact path="/inventory" component={Inventory} />
          <PrivateRoute exact path="/orders" component={Orders} />
          <PrivateRoute exact path="/shipments" component={Shipments} />
          <PrivateRoute exact path="/shipments/new/:page" component={NewShipment} />
          <PrivateRoute exact path="/shipments/edit" component={ShippingInfo} />
          <PrivateRoute exact path="/reports" component={Reports} />
        </Switch>
        <Route exact path="/not-found" component={NotFound} />
      </div>
    )
    if (user && user.tier < 1) {
      routes = (
        <div className="container-fluid">
          <Switch>
            <PrivateRoute exact path="/firsttime" component={Firsttime} />
            <PrivateRoute exact path="/admin" component={Upgrade} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <PrivateRoute exact path="/dashboard" component={Upgrade} />
            <PrivateRoute exact path="/oaleads" component={Oaleads} />
            <PrivateRoute exact path="/ungating" component={Ungating} />
            <PrivateRoute exact path="/affiliate" component={Affiliate} />
            {/* <PrivateRoute exact path="/oacourse" component={Oacourse} /> */}
            <PrivateRoute exact path="/inventory" component={Upgrade} />
            <PrivateRoute exact path="/orders" component={Upgrade} />
            <PrivateRoute exact path="/shipments" component={Upgrade} />
            <PrivateRoute exact path="/shipments/new/:page" component={Upgrade} />
            <PrivateRoute exact path="/shipments/edit" component={Upgrade} />
            <PrivateRoute exact path="/reports" component={Upgrade} />
          </Switch>
          <Route exact path="/not-found" component={NotFound} />
        </div>
      )
    }
    if (user && user.email === 'upload.leads@go-atlas.io') {
      routes = (
        <Switch>
          <PrivateRoute exact path="/oaleads" component={Oaleads} />
        </Switch>
      )
    }
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <ErrorHandler />
            <MuiThemeProvider theme={theme}>
              <Sidebar routes={routes} />
              <Route exact path={['/confirm', '/validate']} component={ConfirmEmail} />
              <Route exact path="/signup" component={Checkout} />
              <Route exact path="/ungatingsignup" component={CheckoutUngating} />
              <Route exact path="/ungatingpackages" component={UngatingPackages} />
              <Route exact path="/loading" component={Loading} />
              <Route exact path="/" component={Landing} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/forgotpassword/:token" render={(routerProps) => {
                return <ForgotPassword {...routerProps} />
              }
              } />
              <Route path="/" render={({ location }) => {
                if (typeof window.ga === 'function') {
                  window.ga('set', 'page', location.pathname + location.search);
                  window.ga('send', 'pageview');
                }
                return null;
              }} />
            </MuiThemeProvider>
          </div>
        </Router>
      </Provider>

    );
  }
}

export default App;
