import isEmpty from '../validation/is-empty';

import {
  SET_CURRENT_USER,
  GET_RESPONSE,
  SET_NEW_PASSWORD,
  GET_ALL_USERS,
  PAYMENT_MADE,
  PURCHASE_PENDING,
  CANCEL_SUBSCRIPTION,
  GET_SUB_INFO,
  LOADING_PAGE,
  STOP_LOADING_PAGE,
  LOADING_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS,
  PAYMENT_ERROR,
  PAYMENT_PENDING,
  EMAIL_RESPONSE,
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  pending: false,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case STOP_LOADING_PAGE:
      return {
        ...state,
        loading: false,
        pending: false
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case GET_RESPONSE:
      return {
        ...state,
        data: action.payload,
        pending: false,
        loading: false,
      }
    case EMAIL_RESPONSE:
      return {
        ...state,
        email: action.payload,
        pending: false,
        loading: false,
      }
    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPass: action.payload
      }
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload
      }
    case GET_SUB_INFO:
      return {
        ...state,
        subInfo: action.payload,
        pending: false
      }
    case PURCHASE_PENDING:
      return {
        ...state,
        pending: true
      }
    case PAYMENT_PENDING: {
      return {
        ...state,
        paymentErrors: {},
        paymentRes: undefined,
        pending: true,
      };
    }
    case PAYMENT_MADE:
      return {
        ...state,
        paymentRes: action.payload,
        pending: false,
        paymentErrors: {},
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        paymentRes: { success: action.payload.success },
        pending: false,
        paymentErrors: { ...action.payload.errors },
      };
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        data: action.payload,
        pending: false
      }
    case LOADING_PAGE:
      return {
        ...state,
        pending: true,
        loading:true
      }
    case LOADING_SUBSCRIPTIONS:
      return {
        ...state,
        loadingSubs: true,
      }
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        loadingSubs: false,
        subs: action.payload,
      }
    default:
      return state;
  }
}
