import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {Table, Typography,TableBody, TableRow ,TableCell} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CSVReader from 'react-csv-reader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = theme => ({
  root: {
    fontFamily:"Quicksand,serif",
    //borderBottom: `1px solid ${theme.palette.divider}`,
    //margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button: {
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
    textTransform: 'none',
    margin: '10px',
    display: "inline",
    whiteSpace: "wrap",
    overflow: 'hidden'
    //width: "130px"
  },
  input: {
    display: 'none',
  },
  cell: {
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    maxHeight: "10px",
    width: "200px",
    overflow: "hidden",
    padding: "15px",
    height: "10px"
  },
  closeButton: {
  position: 'absolute',
  right: theme.spacing.unit,
  top: theme.spacing.unit,
  color: theme.palette.grey[500],
},
  inputs:{
    float: 'left',
    margin: '10px',
  },
  selectors:{
    float: 'right',
    margin: '10px',
    marginRight: 'auto',
  },
  container:{
    width:'200px',
  },
  table: {
    tableLayout: 'auto',
    minWidth: 300
  },
});

class BuyCosts extends React.Component {
  state = {
    open: false,
    headerinfo: ['ASIN','SKU','By Price'],
    csv:'',
    pickedAsin:'',
    pickedSKU:'',
    pickedBuyLink:'',
    pickedBuyPrice:'',
    pickedSupplier:'',
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleInv = data => {
    this.setState({headerinfo:data[0],csv:data})
    //console.log(data);
  }

  handleErrors = data => {
    console.log('error!!',data);
  }

  handleChange = (e) =>{
    let selectedIndex = e.target.options.selectedIndex;
    let index = e.target.options[selectedIndex].getAttribute('data-key');
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        index
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let invInfo = {
      asin:this.state.pickedAsin,
      sku:this.state.pickedSKU,
      buyPrice:this.state.pickedBuyPrice,
      supplier:this.state.pickedSupplier,
      buyLink:this.state.pickedBuyLink,
      csv:this.state.csv
    }

    if ((invInfo.sku.index === 'none' || invInfo.sku === '') && (invInfo.asin.index === 'none'  || invInfo.asin === '')) {
        return toast.error("At least ASIN or SKU required");
    }
    this.props.postBulkBuy(invInfo);
    this.setState({ csv: '' });
    this.handleClose();

  }

  render() {
    const { classes } = this.props;
    const { headerinfo,csv } = this.state;
    let modalContent;
    let onClose=this.handleClose;

    let mapping = headerinfo.map((item, key) =>
    <option key={key} data-key={key} value={item}>{item}</option>
    );
    let mappingContent = (
        <div>
        <br/>
        Then match your CSV upload headers to the corresponding field.
        <div className={classes.container}>
          <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>
                ASIN
              </TableCell>
              <TableCell>
              <select name={'pickedAsin'} disabled={csv === ''} className="leadssel" value={this.state.pickedAsin.value} onChange={this.handleChange}>
               <option data-key={'none'} key={'none'} value='none'>Not Given</option>
               {mapping}
               </select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                SKU:
              </TableCell>
              <TableCell>
              <select name={'pickedSKU'} disabled={csv === ''} className="leadssel" value={this.state.pickedSKU.value} onChange={this.handleChange}>
               <option data-key={'none'} key={'none'} value='none'>Not Given</option>
               {mapping}
               </select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Buy Price
              </TableCell>
              <TableCell>
              <select name={'pickedBuyPrice'} disabled={csv === ''} className="leadssel" value={this.state.pickedBuyPrice.value} onChange={this.handleChange}>
               <option data-key={'none'} key={'none'} value='none'>Not Given</option>
               {mapping}
               </select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Supplier
              </TableCell>
              <TableCell>
              <select name={'pickedSupplier'} disabled={csv === ''} className="leadssel" value={this.state.pickedSupplier.value} onChange={this.handleChange}>
               <option data-key={'none'} key={'none'} value='none'>Not Given</option>
             {mapping}
             </select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Buy Link
              </TableCell>
              <TableCell>
              <select name={'pickedBuyLink'} disabled={csv === ''} className="leadssel" value={this.state.pickedBuyLink.value} onChange={this.handleChange}>
               <option data-key={'none'} key={'none'} value='none'>Not Given</option>
               {mapping}
               </select>
              </TableCell>
            </TableRow>
            </TableBody>
          </Table>
          <br/>
         <div style={{width:"100%",margin:'0 auto'}}>
         <button id="postinv" onClick={this.handleSubmit} disabled={csv === ''} >
             Confirm and Submit
         </button>
         </div>
         </div>
         </div>
      );

    if (this.state.open){
      modalContent = (

        <Dialog
          width={'400px'}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Inventory Bulk Upload</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>

          <DialogContent>
          <br/>
        <h6>Select CSV with inventory information.</h6>
        At least <strong>SKU</strong> or <strong>ASIN</strong> is required.<br/>
        <strong>Buy Link</strong> is a weblink to the location to purchase (great for quick reorders)<br/>
          <br/>
          <CSVReader
            onFileLoaded={this.handleInv}
            onError={this.handleErrors}
            inputId="inventoryinfo"
            inputStyle={{color: 'green'}}
          />
          {mappingContent}
          </DialogContent>

        </Dialog>
      );
    } else {
      modalContent = (
        <div></div>
      );
    }
    return (
      <div>
        <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClickOpen}>
            Bulk Upload Inventory (Buy Costs, etc)
        </Button>
        {modalContent}
      </div>
    );
  }
}

BuyCosts.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(BuyCosts);
