import axios from "axios";

import {
  GET_USER_LEADS,
  LEADS_LOADING,
  GET_ERRORS,
  CLEAR_ERRORS
} from "./types";

// Get the leads for the user
export const getLeads = (postData, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLeadsLoading());
  axios
    .post("/api/leads/download", postData)
    .then(res =>
      dispatch({
        type: GET_USER_LEADS,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      dispatch({
        type: GET_USER_LEADS,
        payload: []
      });
    });
};

export const uploadLeads = (postData, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLeadsLoading());
  axios
    .post("/api/leads/upload", postData)
    .then(res =>
      dispatch({
        type: GET_USER_LEADS,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      dispatch({
        type: GET_USER_LEADS,
        payload: []
      });
    });
};

// Send Feedback for a lead
export const sendFeedback = postData => dispatch => {
  axios.post("/api/leads/sendFeedback", postData).catch(err => {});
};

export const setLeadsLoading = () => {
  return {
    type: LEADS_LOADING
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
