import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

import { shouldIgnoreRedirect } from "../ErrorHandler";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  CLEAR_ERRORS,
  GET_RESPONSE,
  GET_UPDATE_INFO,
  GET_ALL_USERS,
  PURCHASE_PENDING,
  PAYMENT_MADE,
  PAYMENT_ERROR,
  PAYMENT_PENDING,
  CANCEL_SUBSCRIPTION,
  GET_SUB_INFO,
  LOADING_PAGE,
  STOP_LOADING_PAGE,
  LOADING_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS,
  EMAIL_RESPONSE,
} from "./types";

// Admin login user
export const adminLogin = (user, history) => (dispatch) => {
  axios
    .post("/api/users/adminlogin", user)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// forgot Password
export const forgotPassword = (data, history) => (dispatch) => {
  axios
    .post("/api/users/forgotpassword", data)
    .then((res) => {
      history.push("/forgotpassword/wait");
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// reset Password
export const resetPassword = (data, history) => (dispatch) => {
  axios
    .post("/api/users/resetpassword", data)
    .then((res) => {
      history.push("/forgotpassword/success");
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// submit Testimonial
export const submitTestimonial = (data) => (dispatch) => {
  axios
    .post("/api/users/submitTestimonial", data)
    .then((res) => {
      toast.success("Thanks for your testimonial!");
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// claim coupon code
export const claimCode = (data) => (dispatch) => {
  dispatch(setLoading());
  axios
    .post("/api/users/claimCode", data)
    .then((res) => {
      dispatch(clearErrors());
      dispatch(
        {
          type: GET_RESPONSE,
          payload: res.data,
        },
        dispatch(stopLoading())
      );
    })
    .catch((err) => {
      if (err.response) {
        dispatch(
          {
            type: GET_ERRORS,
            payload: err.response.data,
          },
          dispatch(stopLoading())
        );
      }
    });
};

// Ungating Service
export const ungatingService = (data) => (dispatch) => {
  dispatch(setLoading());
  axios
    .post("/api/users/ungatingService", data)
    .then((res) => {
      dispatch(clearErrors());
      dispatch(
        {
          type: GET_RESPONSE,
          payload: res.data,
        },
        dispatch(stopLoading())
      );
    })
    .catch((err) => {
      if (err.response) {
        dispatch(
          {
            type: GET_ERRORS,
            payload: err.response.data,
          },
          dispatch(stopLoading())
        );
      }
    });
};

// Resend token
export const resendToken = (data, history) => (dispatch) => {
  axios
    .post("/api/users/resendtoken", data)
    .then((res) => {
      toast.success("Email Confirmed! Login Now.");
      history.push("/login");
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Resend confirmation email
export const resendConfirmationEmail = (data) => (dispatch) => {
  axios
    .post("/api/users/resendconfirmation", data)
    .then((res) => {
      toast.success("Email sent!");
      dispatch({
        type: EMAIL_RESPONSE,
        payload: {
          ...res.data,
          sent: true,
        },
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Confirm User
export const confirmUser = (token) => (dispatch) => {
  axios
    .get("/api/users/confirm/" + token)
    .then((res) => {
      toast.success("Email Confirmed!");
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      //set user email in freshchat
      if (typeof window.fcWidget !== "undefined") {
        window.fcWidget.user.setEmail(decoded.email);
        window.fcWidget.user.setFirstName(decoded.name);
        console.log(decoded.name);
      }
      // Set current user in suite
      dispatch(setCurrentUser(decoded));
      dispatch({
        type: EMAIL_RESPONSE,
        payload: res.data,
      });
      // window.location = '/oaleads';
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};
// Set New email
export const setNewEmail = (newEmail, history) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .post("/api/users/setnewemail", newEmail)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      toast.success("Email Changed!");
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};
// Set New Password
export const setNewPassword = (newPassword, history) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .post("/api/users/setnewpassword", newPassword)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      toast.success("Password Changed!");
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};
// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Create Lead
export const createLead = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/createLead", userData)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: GET_RESPONSE,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      //set user email in freshchat
      if (typeof window.fcWidget !== "undefined") {
        window.fcWidget.user.setEmail(decoded.email);
        window.fcWidget.user.setFirstName(decoded.name);
        console.log(decoded.name);
      }
      // Set current user in suite
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = (history) => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  if (!shouldIgnoreRedirect()) {
    window.location.href = "/login";
  }
};

export const authToAmz = (amzAuthData, history) => (dispatch) => {
  axios
    .post("/api/users/amzauth", amzAuthData)
    .then((res) => {
      dispatch({
        type: GET_RESPONSE,
        payload: res.data,
      });
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      window.location.href = "/firsttime";
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const getUpdatedInfo = () => (dispatch) => {
  dispatch(clearErrors());
  axios
    .get("/api/users/getUpdatedInfo")
    .then((res) =>
      dispatch({
        type: GET_UPDATE_INFO,
        payload: res.data,
      })
    )
    .catch((err) => {
      if (err.response)
        for (const [key, value] of Object.entries(err.response.data)) {
          toast.error(value);
        }

      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getSubInfo = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setPurchasePending());
  axios
    .get("/api/users/getsubinfo")
    .then((res) => {
      dispatch({
        type: GET_SUB_INFO,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const getAllUsers = () => (dispatch) => {
  dispatch(clearErrors());
  axios
    .get("/api/users/getAllUsers")
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const submitPayment = (data) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setPurchasePending());
  axios
    .post("/api/users/submitPayment", data)
    .then((res) => {
      console.log("got it!");
      dispatch(clearErrors());
      toast.success(
        (data.flavor || data.categoryOrBrand.name) + " purchased! Enjoy :)"
      );
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Decode token to get user data
      dispatch(setCurrentUser(decoded));
      dispatch({
        type: PAYMENT_MADE,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: PAYMENT_MADE,
          payload: null,
        });
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const submitNewPayment = (data, history) => (dispatch) => {
  dispatch(setPurchasePending());
  axios
    .post("/api/users/submitNewPayment", data)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      //set user email in freshchat
      if (typeof window.fcWidget !== "undefined") {
        window.fcWidget.user.setEmail(decoded.email);
        window.fcWidget.user.setFirstName(decoded.name);
      }
      // Set current user in suite
      dispatch(setCurrentUser(decoded));
      console.log("==SUCCESS==", data);
      if (data.ungating)
        window.top.location.href =
          "https://www.go-atlas.io/atlas-access-order-complete/";
      else
        window.top.location.href =
          "https://www.go-atlas.io/atlas-leads-order-complete/";
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: PAYMENT_MADE,
          payload: null,
        });
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const changePayment = (data) => (dispatch) => {
  dispatch(setPaymentPending());
  axios
    .post("/api/users/changePayment", data)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: PAYMENT_MADE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: PAYMENT_ERROR,
          payload: err.response.data,
        });
      }
    });
};

export const cancelSubscription = (data) => (dispatch) => {
  dispatch(setPurchasePending());
  axios
    .post("/api/users/cancelsubscription", data)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: CANCEL_SUBSCRIPTION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const downgrade = (data) => (dispatch) => {
  dispatch(setPurchasePending());
  axios
    .post("/api/users/cancelsubscription", data)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: CANCEL_SUBSCRIPTION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const fetchInvoices = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get("/api/users/fetchinvoices")
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: GET_RESPONSE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const fetchSubscriptions = () => (dispatch) => {
  dispatch({ type: LOADING_SUBSCRIPTIONS });
  axios
    .get("/api/users/subscriptions")
    .then((res) => {
      dispatch({
        type: GET_SUBSCRIPTIONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SUBSCRIPTIONS,
        payload: [],
      });
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const setPurchasePending = () => {
  return {
    type: PURCHASE_PENDING,
  };
};

export const setPaymentPending = () => {
  return {
    type: PAYMENT_PENDING,
  };
};

export const setLoading = () => {
  return {
    type: LOADING_PAGE,
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING_PAGE,
  };
};
