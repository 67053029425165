import moment from 'moment-timezone';

// export const TIMEZOME = 'America/Los_Angeles'; // PDT
export const TIMEZOME = 'America/New_York'; // EST

export const COUNTDOWN_OPTIONS = {
  hours12: 1,
  hours24: 13,
  ampm: 'pm',
  minutes: 0,
  seconds: 0,
  miliseconds: 0,
};

export const DAYS = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export function getNow() {
  return moment.tz(TIMEZOME);
}

export function getNext(day) {
  const now = getNow();
  const today = now.isoWeekday();

  if (today <= day) { 
    return now.isoWeekday(day);
  } else {
    return now.add(1, 'weeks').isoWeekday(day);
  }
}

export function getNextWeekday() {
  const now = getNow();
  const today = now.isoWeekday();
  let nextWeekday = today;

  if (now.get('hour') >= COUNTDOWN_OPTIONS.hours24) {
    nextWeekday += 1;
  }
  if (nextWeekday === 6 || nextWeekday == 7) {
    nextWeekday = 1;
  }

  if (today <= nextWeekday) { 
    return now.isoWeekday(nextWeekday);
  } else {
    return now.add(1, 'weeks').isoWeekday(nextWeekday);
  }
}

export function isNewSub(date) {
  const subDate = moment(date).tz(TIMEZOME);
  const nextDay = getNextWeekday().endOf('day');
  const diff = nextDay.diff(subDate, 'days', true);
  if (subDate.isoWeekday() >= DAYS.Monday && subDate.isoWeekday() <= DAYS.Friday) {
    return diff < 1;
  }
  return diff < 3;
}