import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TuturialDialog(props) {
  const { classes, onMobile } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        className={classes.navButton}
        style={{ position: "absolute", left: onMobile ? 50 : 5 }}
        onClick={handleClickOpen}
      >
        <i className="far fa-question-circle"></i>
        {onMobile ? null : " How To Use Atlas"}
      </Button>
      {open ? (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ padding: 15 }}>
            {"How to Use Atlas"}
            <IconButton
              style={{
                position: "absolute",
                right: 5,
                top: 5
              }}
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <iframe
              width="100%"
              height="330px"
              src="https://www.youtube.com/embed/ZS2XU1gPl4k?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </DialogContent>
        </Dialog>
      ) : null}
    </Fragment>
  );
}
