import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Typography,Radio,Button,Grid,Paper} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../common/Spinner';
import TextFieldGroup from '../common/TextFieldGroup';
import {toast} from 'react-toastify';
import { cancelSubscription } from '../../actions/authActions';
import { withRouter } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import { submitPayment } from '../../actions/authActions';
import logo from '../common/logo.png';


const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit *2,
    marginBottom: theme.spacing.unit *2,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    //textAlign: 'center',3
    color: theme.palette.text.secondary,
    minHeight: "332px",
    //maxHeight: "332px"
  },
  button: {
    margin: 0,
    width: "130px"
  },
  input: {
    display: 'none',
  },
  table: {
    width: "90%",
    tableLayout: 'auto'
  },
  cell: {
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  link: {
    color: "blue",
    textDecoration: "underline"
  },
});


class FormDialog extends React.Component {
  state = {
    open: true,
    errors: {},
    subscriptions:[],
    radioValue:'',
    auth:'',
    chosen_list: 'Ungated A',
    chosen_price:13900,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      if (this.props.errors.payment){
          this.handleClose();
          toast.error(this.props.errors.payment);
      }
      this.setState({
        errors: this.props.errors
      });
    }
  }

  onToken = (token, addresses) => {
    let data = {
      flavor:this.state.chosen_list,
      token
    }
    this.props.submitPayment(data)
  };

  onTokenSoftware = (token, addresses) => {
    let data = {
      flavor:"Software Suite",
      token
    }
    this.props.submitPayment(data)
  };

  handleListChange = (e) => {
    this.setState({ errors: {} });
    const selectedIndex = e.target.options.selectedIndex;
    const price = e.target.options[selectedIndex].getAttribute('price');
     this.setState({chosen_list: e.target.value,chosen_price:price});
  }


  render() {
    const { classes } = this.props;
    const { user, pending } = this.props.auth;
    const {errors} = this.state;

    let lists = [
      {id:0,name:"Ungated A",price:13900},
      {id:1,name:"Ungated B",price:13900},
      {id:7,name:"Ungated C",price:13900},
      {id:8,name:"Ungated D",price:13900},
      {id:4,name:"Replenishables",price:13900},
      {id:6,name:"Toys",price:13900},

    ];
    this.lists = lists.map((item, key) =>
    <option key={item.id} price={item.price} value={item.name}>{item.name}</option>
    );

    let noSubs = (
      <Paper className={classes.paper}>
      <div style={{fontSize:'17px',color:'black'}}>
        Thanks a ton for trying out Atlas! This page is only for members on a paid plan.<br/> Please choose a plan below. <span role="img" aria-label="emoji">👇👇👇</span> <br/><br/>
        </div>
        <Grid container spacing={1}>

          <Grid item xs={6} sm={6} style={{borderRight: '1px solid grey'}}>
          <Typography variant="h6">Atlas Online Arbitrage Leads Lists</Typography>
          <div style={{fontSize:'14px'}}>
          Get 50+ products delivered to you every week. The ungated lists are perfect for new sellers that are just getting started.
          <br/>For more info <strong><a className={classes.link} target="_blank" href="https://www.go-atlas.io/onlinearbitrageleads/?utm_source=atlas&utm_medium=software&utm_campaign=manage_subscriptions">click here</a></strong>
          </div>
          <br/>
          <div style={{textAlign:'center',fontSize:'14px'}}>
          Choose the list you want:{' '}
            <select className="leadssel" value={this.state.chosen_list} onChange={this.handleListChange}>
              {this.lists}
            </select>
          </div>
          </Grid>
          <Grid item xs={6} sm={6}>
          <Typography variant="h6">Atlas Software Suite</Typography>
          <div style={{fontSize:'14px'}}>
          The software suite includes everything you need to operate your FBA business: inventory mangement, advanced profit analysis, creation of shipments, in-depth reports and much more!
          <br/><br/><strong style={{fontSize:'12px'}} >*The full Atlas Software Suite is included for FREE in any lead list subscription!*</strong>
          </div>
          </Grid>
          <Grid item xs={6} sm={6} style={{textAlign:'center',borderRight: '1px solid grey'}}>
          <StripeCheckout
            className={classes.button}
            // stripeKey="pk_test_yTxtNK50fnYQ20mawyqVWY2y"
            stripeKey="pk_live_aszZgWDTsAm84X8YDj7N1H0i"
            token={this.onToken}
            amount={Number(this.state.chosen_price)}
            description={this.state.chosen_list+' Leads List Subscription'}
            image={logo}
            locale="auto"
            name="Atlas"
            zipCode={true}
            label="Get Leads"
            email={user.email}
          />
          </Grid>
          <Grid item xs={6} sm={6} style={{textAlign:'center'}}>
          <StripeCheckout
            className={classes.button}
            // stripeKey="pk_test_yTxtNK50fnYQ20mawyqVWY2y"
            stripeKey="pk_live_aszZgWDTsAm84X8YDj7N1H0i"
            token={this.onTokenSoftware}
            amount={Number(3700)}
            description={"Atlas"}
            image={logo}
            locale="auto"
            name="Atlas Software Suite"
            zipCode={true}
            label="Get Software"
            email={user.email}
          />
          </Grid>

        </Grid>
        <div style={{textAlign:'center',color:'red'}}><br/>
        {errors.payment}
        </div>

      </Paper>
    )
    return (
          <div className={classes.root}>
          <div className="spinner" hidden={!pending}></div>
            <Grid container spacing={1}>
              <Grid item xs>
                {noSubs}
              </Grid>
            </Grid>
          </div>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps, { cancelSubscription, submitPayment })(withStyles(styles)(FormDialog)))
