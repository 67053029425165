import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Link as LinkIn } from 'react-router-dom';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit *2,
    marginBottom: theme.spacing.unit *2,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: "332px"
  },
  button:{
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "normal",
    padding:"2px",
    margin:"3px"
  }
});

class Firsttime extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }
  }

  render() {

    const { classes } = this.props;
    const { user } = this.props.auth;
    let firstTimeContent;
    if (user.sellerID){
      firstTimeContent = (
            <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left"}}>
                'Syncing' the Ship! (in a good way)
            </Typography>
              <br/>
              🎉You have successfully authorized Atlas with your Amazon Seller Account!🎉

              <br/>
              Right now we are syncing your data from Amazon.
              <br/>
              Typically it takes:
              <br/><strong><br/>
              ~45 seconds for Inventory info<br/>
              ~10 seconds for Pricing info<br/>
              ~1,800 orders/hour<br/>
              </strong><br/>
              If you have a lot of orders, it might take a few hours to get them all..
              But don't worry, we get the most recent first and work our way backwards in time.
              <br/><br/>
              If you have any issues or find any bugs, just reach out to <a style={{color:'blue'}} href="mailto:support@go-atlas.io">support@go-atlas.io</a>. Our world renound support team is standing by :)
              <br/><br/>
              Thanks again for joining Atlas; it means a lot to everyone on our team!
              <br/><br/>
              Jordan Kilburn
              <br/>
              CTO of Atlas Labs
            </Paper>
      );
    } else {
    firstTimeContent = (
          <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left"}}>
                Amazon Credentials
          </Typography>
          <p>Please authorize Atlas with you Amazon Seller Account by going to&nbsp;
          <LinkIn className={classes.link} to="/settings">
          Settings
          </LinkIn>
          </p>
          </Paper>
    );
  }
      return (
        <div className="firsttime">
          <div className="container">
          <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs>
                {firstTimeContent}
            </Grid>

          </Grid>
          </div>
          </div>
        </div>
      );
    }
}

Firsttime.propTypes = {
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps, { })(withStyles(styles)(Firsttime)))
