import axios from 'axios';

const BASE_URL = "/api/upload";

export const upload = (data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL, data, { headers })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const cleanCloudTempFolder = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/clean-temp-folder`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
