import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Link } from 'react-router-dom';
import * as qs from 'query-string';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const parsed = qs.parse(this.props.location.search);
    let  loginRedirect = '/oaleads'
    if (this.props.auth.isAuthenticated) {
      if (parsed.loginRedirect) {
        loginRedirect = '/'+parsed.loginRedirect;
      }
      window.location = loginRedirect;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }
    if (this.props.auth.isAuthenticated) {
      const parsed = qs.parse(this.props.location.search);
      let loginRedirect = '/oaleads';
      if (parsed.loginRedirect) {
        loginRedirect = '/'+parsed.loginRedirect;
      }
      window.location = loginRedirect;
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password
    };

    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto"><br/>
              <h1 className="text-center">Log In</h1>
              <p className="lead text-center">
                Sign in to your Atlas account
              </p>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />

                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                {errors.verified}
                <input type="submit" className="btn btn-info btn-block mt-4" style={{marginTop:'1px'}}/>
              </form>
              <br/>

              <a href={`https://www.go-atlas.io/onlinearbitrageleads/`} style={{margin:0}}>Need to Sign Up?</a>
              <Link to={`/forgotpassword`} style={{marginLeft:10}}>Forgot Password?</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(Login);
