import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Typography, Switch } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import keepaLogo from "../common/keepaLogo.png";

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  button: {
    backgroundColor: "Transparent",
    border: 0,
    padding: 3,
    margin: 0,
    minWidth: "20px",
    maxWidth: "20px",
    width: "20px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
});

class FormDialog extends React.Component {
  state = {
    open: false,
    range: 30,
    checked: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    let modalContent;
    const { checked } = this.state;
    let onClose = this.handleClose;
    const range = checked ? 30 : 90;
    if (this.state.open) {
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">Keepa</Typography>
            {onClose ? (
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>

          <DialogContent>
            <img
              alt={"keepa 4 " + this.props.asin}
              src={
                "https://graph.keepa.com/pricehistory.png?domain=com&asin=" +
                this.props.asin +
                "&salesrank=1&amazon=1&range=" +
                range +
                "&width=600&height=230"
              }
            />
            <div style={{textAlign:'center'}}>
            30 Day Graph
            <Switch
              defaultChecked
              value={checked}
              color="default"
              onChange={() => this.setState({ checked: !checked })}
            />
            90 Day Graph
            </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = <div></div>;
    }
    return (
      <span>
        <Button className={classes.button} onClick={this.handleClickOpen}>
          <img src={keepaLogo} style={{ width: 15 }} alt="keepa" />
        </Button>
        {modalContent}
      </span>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(FormDialog);
