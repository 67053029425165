import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Typography,Radio,Button,Grid,Paper} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../common/Spinner';
import TextFieldGroup from '../common/TextFieldGroup';
import {toast} from 'react-toastify';
import { cancelSubscription,downgrade } from '../../actions/authActions';
import { withRouter } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import { submitPayment } from '../../actions/authActions';
import logo from '../common/logo.png';
import jordan from './jordan.gif'
import jake from './jake.gif'
import ali from './ali.gif'
import infographic from './Atlas_Infographic.pdf'


const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button:{
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding:"5px",
    margin:"3px",
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    "&:disabled":{
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
  },
  input: {
    display: 'none',
  },
  table: {
    width: "40%",
    tableLayout: 'auto'
  },
  cell: {
    width:'150px',
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  link: {
    color: "blue",
    textDecoration: "underline"
  },
  exitOptions:{
    //borderStyle:'groove',
    padding:12,
    height:300,
    //backgroundColor:'#69829B',
    //color:'white'
  } ,
  killOptions:{
    padding:12,
  }
});


class FormDialog extends React.Component {
  state = {
    open: false,
    errors: {},
    subscriptions:[],
    radioValue:'',
    auth:'',
    step:1,
    q1:'How often did you use Atlas?',
    q2:"What's the single biggest reason for you canceling?",
    q3:"What would it take for you to consider resubscribing?",
    q4:"What's the most important thing you'd like us to know to improve in the future?",
    reason1:'',
    reason2:'',
    reason3:'',
    reason4:'',
    whoDies:'',
    chosen_list: 'Ungated A',
    chosen_price:13900,

  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      if (this.props.errors.subscription){
          this.handleClose();
          toast.error(this.props.errors.subscription);
      }
      this.setState({
        errors: this.props.errors
      });
    }
    if (prevProps.auth !== this.props.auth) {
      this.setState({ auth: this.props.auth });
      if (this.props.auth.data && this.props.auth.data.method === 'getSubscriptions'){
        this.setState({ subscriptions: this.props.auth.data.response });
      }else if (this.props.auth.data && this.props.auth.data.method === 'cancelSubscription'){
        this.handleClose()
        toast.success('Subscription to '+this.props.auth.data.flavor+' Canceled! - You still have '+this.props.auth.data.cancel_at+' days of use.'+ (this.props.auth.data.downgrade?" After that, you will be billed only for the software!":""),{ autoClose: false });
      }
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true,step:1, radioValue:'' });
  };

  handleClose = () => {
    this.setState({ open: false,step:1, radioValue:'' });
  };

  radioChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getSubscriptions = (e) => {
    e.preventDefault();
    const cancelData = {
      method:'getSubscriptions',
      email:this.props.auth.user.email
    };
    this.props.cancelSubscription(cancelData, this.props.history)
    this.handleClickOpen();
  }

  cancelSubscription = (e) => {
    e.preventDefault();
    let errors = {}
    if (this.state.step === 1){
      this.setState({step:2});
    } else if (this.state.step === 2){
      const {reason1, reason2,reason3,reason4,q1,q2,q3,q4} = this.state;
      if (reason1.length < 3) errors.reason1 = 'Please type at least 3 characters'
      if (reason2.split(" ").length < 3) errors.reason2 = "Please don't type gibberish.. We need your honest feedback!";
      if (reason2.length < 30) errors.reason2 = 'Please type at least 30 characters'
      if (reason3.split(" ").length < 3) errors.reason3 = "Please don't type gibberish.. We need your honest feedback!";
      if (reason3.length < 30) errors.reason3 = 'Please type at least 30 characters'
      this.setState({errors})
      if (Object.keys(errors).length === 0) {
        {this.setState({errors})}
        this.setState({step:3});
      }
    } else if (this.state.step === 3) {
      const {whoDies} = this.state;
      if (whoDies === '') errors.whoDies = 'Pick someone to get a pay cut.'
      this.setState({errors})
      if (Object.keys(errors).length === 0) {
        {this.setState({errors})}
        this.setState({step:4});
      }
    } else if (this.state.step === 4) {
      const {reason1, reason2,reason3,reason4,q1,q2,q3,q4,whoDies} = this.state;
      const cancelData = {
        reasons:[{q:q1,r:reason1},{q:q2,r:reason2},{q:q3,r:reason3},{q:q4,r:reason4},{q:"Pay cut?",r:whoDies}],
        method:'cancelSubscription',
        email:this.props.auth.user.email,
        list_name:this.state.radioValue,
      };
      this.props.cancelSubscription(cancelData, this.props.history);
    }
  }

  onToken = (token, addresses) => {
    let data = {
      flavor:this.state.radioValue,
      token
    }
    this.props.submitPayment(data)
  };

  onTokenSoftware = (token, addresses) => {
    let data = {
      flavor:"Software Suite",
      token
    }
    this.props.submitPayment(data)
  };

  handleListChange = (e) => {
    e.preventDefault();
    const selectedIndex = e.target.options.selectedIndex;
    const price = e.target.options[selectedIndex].getAttribute('price');
    this.setState({errors: {},chosen_list: e.target.value,chosen_price:price});
  }

  downgrade = () => {
    const {reason1, reason2,reason3,reason4,q1,q2,q3,q4,whoDies} = this.state;
    const cancelData = {
      reasons:[{q:q1,r:reason1},{q:q2,r:reason2},{q:q3,r:reason3},{q:q4,r:reason4},{q:"Pay cut?",r:whoDies},{q:"downgrade?",r:"true!"}],
      method:'cancelSubscription',
      email:this.props.auth.user.email,
      list_name:this.state.radioValue,
      downgrade:true
    };
    this.props.cancelSubscription(cancelData, this.props.history);
  }


  render() {
    const { classes,user } = this.props;
    const {errors} = this.state;
    let modalContent;
    let onClose=this.handleClose;
    let content;

    let lists = [
      {id:0,name:"Ungated A",price:13900},
      {id:1,name:"Ungated B",price:13900},
      {id:7,name:"Ungated C",price:13900},
      {id:7,name:"Ungated D",price:13900},
      {id:4,name:"Replens",price:13900},
      {id:6,name:"Toys",price:13900},

    ];
    this.lists = lists.map((item, key) =>
    <option key={item.id} price={item.price} value={item.name}>{item.name}</option>
    );

    let noSubs = (
      <DialogContent>
        You're not subscribed to any plan, but you can pick one now by going to <br/><br/>
        <a target="_blank" style={{
          textDecoration: 'underline',
          "&:hover": {
            color: "#132f4a"
          }
        }} href='/settings'>Settings</a>

      </DialogContent>
    )
    if (this.state.subscriptions.length<1 && !this.props.auth.pending){
      content = noSubs
    }
    else if (this.state.step === 1) {content = (
      <DialogContent>
        {(this.props.auth.pending)?(
          <div style={{align:'center'}}><br/>Loading...<Spinner/></div>
        ):(<div>
          Which subscription do you want to cancel?
        <div className="table-responsive" style={{textAlign:'center'}}>
         <table className={classes.table}>
           <tbody >
               {this.state.subscriptions.map((sub, i) => {
                 return (
                 <tr style={{minWidth:'400px'}} key={i}>
                   <td className={classes.cell} style={{color: 'black'}} padding="none" align="right">{
                     <Radio
                       disabled={(sub.cancel_at !== null || sub.price===0)?true:false}
                       checked={this.state.radioValue === sub.flavor}
                       onChange={this.radioChange}
                       value={sub.flavor}
                       name="radioValue"
                       aria-label={sub}
                     />
                   }</td>
                   <td className={classes.cell} style={{color: 'black',minWidth:'350px'}} padding="none" align="left"><strong>{sub.flavor}</strong> - {sub.price===0?" Free with leads subscription":sub.planType+" @ $"+sub.price} {sub.cancel_at === null?null:<span>(Cancels on {(new Date(sub.cancel_at)).toLocaleDateString('en-US')})</span>}</td>
                  </tr>)
                })}
           </tbody>
         </table>

         </div>
         <div style={{textAlign:'right'}}>
         <button disabled={this.props.auth.pending || this.state.radioValue===''} className="btn btn-info  mt-1" style={{backgroundColor:'red', border:'0px'}} onClick={this.cancelSubscription}>
         Continue to Cancel {this.state.radioValue}
         </button>
         </div>
         </div>
         )}
      </DialogContent>)}
    else if (this.state.step === 2) {content = (
      <DialogContent>
        Please answer just a couple quick question on why you want to cancel. This helps us serve our customers better!
        <br/><br/>
        {this.state.q1}
        <TextFieldGroup
          name="reason1"
          type="reason1"
          value={this.state.reason1}
          onChange={this.handleInputChange}
          error={errors.reason1}
        />
        {this.state.q2}
        <TextFieldGroup
          name="reason2"
          type="reason2"
          value={this.state.reason2}
          onChange={this.handleInputChange}
          error={errors.reason2}
        />
        {this.state.q3}
        <TextFieldGroup
          name="reason3"
          type="reason3"
          value={this.state.reason3}
          onChange={this.handleInputChange}
          error={errors.reason3}
        />
        {this.state.q4} (optional)
        <TextFieldGroup
          name="reason4"
          type="reason4"
          value={this.state.reason4}
          onChange={this.handleInputChange}
          error={errors.reason4}
        />
        <div style={{textAlign:'right'}}>
        <button disabled={this.props.auth.pending || this.state.radioValue===''} className="btn btn-info  mt-1" style={{backgroundColor:'red', border:'0px'}} onClick={this.cancelSubscription}>
        Continue to Cancel
        </button>
        </div>
      </DialogContent>
    )}
    else if (this.state.step === 3) {content = (
       <DialogContent>
        Every one of our loyal customers count! If you're leaving us, it means one of us messed up big time, and that's NOT ok...<br/>

        <strong>Pick who is going to get a pay cut:</strong>

        <Grid container style={{textAlign:'center',margin:7}}>
          <Grid item xs={4} sm={4} style={{padding:12}}>
          <Paper className={classes.killOptions}>
          <img src={jordan} style={{width:120}} alt="Jordan" /><br/>
          Jordan
          <Radio
            checked={this.state.whoDies === "Jordan"}
            onChange={this.radioChange}
            value={"Jordan"}
            name="whoDies"
            aria-label={this.state.whoDies}
          /><br/>
          <div style={{fontSize:12}}>
            Sold his car to finance this business.
          </div>
          </Paper>
          </Grid>
          <Grid item xs={4} sm={4} style={{padding:12}}>
          <Paper className={classes.killOptions}>
          <img src={ali} style={{width:120}} alt="Ali" /><br/>
          Ali
          <Radio
            checked={this.state.whoDies === "Ali"}
            onChange={this.radioChange}
            value={"Ali"}
            name="whoDies"
            aria-label={this.state.whoDies}
          />
          <div style={{fontSize:12}}>
            Trying to pay off her college loans.
          </div>
          </Paper>
          </Grid>
          <Grid item xs={4} sm={4} style={{padding:12}}>
          <Paper className={classes.killOptions}>
          <img src={jake} style={{width:120}} alt="Jake" /><br/>
          Jake
          <Radio
            checked={this.state.whoDies === "Jake"}
            onChange={this.radioChange}
            value={"Jake"}
            name="whoDies"
            aria-label={this.state.whoDies}
          />
          <div style={{fontSize:12}}>
            Can't walk the dog if he's searching for jobs.
          </div>
          </Paper>
          </Grid>
        </Grid>
        <div style={{textAlign:'center'}}>
        <button disabled={this.props.auth.pending} className="btn btn-info  mt-1" style={{border:'0px'}} onClick={this.handleClose}>
        Nevermind, I Want To Stay!
        </button>
        <br/>
        <button disabled={this.props.auth.pending} className="btn btn-info  mt-1" style={{backgroundColor:'red', border:'0px',fontSize:10}} onClick={this.cancelSubscription}>
        Continue to Cancel
        </button>
        <div style={{fontSize:11,color:'red'}}>
        {errors.whoDies}
        </div>
        </div>
       </DialogContent>
       )}
    else if (this.state.step === 4) {content = (
      <DialogContent>
      <div style={{fontSize:18}}>
        Alright, we'll make sure {this.state.whoDies} gets a pay cut!
        To save {this.state.whoDies}, why don't you try one of these options first...
        </div>
        <div style={{textAlign:'center'}}>
        <Grid container style={{textAlign:'center'}}>
          <Grid item xs={4} sm={4} style={{padding:12}}>
            <Paper className ={classes.exitOptions}>
            <div style = {{fontSize:30,marginBottom:20}}>
            Atlas is the Best in Business<br/>
            </div>
            See how we compare with other OA leads services and inventory software
            <br/><br/>
            <button  disabled={this.props.auth.pending} className="btn btn-info  mt-1" onClick={()=>{window.open(infographic, "_blank")}}>
              See Infographic
            </button>
            <br/>
            </Paper>
          </Grid>
          {/* <Grid item xs={4} sm={4} style={{padding:12}}>
              <Paper className = {classes.exitOptions}>
              <div style = {{fontSize:30,marginBottom:20}}>
              Online Arbitage Web Training<br/>
              </div>
              Jump right into Amazon FBA with out Atlas Jumpstart OA Course. Free!
              <br/><br/>
              <button  disabled={this.props.auth.pending} className="btn btn-info  mt-1" onClick={()=>{window.location.assign("/oacourse")}}>
                Watch Free Training
              </button>
              <br/>
              </Paper>
          </Grid> */}
          <Grid item xs={4} sm={4} style={{padding:12}}>
              <Paper className ={classes.exitOptions}>
              <div style = {{fontSize:30,marginBottom:20}}>
              Downgrade to Only Software<br/>
              </div>
                For only $37/mo you can keep the inventory software! Upgrade back at anytime later.
              <br/><br/>
              <button disabled={this.props.auth.pending} className="btn btn-info  mt-1" onClick={this.downgrade}>
                Keep Software for $37
              </button>
              <br/>
              </Paper>
          </Grid>
        </Grid>
        </div>
        <div style={{textAlign:'right'}}>
        <button disabled={this.props.auth.pending} className="btn btn-info  mt-1" style={{backgroundColor:'red', border:'0px',fontSize:14}} onClick={this.cancelSubscription}>
        Give {this.state.whoDies} a Pay Cut and Cancel my Subscription to {this.state.radioValue}!
        </button>
        </div>
      </DialogContent>
      )}




    if (this.state.open){
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={this.state.step === 4?'md':'sm'}
          fullWidth={true}
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Cancel Account</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
          {content}
        </Dialog>
      );
    } else {
      modalContent = (
        <div></div>
      );
    }
    return (
      <div>

      <Button
      className={classes.button}
      style={{width:'100%',}}
      variant="contained" color="primary"
      onClick={this.getSubscriptions}>
      Cancel Account
      </Button>
        {modalContent}
      </div>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps, { cancelSubscription,downgrade })(withStyles(styles)(FormDialog)))
