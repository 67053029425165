import {
  LOADING_UNGATING_TYPE,
  STOP_LOADING_UNGATING_TYPE,
  GET_UNGATING_TYPES,
  DELETE_UNGATING_TYPE,
  UPDATE_UNGATING_TYPE,
  CREATE_UNGATING_TYPE,
  GET_UNGATING_TYPE,
  LOADING_UPDATE_UNGATING_TYPE,
  LOADING_DELETE_UNGATING_TYPE,
  LOADING_CREATE_UNGATING_TYPE,
  LOADING_GET_UNGATING_TYPE,
  LOADING_GET_PUBLIC_UNGATING_TYPES,
  GET_PUBLIC_UNGATING_TYPES,
} from '../actions/types';

const initialState = {
  ungatings: [],
  success: false,
  loading: false,
  lastUngating: undefined,
  loadingUpdate: false,
  loadingDelete: false,
  loadingCreate: false,
  loadingGet: false,
  loadingGetPublic: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_UNGATING_TYPE:
      return {
        ...state,
        loading: true,
      };
    case LOADING_GET_PUBLIC_UNGATING_TYPES:
      return {
        ...state,
        loadingGetPublic: true,
      };
    case GET_PUBLIC_UNGATING_TYPES:
      return {
        ...state,
        loadingGetPublic: false,
        ungatings: action.payload.result,
        success: action.payload.success,
      };
    case STOP_LOADING_UNGATING_TYPE:
      return {
        ...state,
        loading: false,
      };
    case LOADING_UPDATE_UNGATING_TYPE:
      return {
        ...state,
        loadingUpdate: true,
      };
    case LOADING_DELETE_UNGATING_TYPE:
      return {
        ...state,
        loadingDelete: true,
      };
    case LOADING_CREATE_UNGATING_TYPE:
      return {
        ...state,
        loadingCreate: true,
      };
    case LOADING_GET_UNGATING_TYPE:
      return {
        ...state,
        loadingGet: true,
      };
    case GET_UNGATING_TYPES:
      return {
        ...state,
        loading: false,
        ungatings: action.payload.result,
        success: action.payload.success,
      };
    case DELETE_UNGATING_TYPE:
      const filteredUngatings = state.ungatings.filter((ungating) =>
        ungating._id !== action.payload.result
      );
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        ungatings: filteredUngatings,
        loadingDelete: false,
      };
    case UPDATE_UNGATING_TYPE:
      const mappedUngatings = state.ungatings.map((ungating) =>
        ungating._id === action.payload.result._id ?
          action.payload.result :
          ungating
      );
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        ungatings: mappedUngatings,
        loadingUpdate: false,
      };
    case CREATE_UNGATING_TYPE:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        ungatings: [...state.ungatings, action.payload.result],
        loadingCreate: false,
      };
    case GET_UNGATING_TYPE:
      return {
        ...state,
        loading: false,
        lastUngating: action.payload.result,
        loadingGet: false,
      };
    default:
      return state;
  }
};
