import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import leadsReducer from './leadsReducer';
import inventoryReducer from './inventoryReducer';
import shipmentsReducer from './shipmentsReducer';
import ordersReducer from './ordersReducer';
import reportsReducer from './reportsReducer';
import announceReducer from './announceReducer';
import ungatingReducer from './ungatingReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  leads:leadsReducer,
  inventory:inventoryReducer,
  orders:ordersReducer,
  shipments:shipmentsReducer,
  reports:reportsReducer,
  announce:announceReducer,
  ungating:ungatingReducer,
});
