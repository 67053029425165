import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  label,
  error,
  success,
  info,
  type,
  onChange,
  disabled,
  style
}) => {

  let bottomLine;
  if (error) {bottomLine = (<div className="invalid-feedback">{error}</div>)}
  else if (success) {bottomLine = (<div style={{fontSize: '80%',visibility:'visible',color:'green'}}>{success}</div>)}
  else bottomLine = (<div style={{fontSize: '80%',display:'none'}}>{'.'}</div>)

  return (
    <div className="form-group">
      {label && <small>{label}</small>}
      <input
        type={type}
        className={classnames('form-control margin-bottom', {
          'is-invalid margin-bottom': error
        })}
        style={style}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {bottomLine}
    </div>
  );
};

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

TextFieldGroup.defaultProps = {
  type: 'text'
};

export default TextFieldGroup;
