import React, { PureComponent } from "react";

// Wysiwyg
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// MaterialUI
import { withStyles } from '@material-ui/core/styles';

// Utils
import { upload } from '../../../utils/fileUpload';

// Styles
import './WysiwygEditor.scss';

const styles = () => ({
  wrapperContainer: {
    fontFamily: "Quicksand,serif",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "4px",
    border: "1px solid lightgrey",
    maxWidth: "721px",
  },
  editorContainer: {
    padding: "10px",
    minHeight: "300px",
    // maxWidth: "calc(100vw - 223px)"
  },
});
class WysiwygEditor extends PureComponent {
  constructor(props) {
    super(props);
    const editorState = EditorState.createEmpty();
    this.state = {
      editorState,
    };
  }

  uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const data = new FormData();
      data.append('file', file);
      upload(data, headers)
        .then(res => {
          const { success } = res.data;
          if (success) {
            const { result } = res.data;
            resolve({
              data: {
                link: result.secure_url,
              },
            });
          } else {
            const { error } = res.data;
            reject(error);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  componentDidMount() {
    const { initialState } = this.props;
    // Keep this on the did mount
    if (initialState) {
      const fromRaw = convertFromRaw(initialState);
      const editorState = EditorState.createWithContent(fromRaw);
      this.setState({
        editorState,
      });
    }
  }

  // Public
  getDataToStore = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const raw = convertToRaw(currentContent);
    return raw;
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { placeholder, classes, editorId } = this.props;
    const { editorState } = this.state;
    return (
      <Editor
        wrapperId={editorId}
        wrapperClassName={classes.wrapperContainer}
        editorClassName={classes.editorContainer}
        editorState={editorState}
        placeholder={placeholder}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          options: [
            'history',
            'fontSize',
            'inline',
            'colorPicker',
            'textAlign',
            'list',
            'link',
            'emoji',
            'image',
          ],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          colorPicker: {
            colors: [
              'rgba(0,0,0,0.54)', 'rgb(26,188,156)'  , 'rgb(84,172,210)', 'rgb(44,130,201)' , 'rgb(147,101,184)',
              'rgb(71,85,119)'  , 'rgb(204,204,204)' , 'rgb(65,168,95)' , 'rgb(0,168,133)'  , 'rgb(61,142,185)' ,
              'rgb(41,105,176)' , 'rgb(85,57,130)'   , 'rgb(40,50,78)'  , 'rgb(0,0,0)'      , 'rgb(247,218,100)',
              'rgb(251,160,38)' , 'rgb(235,107,86)'  , 'rgb(226,80,65)' , 'rgb(163,143,132)', 'rgb(239,239,239)',
              'rgb(255,255,255)', 'rgb(250,197,28)'  , 'rgb(243,121,52)', 'rgb(209,72,65)'  , 'rgb(184,49,47)'  ,
              'rgb(124,112,107)', 'rgb(209,213,216)' , 'rgb(97,189,109)',
            ],
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 13, 14, 16, 18, 24],
          },
          textAlign: {
            inDropdown: false,
            options: ['left', 'center', 'right', 'justify'],
          },
          list: {
            inDropdown: false,
          },
          link: {
            inDropdown: false,
            options: ['link', 'unlink'],
          },
          emoji: {
            emojis: [
              '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
              '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
              '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
              '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
              '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
              '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
              '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
              '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
              '✅', '❎', '💯',
            ],
          },
          history: {
            inDropdown: false,
            options: ['undo', 'redo'],
          },
          image: {
            uploadCallback: this.uploadImageCallBack,
            alt: {
              present: true,
              mandatory: false,
            },
            previewImage: true,
            defaultSize: {
              height: 'auto',
              width: '650px',
            },
          },
        }}
      />
    );
  }
}

export default (withStyles(styles)(WysiwygEditor));

