import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Typography,Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { cancelSubscription } from '../../actions/authActions';
import { withRouter } from 'react-router-dom';


const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  button:{
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "normal",
    padding:"2px",
    margin:"3px"
  },
  input: {
    display: 'none',
  },
  table: {
    width:"90%",
    tableLayout: 'auto',
    border:'2px'
  },
  cell: {
    border:'2px'
  },
  closeButton: {
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}
});


class FormDialog extends React.Component {
  state = {
    open: false,
    errors: {},
    auth:'',
  };


  handleClickOpen = () => {
    this.setState({ open: true,step:1, radioValue:'' });
  };

  handleClose = () => {
    this.setState({ open: false,step:1, radioValue:'' });
  };

  render() {
    const { classes,cancelData } = this.props;
    let modalContent;
    let onClose=this.handleClose;
    if (this.state.open){
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={'lg'}
          fullWidth={true}
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Reason User Cancelled</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
          <DialogContent>
          <div className="table-responsive" style={{textAlign:'center'}}>
           <table border="1">
             <tbody border="1" >
                 {cancelData.map((row, i) => {
                   return (
                   <tr key={i} border="1">
                     <td border="1" style={{color: 'black',padding:"3px"}}  align="left">{row.q}</td>
                     <td border="1" style={{color: 'black',padding:"3px"}}  align="left">{row.r}</td>
                    </tr>)
                  })}
             </tbody>
           </table>
           </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = (
        <div></div>
      );
    }
    return (
      <div>
      <Button variant="contained"
        className={classes.button}
        onClick={this.handleClickOpen}
        >
        Questionaire
      </Button>
        {modalContent}
      </div>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps, { cancelSubscription })(withStyles(styles)(FormDialog)))
