import React from 'react';

// Redux
import { connect } from 'react-redux';

// Router DOM
import { withRouter } from 'react-router-dom';

// MaterialUI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Actions
import {
  getPublicUngatingTypes,
} from '../../../actions/ungatingActions';

// Components
import PackageCard from './PackageCard';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  card: {
    borderColor: 'black',
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
});
class UngatingPackages extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ungatings: [],
      height: document.documentElement.scrollHeight || 0,
    };
  }

  // window.parent.postMessage(message, '*');
  componentDidMount() {
    document.body.classList.add('hide-fc');
    this.props.getPublicUngatingTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ungating.ungatings !== this.props.ungating.ungatings) {
      const { ungatings } = this.props.ungating;
      this.setState({
        ungatings,
      });
    }
    const currentHeight = document.documentElement.scrollHeight || 0;
    if (this.state.height !== currentHeight) {
      this.setState({
        height: currentHeight,
      }, () => {
        try {
          window.parent.postMessage(JSON.stringify({ type: 'resize', value: currentHeight }), '*');
        } catch (e) {
          console.log('Error on postMessage', e);
        }
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { ungatings } = this.state;

    return (
      <div className="container">
        <div className={classes.container}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={6}
          >
            {
              ungatings.map((ungating, index) => (
                <Grid
                  key={ungating._id}
                  item
                  xs={12}
                  md={3}
                >
                  <PackageCard
                    ungating={ungating}
                  />
                </Grid>
              ))
            }
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ungating: state.ungating,
});

export default withRouter(connect(mapStateToProps, {
  getPublicUngatingTypes,
})(withStyles(styles)(UngatingPackages)));
