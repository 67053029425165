import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button:{
    // position:'absolute',
    // right:0,
    display:'inline',
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding:"2px",
    margin:"3px",
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    "&:disabled":{
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
  },
  closeButton: {
  position: 'absolute',
  right: theme.spacing.unit,
  top: theme.spacing.unit,
  color: theme.palette.grey[500],
}
});


class WhyDataModal extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    let modalContent;
    let onClose=this.handleClose;
    if (this.state.open){
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={this.state.step === 4?'md':'sm'}
          fullWidth={true}
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Why We Need This Information</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <DialogContent><br/>
        <strong style={{fontSize:14}}>**You do not need to enter your Amazon credentials to access your OA leads.**<br/></strong>
        You can access your leads here:&nbsp;
        <Link className="link" style={{color:'blue',textDecoration: 'underline'}} to="/oaleads">
         OA Leads
       </Link><br/><br/>

        However, in order to access all other functionality of the suite, we need to be able to read and update your orders, inventory, and shipments.
        This is so you will be able to use features like: <li>Reports</li><li> 1-Click & List</li><li>Inventory</li><li>Dashboard Sales & Profit</li>
        <li>Shipment Viewing and Creation</li><li>SKU Analysis</li><li>And All the Other Amazing Features!</li>
        </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = (
        <span></span>
      );
    }
    return (
      <span>
      <Button variant="contained" color="primary"
      className={classes.button} onClick={this.handleClickOpen}>Why Do We Need This Information?</Button>
      {modalContent}
      </span>
    );
  }
}

WhyDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhyDataModal);
