import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

// Redux
import { connect } from 'react-redux';

// Router DOM
import { withRouter, Link } from 'react-router-dom';

// MaterialUI
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';

// Actions
import {
  ungatingService,
  claimCode,
  submitTestimonial
} from '../../actions/authActions';
import {
  getUngatingTypes,
} from '../../actions/ungatingActions';

// Components
import PurchaseModal from './PurchaseModal';

// DraftJS
import draftToHtml from 'draftjs-to-html';

// Styles
import './Ungating.scss';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 2
  },
  paper: {
    position: 'relative',
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  button: {
    border: '0px',
    textTransform: "none",
    fontWeight: "normal",
    fontSize: '18px',
    lineHeight: '20px',
    backgroundColor: '#149cea',
    "&:hover": {
      border: '0px',
      backgroundColor: "#132f4a"
    },
    "&:disabled": {
      border: '0px',
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
  },
  backToMenuButton: {
    position: 'absolute',
    right: '16px',
  },
  link: {
    color: "blue",
    textDecoration: "underline"
  },
  singleDiscountDescription: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  singleDiscountContainer: {
    '& p': {
      fontSize: '14px',
      fontWeight: '400',
      marginBottom: '0.5rem',
      color: 'rgba(0, 0, 0, 0.54)',
      '&:first-of-type': {
        marginTop: '0.5rem',
      }
    },
    '& > strong': {
      fontSize: '20px',
    }
  },
  singleDiscountMessagesContainer: {
    marginTop: '0.5rem',
  },
  singleDiscountMessageSuccess: {
    color: 'green',
  },
  singleDiscountMessageError: {
    color: 'red',
  },
});

function getAmountOff(extraCoupon) {
  if (extraCoupon.discountType === 'AMOUNT') {
    return `$${extraCoupon.amount}`;
  }
  return `${extraCoupon.amount}%`;
}

class Ungating extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      ungatings: [],
      hasUserPaid: false,
      completed: false,
      leadsCoupon: "",
      ungatingCoupon: "",
      leadsCouponRequested: false,
      ungatingCouponRequested: false,
      extraCouponRequested: {},
      extraCouponCode: {},
      testimonial: "",
      ungatingSelected: null,
      step: 0,
    };
  }

  componentDidMount() {
    this.props.getUngatingTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
    if (prevProps.auth !== this.props.auth && this.props.auth.data !== prevProps.auth.data) {
      if (this.props.auth.data.hasUserPaid) {
        const extraCoupons = this.props.auth.data.catOrBrand.extraCoupons || null;
        this.setState({
          ungatingSelected: this.props.auth.data.catOrBrand,
          hasUserPaid: true,
          extraCouponRequested: extraCoupons
            ? extraCoupons.reduce((acc, curr) => ({...acc, [curr.code]: false }), {})
            : {},
          extraCouponCode: extraCoupons
            ? extraCoupons.reduce((acc, curr) => ({...acc, [curr.code]: '' }), {})
            : {},
        });
      }
      if (this.props.auth.data.code && this.props.auth.data.campaign.includes("off-leads")) {
        this.setState({
          leadsCoupon: this.props.auth.data.code,
        });
      }
      if (this.props.auth.data.code && this.props.auth.data.campaign.includes("off-ungating")) {
        this.setState({
          ungatingCoupon: this.props.auth.data.code,
        });
      }
      if (this.props.auth.data.code && this.props.auth.data.campaign.includes("off-special")) {
        this.setState(s => ({
          extraCouponCode: {
            ...s.extraCouponCode,
            [this.props.auth.data.campaign]: this.props.auth.data.code
          }
        }));
      }
    }
    if (prevProps.ungating.ungatings !== this.props.ungating.ungatings) {
      this.setState({
        ungatings: this.props.ungating.ungatings,
      });
    }
  }

  chooseUngatingType = (ungating) => {
    const extraCoupons = ungating.extraCoupons || null;
    this.setState({
      ungatingSelected: ungating,
      extraCouponRequested: extraCoupons
        ? extraCoupons.reduce((acc, curr) => ({...acc, [curr.code]: false }), {})
        : {},
      extraCouponCode: extraCoupons
        ? extraCoupons.reduce((acc, curr) => ({...acc, [curr.code]: '' }), {})
        : {},
    }, () => {
      this.props.ungatingService({
        catOrBrand: ungating,
        action: "hasUserPaid",
      });
    });
  };

  claimCode = (campaign) => {
    this.setState(s => {
      const newState = {
        ...s,
        errors: {},
        leadsCoupon: "",
        ungatingCoupon: "",
        leadsCouponRequested: campaign.includes("off-leads"),
        ungatingCouponRequested: campaign.includes("off-ungating"),
      };
      if (campaign.includes('off-special')) {
        newState.extraCouponRequested[campaign] = true;
        newState.extraCouponCode[campaign] = '';
      }
      return newState;
    }, () => {
      this.props.claimCode({
        action: 'claim',
        campaign,
      });
    });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };

  submitTestimonial = (e) => {
    e.preventDefault();
    const { testimonial, ungatingSelected } = this.state;
    this.props.submitTestimonial({
      testimonial,
      testimonialType: ungatingSelected.name,
    });
    this.setState({
      testimonial: "",
    });
  };

  handleBackToMenu = () => {
    this.setState({
      ungatingSelected: null,
      hasUserPaid: false,
      step: 0,
      completed: false,
    });
  };

  renderNavigationButton = (caption, onClick, className, style) => {
    return (
      <Button variant="contained"
        color="primary"
        style={style}
        className={className}
        onClick={onClick}
      >
        {caption}
      </Button>
    );
  };

  generateStepMarkup = (content) => {
    const markup = draftToHtml(content);

    // Images with "text-align: none"
    const replacedMarkup = markup.replace(
      new RegExp('<div style="text-align:none;">', 'g'),
      '<div style="text-align: center;">',
    ).replace(
      new RegExp('<p></p>', 'g'),
      '<p>&nbsp;</p>',
    );

    return replacedMarkup;
  };

  ungatingsToShow = () => {
    const { ungatings } = this.state;
    const ungatingsToShow = ungatings.reduce((prev, ungating) => prev || ungating.visible, false);
    return ungatingsToShow;
  };

  render() {
    const { classes } = this.props;
    const { pending, auth } = this.props.auth;
    const {
      errors,
      ungatingSelected,
      hasUserPaid,
      step,
      completed,
      leadsCoupon,
      ungatingCoupon,
      ungatings,
      testimonial,
      leadsCouponRequested,
      ungatingCouponRequested,
      extraCouponRequested,
      extraCouponCode,
    } = this.state;

    let ungatingContent, chooseCategory, resetButton;

    if (ungatingSelected && hasUserPaid && completed) {
      chooseCategory = (
        <div>
          <br/>
          <div>
            <strong
              style={{ color: 'green', fontSize: '18px' }}
            >
              Congratulations on being ungated in {ungatingSelected.name}!
            </strong>
            <br/>
            <br/>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div style={{ fontSize: '18px' }}>
                  Please check out these discounts for other Atlas products...
                </div>
                <br/>
                <div style={{ textAlign: 'left' }}>
                  {ungatingSelected.extraCoupons && ungatingSelected.extraCoupons.map((extraCoupon, idx) => (
                      <>
                        <div className={classes.singleDiscountContainer}>
                        {/* title
                        description
                        buttonText */}
                          <strong>{extraCoupon.title}</strong>
                          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(extraCoupon.description)}}></span>
                          <Button variant="contained"
                            color="primary"
                            onClick={() => this.claimCode(extraCoupon.code)}
                            className={classes.button}
                          >
                            {extraCoupon.buttonText}
                          </Button>
                          <div className={classes.singleDiscountMessagesContainer}>
                            <div className={classes.singleDiscountMessageSuccess}>
                            {
                              (extraCouponRequested[extraCoupon.code] && extraCouponCode[extraCoupon.code])
                                ? (
                                  <span>
                                    Use this code on checkout: <strong>{extraCouponCode[extraCoupon.code]}</strong>.
                                  </span> 
                                )
                                : null
                            }
                            </div>
                            <div className={classes.singleDiscountMessageError}>
                            {
                              (extraCouponRequested[extraCoupon.code] && errors?.extraCoupon?.[extraCoupon.code])
                                ? (
                                  <span>
                                    {errors.extraCoupon[extraCoupon.code].split(": ")[0]}:&nbsp;
                                    <strong>{errors.extraCoupon[extraCoupon.code].split(": ")[1]}</strong>
                                  </span> 
                                )
                                : null
                            }
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  <div className={classes.singleDiscountContainer}>
                    <strong>DISCOUNT: Get 15% off all Lead-Lists</strong>
                    <p>
                      Claim this discount to get 15% off any NEW (monthly or biannual) online arbitrage lead-list
                      subscription.
                    </p>
                    <p>
                      You could apply this discount to:
                      <ul>
                        <li>Replenishables</li>
                        <li>Toys</li>
                        <li>
                          Ungated (Already subscribed to ungated? Use this coupon to purchase another ungated list—giving
                          you a total of 10 unique leads/day. You can buy up to 9 lists—giving you a total of 45 unique
                          leads/day, etc.)
                        </li>
                      </ul>
                    </p>
                    <Button variant="contained"
                      color="primary"
                      onClick={() => this.claimCode(`ungating-15-off-leads-${ungatingSelected.name}`)}
                      className={classes.button}
                    >
                      Claim your 15% off Discount
                    </Button>
                    <div className={classes.singleDiscountMessagesContainer}>
                      <div className={classes.singleDiscountMessageSuccess}>
                      {
                        (leadsCouponRequested && leadsCoupon) ?
                          <span>Use this code on checkout: <strong>{leadsCoupon}</strong>. It will expire in 14 days.</span> :
                          null
                      }
                      </div>
                      <div className={classes.singleDiscountMessageError}>
                      {
                        (leadsCouponRequested && errors.coupon) ?
                          <span>{errors.coupon.split(": ")[0]}: <strong>{errors.coupon.split(": ")[1]}</strong></span> :
                          null
                      }
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className={classes.singleDiscountContainer}>
                    <strong>DISCOUNT: Get 50% off Any Other Category-Ungating Package</strong>
                    <p>
                      We&apos;ll be honest: After you&apos;ve successfully gotten ungated in one category, you&apos;ll
                      see that it&apos;s MUCH easier to do it the next time.
                    </p>
                    <p>
                      In fact, most of the steps you had to take to get ungated in this last category, you&apos;ll do
                      again to get ungated in the next category.
                    </p>
                    <p>
                      Accordingly, you shouldn&apos;t have to pay full price for additional ungating walk-thru packages.
                    </p>
                    <p>
                      That&apos;s why we&apos;re offering this 50%-off discount for all additional ungating walk-thrus.
                    </p>
                    <Button variant="contained"
                      color="primary"
                      onClick={() => this.claimCode(`ungating-50-off-ungating-${ungatingSelected.name}`)}
                      className={classes.button}
                    >
                      Claim your 50% off coupon
                    </Button>
                    <div className={classes.singleDiscountMessagesContainer}>
                      <div className={classes.singleDiscountMessageSuccess}>
                      {
                        (ungatingCouponRequested && ungatingCoupon) ?
                          <span>Use this code on checkout: <strong>{ungatingCoupon}</strong>. It will expire in 14 days.</span> :
                          null
                      }
                      </div>
                      <div className={classes.singleDiscountMessageError}>
                      {
                        (ungatingCouponRequested && errors.coupon) ?
                          <span>{errors.coupon.split(": ")[0]}: <strong>{errors.coupon.split(": ")[1]}</strong></span> :
                          null
                      }
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className={classes.singleDiscountContainer}>
                    <strong>Become an Affiliate</strong>
                    <p>
                      Get a recurring 20% commission on the purchases of any new customer that you refer to Atlas.
                    </p>
                    <Link className="link" to="/affiliate">
                      <Button variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Learn More About Becoming an Affiliate
                      </Button>
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={1} />
              <Grid container item xs={5} direction="column">
                <strong>Leave Us a Testimonial :)</strong>
                <br/>
                <form disabled onSubmit={this.submitTestimonial}>
                  <textarea
                    style={{ height: 200, width: "100%" }}
                    name="testimonial"
                    type="testimonial"
                    value={testimonial}
                    onChange={this.handleInputChange}
                    error={errors.testimonial}
                  />
                  <Button type="submit"
                    variant="contained"
                    color="primary"
                    disabled={testimonial.length < 3}
                    className={classes.button}
                  >
                    Submit Testimonial
                  </Button>
                  <br/>
                  <br/>
                </form>
              </Grid>
            </Grid>
          </div>
          <br/>
          <br/>
          {
            this.renderNavigationButton(
              "Previous",
              () => this.setState({ completed: false }),
              classes.button,
              { left: 16, position: 'absolute', bottom: 16, }
            )
          }
        </div>
      );
      resetButton = this.renderNavigationButton(
        "Back to Menu",
        this.handleBackToMenu,
        classes.button,
        { position: 'absolute', right: '16px' }
      );
    } else if (ungatingSelected && hasUserPaid && !completed) {
      const { steps } = ungatingSelected;
      chooseCategory = (
        <div>
          <br/>
          <div>
            <strong>Step {step + 1}: {steps[step].title}</strong>
            <br/>
            <br/>
            <div
              className="with-markup"
              dangerouslySetInnerHTML={{ __html: this.generateStepMarkup(steps[step].content) }}
            />
          </div>
          <br/>
          <div
            style={{ fontSize: 14 }}
          >
            **If you have any questions about the instructions for this step, please email <a className={classes.link} href="mailto:ungating@go-atlas.io" rel="noopener noreferrer" target="_blank">ungating@go-atlas.io</a> with the subject: {ungatingSelected.name} Ungating Question.**
          </div>
          {
            steps[step + 1] && steps[step + 1].title.length !== 0 ?
              null :
              <div
                style={{ textAlign: 'center' }}
              >
                <br/>
                <strong>When you're approved, click the button below to let us know and access <span style={{ textDecoration: 'underline' }}>bonus content and discounts.</span></strong>
                <br/>
                <Button variant="contained"
                  className={classes.button}
                  onClick={() => this.setState({ completed: true })}
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                >
                  I've Been Ungated!!
                </Button>
              </div>
          }
          <br/>
          <br/>
          {
            steps[step - 1] && steps[step - 1].title.length !== 0 ?
              this.renderNavigationButton(
                "Previous Step",
                () => this.setState({ step: step - 1 }),
                classes.button,
                { left: 16, position: 'absolute', bottom: 16, }
              ) :
              null
          }
          {
            steps[step + 1] && steps[step + 1].title.length !== 0 ?
              this.renderNavigationButton(
                "Next Step",
                () => this.setState({ step: step + 1 }),
                classes.button,
                { right: 16, position: 'absolute', bottom: 16, }
              ) :
              null
          }
        </div>
      );
      resetButton = this.renderNavigationButton(
        "Back to Menu",
        this.handleBackToMenu,
        classes.button,
        { position: 'absolute', right: '16px' }
      );
    } else {
      chooseCategory = !this.ungatingsToShow() ?
      (
        <div>
          <br/>
          No ungatings currently available.
          <br/>
        </div>
      ) :
      (
        <div>
          <br/>
          Choose a Category or Brand to get ungated in:
          <br/>
          <br/>
          <Grid container spacing={1}>
            {
              ungatings.map((ungating) => {
                return (
                  ungating.visible ?
                    <Grid item
                      key={ungating._id}
                      sm={3}
                      md={2}
                      id={ungating._id}
                      style={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => this.chooseUngatingType(ungating)}
                    >
                      <img src={ungating.avatar ? ungating.avatar : require('./assets/default.png')}
                        alt={ungating.name}
                        style={{ width: "80%" }}
                      />
                      <br/>
                      {ungating.name}
                    </Grid> :
                    null
                )
              })
            }
          </Grid>
        </div>
      );
    }

    ungatingContent = (
      <Paper className={classes.paper}>
        <Typography className={classes.title}
          variant="h6"
          id="tableTitle"
          style={{ textAlign: "left" }}
        >
          Atlas Access Ungating Service {resetButton}
        </Typography>
        {chooseCategory}
      </Paper>
    );

    return (
      <div className="ungating">
        <div className="spinner" hidden={!pending} />
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {ungatingContent}
              {
                ungatingSelected ?
                  <PurchaseModal ungating={ungatingSelected}
                    auth={auth}
                    errors={errors}
                  /> :
                  null
              }
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Ungating.propTypes = {
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  ungating: state.ungating,
});

export default withRouter(connect(mapStateToProps, {
  ungatingService,
  claimCode,
  submitTestimonial,
  getUngatingTypes,
})(withStyles(styles)(Ungating)));
