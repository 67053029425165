import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getGraphData,getPieData,getTableData } from '../../actions/orderActions';
import { profitLossReport } from '../../actions/reportActions';

import Spinner from '../common/Spinner';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {Chart} from 'react-google-charts';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as LinkIn } from 'react-router-dom';

let counter = 0;
function createtabData( name, revenue, margin, profit, units ) {
  counter += 1;
  return { id: counter, name ,revenue, margin, profit, units };
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit *2,
    flexGrow: 1,
    marginBottom: theme.spacing.unit *2,
},
title: {
  flex: '0 0 auto',
  aligh: "center"
},
table: {
  margin:'auto',
  //minWidth: "100px",
  tableLayout: 'auto',
  height:"100%",
  textAlign:'center',
  fontSize: "14px",
  width: '100%'
},
topBar: {
  padding: theme.spacing.unit * 1,
  textAlign: "center",
  color: theme.palette.text.secondary,
},
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxHeight: "332px",
    minHeight: "332px",
  },
  datePick:{
    fontSize: "14px",
    fontWeight: "normal",
  },
  button:{
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "normal",
    padding:"2px",
    margin:"3px"
  },
   link:{
      color:"blue",
      textDecoration: "underline"
    },
    datepicker:{
      border: "1px solid grey",
      maxWidth:"70px",
      height:"26px",
      width:"100% !important",
      borderStyle:"none",
    },
    dateWrapper:{
      display:"inline",
      textTransform: "none",
      fontSize: "12px",
      fontWeight: "normal",
      padding:"2px",
      margin:"3px"
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    tableRow:{
      minWidth: "75%",
      width:"75%",
      maxWidth:'332px',
      textAlign:'center',
      minHeight: "30px",
      height:"30px",
      maxHeight:"30px",
      fontSize: "14px",
    },
    cell: {
      minHeight: "30px",
      height:"30px",
      maxHeight:"30px",
      fontSize: "14px",
      borderTop: '1px solid #ddd',
      borderBottom: '1px solid #ddd',
      }
});

class Dash extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chartChanger: 5,
      focusedInput: null,
      startDate: new Date(new Date() - 1000 * 60 * 60 * 24 * 7),
      endDate: new Date(),
      errors: {},
      inventory: [],
      orders: [],
      graphData: [],
      pieData: [],
      tableData: [],
      growthData: [],
      mOmGrowth:0,
      reqReport: [],
      reports:[],
      showDatePicker:false
    };
    }


  getInventory = (inv) => {
    this.props.getInventory(inv);
  }

  componentDidMount = () => {
    if (this.props.auth && this.props.auth.user && this.props.auth.user.sellerID && this.props.auth.user.sellerID !== null) {
      // this.getGraphData();
      // this.getPieData();
      this.getTableData();
      // this.profitLossReport()
      this.setNewDates(this.state.startDate,this.state.endDate)
    }
  }

componentDidUpdate(prevProps, prevState) {
  if (prevProps.errors !== this.props.errors) {
    this.setState({
      errors: this.props.errors
    });
  }
  if(prevProps.orders!==this.props.orders && this.props.orders.pieData){
    this.setState({
        pieData: this.props.orders.pieData
      });
  }
  if(prevProps.orders!==this.props.orders && this.props.orders.graphData){
    let data = this.createGraphData(this.props.orders.graphData);
    this.setState({
        graphData: data
      });
  }

  if(prevProps.orders!==this.props.orders && this.props.orders.tableData){
      let tableData = this.createTableData(this.props.orders.tableData);
      let growthData = this.createGrowthData(this.props.orders.tableData);
      this.setState({
          tableData,
          growthData:growthData.growthData,
          mOmGrowth:growthData.mOmGrowth
        });
  }
  if(prevProps.reports!==this.props.reports && this.props.reports.reqReport){
    this.setState({
        reports:  this.props.reports
      });
    if (prevProps.reports.reqReport !== this.props.reports.reqReport) {
      this.setState({
          reqReport: this.props.reports.reqReport,
        });
    }
  }
}

  setNewDates = (startDate, endDate) => {
    startDate = startDate.setHours(0, 0, 0, 0);
    endDate = endDate.setHours(0, 0, 0, 0);
    if (startDate > endDate){
      toast.error("Start Date must be older than End Date!");
      return 0;
    }
    this.props.getGraphData(startDate, endDate);
    this.props.getPieData(startDate,endDate)
    this.props.profitLossReport(startDate, endDate);
    this.setState({startDate:(new Date(startDate)),endDate:new Date(endDate)});
  }

  profitLossReport = () => {
    this.setState({reqReport: ''}, () => {
      this.props.profitLossReport(this.state.startDate, this.state.endDate);
    });
  }

  getGraphData = () => {
    this.props.getGraphData(this.state.startDate, this.state.endDate, this.state.graphData);
  }

  getTableData = () => {
    this.props.getTableData(this.state.tableData);
  }

  getPieData = () => {
    this.props.getPieData(this.state.startDate, this.state.endDate, this.state.pieData);
  }


createGraphData = (data) => {
  if (data === null || data.length < 1) return 0
  //data = data.slice(0, 20);
  for (let i = 1; i < data.length; i++) {
    data[i][0] = new Date(data[i][0]);
  }
  return data
}

createGrowthData = data => {
  if (data === null || data.length < 1) return 0
  let today = new Date();
  let firstDayThis = new Date(today.getFullYear(), today.getMonth(), 1);
  let firstDayLast = new Date(today.getFullYear(), today.getMonth() - 1 , 1);
  let daysinMonth = new Date(today.getFullYear(), today.getMonth()+1, 0).getDate();
  let dayinLast = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
  let maxDays = Math.max(daysinMonth,dayinLast);

  let growthData = [['Day of Month','Last Month','This Month']]
    for (let j = 1; j < maxDays+1; j++) {
      growthData.push([j,0,0])
      if (j>today.getDate()){
        growthData[j][2]=null
      }
      if (j>dayinLast){
        growthData[j][1]=null
      }
    }
    for (let i = 1; i < data.length; i++) {

      if (new Date(data[i][0]).getMonth() === today.getMonth()){

      }
      if (firstDayThis.getMonth() === new Date(data[i][0]).getMonth()){
        growthData[new Date(data[i][0]).getDate()][2] += Number(data[i][1]);
        if (new Date(data[i][0]).getDate() === today.getDate()){
        }
      }
      if (firstDayLast.getMonth() === new Date(data[i][0]).getMonth()){
        growthData[new Date(data[i][0]).getDate()][1] += Number(data[i][1]);
      }
    }
    let profitLast = 0;
    let profitSoFar = 0
    for (let k = 1; k < today.getDate(); k++) {
      profitLast += Number(growthData[k][1]);
      profitSoFar += Number(growthData[k][2]);
    }
    let mOmGrowth = profitSoFar/profitLast
    return {growthData,mOmGrowth}
}

createTableData = data => {
  if (data === null || data.length < 1) return 0
  let today = new Date();//new Date(new Date() + 1000 * 60 * 60 * 24 * 1);
  //range for Current
  let currentEnd = new Date(this.state.endDate)
  let currentStart = new Date(this.state.startDate)
  //Range for 'today'
  let todayEnd = new Date(new Date(new Date() + 1000 * 60 * 60 * 24 * 1).setHours(0,0,0,0));
  let todayStart = new Date(today.setHours(0,0,0,0));
  //range to yesterday
  let yesterdayEnd = todayStart;
  let yesterdayStart = new Date(new Date(new Date() - 1000 * 60 * 60 * 24 * 1).setHours(0,0,0,0));
  //range for 7 Days
  let sevenEnd = todayEnd;
  let sevenStart = new Date(new Date(new Date() - 1000 * 60 * 60 * 24 * 7).setHours(0,0,0,0));
  //range for 14
  let fourteenEnd = todayEnd;
  let fourteenStart = new Date(new Date(new Date() - 1000 * 60 * 60 * 24 * 14).setHours(0,0,0,0));
  //range for 30
  let thirtyEnd = todayEnd;
  let thirtyStart = new Date(new Date(new Date() - 1000 * 60 * 60 * 24 * 30).setHours(0,0,0,0));
  //range for 60
  let sixtyEnd = todayEnd;
  let sixtyStart = new Date(new Date(new Date() - 1000 * 60 * 60 * 24 * 60).setHours(0,0,0,0));
  //range for 90
  let nintyEnd = todayEnd;
  let nintyStart = new Date(new Date(new Date() - 1000 * 60 * 60 * 24 * 90).setHours(0,0,0,0));
  //sort all data into proper buckets
  let todayOrders = [0,0,0,0]; let yesterdayOrders = [0,0,0,0]; let sevenOrders =[0,0,0,0]; let fourteenOrders=[0,0,0,0];
  let thirtyOrders = [0,0,0,0]; let sixtyOrders = [0,0,0,0]; let nintyOrders = [0,0,0,0]; let currentOrders = [0,0,0,0]
  let tableData = [];

  for (let i = 1; i < data.length; i++) {
    let thisOrderDate = new Date(data[i][0]);
    if (currentEnd.getTime()>=thisOrderDate.getTime() && thisOrderDate.getTime() >= currentStart.getTime()){
      currentOrders[0]+=Number(data[i][2]);
      currentOrders[2]+=Number(data[i][1]);
      currentOrders[3]+=Number(data[i][3]);
    }
    if (todayEnd.getTime()>=thisOrderDate.getTime() && thisOrderDate.getTime() >= todayStart.getTime()){
      todayOrders[0]+=Number(data[i][2]);
      todayOrders[2]+=Number(data[i][1]);
      todayOrders[3]+=Number(data[i][3]);
    }
    if (yesterdayEnd.getTime()>thisOrderDate.getTime() && thisOrderDate.getTime() >= yesterdayStart.getTime()){
      yesterdayOrders[0]+=Number(data[i][2]);
      yesterdayOrders[2]+=Number(data[i][1]);
      yesterdayOrders[3]+=Number(data[i][3]);
    }
    if (sevenEnd.getTime()>=thisOrderDate.getTime() && thisOrderDate.getTime() >= sevenStart.getTime()){
      sevenOrders[0]+=Number(data[i][2]);
      sevenOrders[2]+=Number(data[i][1]);
      sevenOrders[3]+=Number(data[i][3]);
    }
    if (fourteenEnd.getTime()>=thisOrderDate.getTime() && thisOrderDate.getTime() >= fourteenStart.getTime()){
      fourteenOrders[0]+=Number(data[i][2]);
      fourteenOrders[2]+=Number(data[i][1]);
      fourteenOrders[3]+=Number(data[i][3]);
    }
    if (thirtyEnd.getTime()>=thisOrderDate.getTime() && thisOrderDate.getTime() >= thirtyStart.getTime()){
      thirtyOrders[0]+=Number(data[i][2]);
      thirtyOrders[2]+=Number(data[i][1]);
      thirtyOrders[3]+=Number(data[i][3]);
    }
    if (sixtyEnd.getTime()>=thisOrderDate.getTime() && thisOrderDate.getTime() >= sixtyStart.getTime()){
      sixtyOrders[0]+=Number(data[i][2]);
      sixtyOrders[2]+=Number(data[i][1]);
      sixtyOrders[3]+=Number(data[i][3]);
    }
    if (nintyEnd.getTime()>=thisOrderDate.getTime() && thisOrderDate.getTime() >= nintyStart.getTime()){
      nintyOrders[0]+=Number(data[i][2]);
      nintyOrders[2]+=Number(data[i][1]);
      nintyOrders[3]+=Number(data[i][3]);
    }
  }
  tableData.push(createtabData('Selected',currentOrders[0],Number(currentOrders[2]/currentOrders[0] *100).toFixed(2),currentOrders[2],currentOrders[3]))
  tableData.push(createtabData('Today',todayOrders[0],Number(todayOrders[2]/todayOrders[0] *100).toFixed(2),todayOrders[2],todayOrders[3]))
  tableData.push(createtabData('Yesterday',yesterdayOrders[0],Number(yesterdayOrders[2]/yesterdayOrders[0] *100).toFixed(2),yesterdayOrders[2],yesterdayOrders[3]))
  tableData.push(createtabData('7 Days',sevenOrders[0],Number(sevenOrders[2]/sevenOrders[0] *100).toFixed(2),sevenOrders[2],sevenOrders[3]))
  tableData.push(createtabData('14 Days',fourteenOrders[0],Number(fourteenOrders[2]/fourteenOrders[0] *100).toFixed(2),fourteenOrders[2],fourteenOrders[3]))
  tableData.push(createtabData('30 Days',thirtyOrders[0],Number(thirtyOrders[2]/thirtyOrders[0] *100).toFixed(2),thirtyOrders[2],thirtyOrders[3]))
  tableData.push(createtabData('60 Days',sixtyOrders[0],Number(sixtyOrders[2]/sixtyOrders[0] *100).toFixed(2),sixtyOrders[2],sixtyOrders[3]))
  tableData.push(createtabData('90 Days',nintyOrders[0],Number(nintyOrders[2]/nintyOrders[0] *100).toFixed(2),nintyOrders[2],nintyOrders[3]))

  return tableData;
}

createProfitLossReport = (orders) => {
  let incomeObj = {
    "Revenue": 0,
  }
  let profitObj = {
    "Profit": 0,
  }
  let expenseObj = {
    "COGS": 0,
    "Sales Tax": 0,
    "Shipping": 0,
    "Referral Fee": 0,
    "FBA Fees": 0,
    "Closing Fee": 0,
    "Inbound Shipping":0,
    "Storage Fee":0,
    "Prepper/Misc": 0,
    "Tax Collection Fee": 0,
    "Promotions": 0,
    
  }
  if (Object.values(orders).length===0 || orders.constructor !== Array) return {}

  for (let i = 0; i < orders.length; i++) {
    if (orders[i].skus) {
      let orderSku = orders[i].skus
      for (let j = 0; j < orderSku.length; j++) {
        if (orderSku[j].fbaFee) {
          expenseObj["FBA Fees"] +=(orderSku[j].fbaFee);
        }
        if (orderSku[j].buyPrice) {
          expenseObj["COGS"] += (-1*orderSku[j].buyPrice*orderSku[j].quantity);
        }
        if (orderSku[j].salesTaxCost) {
          expenseObj["Sales Tax"] += (-1*orderSku[j].salesTaxCost*orderSku[j].quantity);
        }
        if (orderSku[j].shippingFee) {
          expenseObj["Shipping"] += (-1*orderSku[j].shippingFee*orderSku[j].quantity);
        }
        if (orderSku[j].miscFee) {
          expenseObj["Prepper/Misc"] += (-1*orderSku[j].miscFee*orderSku[j].quantity);
        }
        if (orderSku[j].referalFee) {
          expenseObj["Referral Fee"] += (orderSku[j].referalFee);
        }
        if (orderSku[j].closingFee) {
          expenseObj["Closing Fee"] += (orderSku[j].closingFee);
        }
        if (orderSku[j].taxCollectFee) {
          expenseObj["Tax Collection Fee"] += (orderSku[j].taxCollectFee);
        }

        if (orderSku[j].promotions && orderSku[j].promotions.length > 0) {
          let totalFee =
            ((typeof orderSku[j].shippingCharge === "undefined") ? 0 : Number(orderSku[j].shippingCharge)) +
            ((typeof orderSku[j].shippingChargeback === "undefined") ? 0 : Number(orderSku[j].shippingChargeback));
          for (let k = 0; k < orderSku[j].promotions.length; k++) {
            let currPromo = orderSku[j].promotions[k];
            if (currPromo.promotionAmount) {
              totalFee += currPromo.promotionAmount;
            }

          }
          expenseObj["Promotions"] += totalFee;
        }
        if (orderSku[j].purchasedPrice) {
          incomeObj["Revenue"] += (orderSku[j].purchasedPrice);
        }
      }
    } else if (orders[i].feeType) {
      //this is a fee
        switch (orders[i].feeType) {
          case "FBAStorageFee":
            expenseObj["Storage Fee"] += orders[i].feeCost;
            break;
          case "FBAInboundTransportationFee":
            expenseObj["Inbound Shipping"] += orders[i].feeCost;
            break;
          default:
            expenseObj["Prepper/Misc"] += orders[i].feeCost;
            break;
        }
    }
  }

  profitObj["Profit"] = incomeObj["Revenue"];
  let graphArray = [
    ["Category","Total",{ role: 'style' }]
  ];
  let pieArray = [
    ["Category","Total"]
  ];
  graphArray.push(["Revenue",incomeObj["Revenue"],incomeObj["Revenue"]>0?'green':'red']);
  Object.keys(expenseObj).map((keyName, i) => {
      if (expenseObj[keyName] === 0){
        delete expenseObj[keyName];
      } else{
        profitObj["Profit"] += expenseObj[keyName];
        graphArray.push([keyName,expenseObj[keyName],expenseObj[keyName]>0?'green':'red']);
        pieArray.push([keyName,Math.abs(expenseObj[keyName])]);
      }
      return 0
  })
  graphArray.push(["Profit",profitObj["Profit"],profitObj["Profit"]>0?'green':'red']);

  return graphArray
}

  render() {
    const { user } = this.props.auth;
    const errors = this.props.errors;
    const { classes } = this.props;
    const { loading } = this.props.orders;
    const { graphData,chartChanger, pieData, tableData,growthData } = this.state;
    const errorTest = Object.keys(errors).length;
    let dashboardContent, reportGraph, chartContent,pieContent, tableContent,growthContent;
    let dateContent = (
      <div>
      <Button disabled={(loading&&!errorTest) ? true:false} variant="outlined" className={classes.button} onClick={() => {
        let startDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 7);
        let endDate = new Date();
        this.setNewDates(startDate, endDate)
      }}>
        7 Days
      </Button>
      <Button disabled={loading ? true:false} variant="outlined" className={classes.button} onClick={() => {
        let startDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
        let endDate = new Date();
        this.setNewDates(startDate, endDate)
      }}>
        30 Days
      </Button>
      <Button disabled={loading ? true:false} variant="outlined" className={classes.button} onClick={() => {
        let startDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 90);
        let endDate = new Date();
        this.setNewDates(startDate, endDate)
      }}>
        90 Days
      </Button>
      {this.state.showDatePicker?<div className={classes.dateWrapper}><div className={classes.dateWrapper}>
      <DatePicker
        disabled={loading ? true:false}
        className={classes.datepicker}
        selected={this.state.startDate}
        maxDate={this.state.endDate}
        onChange={(e) => {this.setNewDates(e, this.state.endDate)}}
        />
        </div>
        <div className={classes.dateWrapper}>
          <i className="fas fa-arrow-right"></i>
        </div>
        <div className={classes.dateWrapper}>
        <DatePicker
          disabled={loading ? true:false}
          className={classes.datepicker}
          selected={this.state.endDate}
          minDate={this.state.startDate}
          maxDate={new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()+1)}
          onChange={(e) => {this.setNewDates(this.state.startDate, e)}}
          />
      </div>
      </div>:<Button disabled={loading ? true:false} variant="outlined" className={classes.button} onClick={() => {
        this.setState({showDatePicker:true})
      }}>
        Custom
      </Button>}
      </div>
    )
      if (this.props.reports.loading) {
        reportGraph = (
        <div>
        Crunching the Numbers...
        <Spinner/>
        </div>
      )
    } else if (!errors.mwsAuth && this.state.reqReport){
      const reportData = this.createProfitLossReport(this.state.reqReport);
      reportGraph = (
          <Chart
          width = {"100%"}
          height={'270px'}
          chartType="BarChart"
          loader={<div>Crunching the Numbers...<Spinner/></div>}
          data={reportData}
          options={{
                    fontName:"Quicksand,serif",
                    isStacked: true,
                    legend: {position: 'none'},
                    tooltip:{
                      showColorCode: true,
                      ignoreBounds:true,
                      isHtml:true,
                      text:'value',
                      textStyle:{fontSize:14}
                    },
                    animation:{
                      startup:true,
                      duration: 500,
                      easing: 'out',
                    },
                    chartArea:{width:"60%",height:"75%"},
                    vAxis: { textPosition: 'left'},
                    hAxis:{format: '$#,###.##',
                    gridlines: { count: 30 }},
                    hAxes: {0: {
                              gridlines: {color: '#D3D3D3'},}
                            },
                  }}
          chartPackages={["corechart", "controls"]}
          rootProps={{ 'data-testid': '1' }}
          chartEvents = {
            [{
              eventName: "error",
              callback: (a) => {
                //hide dumb error about gettime being null
                //a.google.visualization.errors.removeError(a.eventArgs[0].id);
              }
            }]
          }
          formatters={[
            {
               type: "NumberFormat",
               column: 1,
               options: {
                  prefix: "$",
                  negativeColor: "red",
                  negativeParens: true,
                }
             }
         ]}
         
        />
      );
    }

    if (!errors.mwsAuth && tableData.length <= 0){
      tableContent = growthContent = (
        <div>
        Crunching the Numbers...
        <Spinner/>
        </div>
      );
    } else {
      growthContent = (
        <div>
        <Chart
        width = {"100%"}
        key = {chartChanger}
        height={'250px'}
        chartType="LineChart"
        loader={<div>Crunching the Numbers...<Spinner/></div>}
        data={growthData}
        options={{
                  fontName:"Quicksand,serif",
                  curveType: 'function',
                  legend:{
                    position:'top'
                  },
                  tooltip:{
                    showColorCode: true,
                    ignoreBounds:true,
                    isHtml:true,
                    text:'value',
                    textStyle:{fontSize:14}
                  },
                  animation:{
                    startup:true,
                    duration: 500,
                    easing: 'out',
                  },
                  explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    axis: 'horizontal',
                    keepInBounds: true,
                    maxZoomIn: 5,
                    maxZoomOut: 8.0
                    },
                  chartArea:{width:"80%",height:"75%"},
                  colors: ['#BEBEBE', '#149cea'],
                  vAxis: { title: 'Profit',viewWindowMode: "explicit", viewWindow:{ min: 0 },titleTextStyle:{italic:false} },
                  vAxes: {0: {
                            gridlines: {color: '#D3D3D3'},title: 'Profit'}
                          },
                  hAxis: { title: 'Day of Month' },
                  seriesType: 'line'
                }}
      chartPackages={["corechart", "controls"]}
        rootProps={{ 'data-testid': '1' }}
        chartEvents = {
          [{
            eventName: "error",
            callback: (a) => {
              //hide dumb error about gettime being null
              a.google.visualization.errors.removeError(a.eventArgs[0].id);
            }
          }]
        }
      />
      Current Growth Rate of <strong>{Number((this.state.mOmGrowth-1)*100).toFixed(3)}%</strong> over last month
      </div>
      );
      tableContent = (
        <div className="table-responsive">
         <table className={classes.table}>
           <thead>
             <tr>
               <th></th>
               <th>Revenue</th>
               <th>Margin</th>
               <th>Profit</th>
               <th>Units</th>
             </tr>
           </thead>
           <tbody >
                {this.state.tableData.map(row => (
                  <tr key={row.id}>
                   <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{row.name}</td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${Number(row.revenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{Number(row.margin).toFixed(2)}%</td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${Number(row.profit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{Number(row.units).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  </tr>
                ))}
           </tbody>
         </table>
         </div>
      );
    }


    if (errors.mwsAuth && pieData.length === 0) {
      pieContent = (
        <div>

        </div>
      );
    } else if (loading || pieData.length === 0){
      pieContent = (
        <div>
        Crunching the Numbers...
        <Spinner/>
        </div>
      );
    } else {
      pieContent = (
        <Chart
          legend= {{position: 'bottom'}}
          width={'100%'}
          height={250}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={pieData}
          options={{
           fontSize:12,
            pieHole: 0.33,
            fontName:"Quicksand,serif",
            //is3D: true,
            legend:{
              position:'bottom',
              textStyle: {fontSize: 13}
            },
            chartArea:{right:0,left:0,top:10
            },
            pieSliceText:'percentage',
            tooltip:{
              showColorCode: true,
              ignoreBounds:true,
              isHtml:true,
              text:'both',
              textStyle:{fontSize:14},
              trigger:'selection'
            }

          }}
          rootProps={{ 'data-testid': '1' }}
          formatters={[
            {
               type: "NumberFormat",
               column: 1,
               options: {
                  prefix: "$",
                }
             }
         ]}
        />
      );
    }
    if (errors.mwsAuth && graphData.length === 0) {
      chartContent = (
        <div>

        </div>
      );
    } else if (loading || graphData.length === 0){
      chartContent =(
        <div>
        Crunching the Numbers...
        <Spinner/>
        </div>
      );
    } else {

      chartContent = (
        <div>

        <Chart
        width = {"100%"}
        key = {chartChanger}
        height={'250px'}
        chartType="ComboChart"
        loader={<div>Crunching the Numbers...<Spinner/></div>}
        data={graphData}
        formatters={[
          {
             type: "NumberFormat",
             column: 1,
             options: {
                prefix: "$",
              }
           },{
            type: "NumberFormat",
            column: 2,
            options: {
               prefix: "$",
             }
          }
       ]}
        options={{
                  fontName:"Quicksand,serif",
                  curveType: 'function',
                  tooltip:{
                    showColorCode: true,
                    //ignoreBounds:true,
                    isHtml:true,
                    text:'value',
                    textStyle:{fontSize:14}
                  },
                  animation:{
                    startup:true,
                    duration: 500,
                    easing: 'out',
                  },
                  explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    axis: 'horizontal',
                    keepInBounds: true,
                    maxZoomIn: 5,
                    maxZoomOut: 8.0
                    },
                  chartArea:{width:"75%",height:"75%"},
                  colors: ['#7DCFB6','#132f4a','#149cea'],
                  vAxis: { title: 'Value',viewWindowMode: "explicit", viewWindow:{ min: 0 },titleTextStyle:{italic:false} },
                  vAxes: {0: {
                            gridlines: {color: 'transparent'},title: 'Value'},
                          1: {
                            gridlines: {color: 'transparent'},title: 'Orders'},
                          },
                  hAxis: { title: 'Date' },
                  seriesType: 'line',
                  series: { 2: { type: 'bars' , targetAxisIndex:1}},
                }}
      chartPackages={["corechart", "controls"]}
        rootProps={{ 'data-testid': '1' }}
        chartEvents = {
          [{
            eventName: "error",
            callback: (a) => {
              //hide dumb error about gettime being null
              a.google.visualization.errors.removeError(a.eventArgs[0].id);
            }
          }]
        }
      />
      </div>
    );
  }
    if(!user.sellerID){
      dashboardContent = (
      <Paper className={classes.root}>
        To view your sales dashboard, you need to authenticate with Amazon in &nbsp;
        <LinkIn className={classes.link} to="/settings">
        Settings
        </LinkIn>
      </Paper>
    );
  } else {
      dashboardContent = (
          <div className={classes.root}>
            <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.topBar}>
              <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography
                  className={classes.title}
                  variant='h6'
                  id='tableTitle'
                  style={{ textAlign: "left", marginLeft: "20px", color:"black" }}
                >
                  Dashboard
                </Typography>
                </Grid><Grid item xs={12} sm={6}>
                    {dateContent}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
         
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
              <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left",marginLeft:"20px"}}>
              Revenue, Expenses & Profit
            </Typography>
              {reportGraph}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
              <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left",marginLeft:"20px"}}>
                  Expense Breakdown
            </Typography>
            <div style={{align:"center",width: "auto", margin: "0 auto !important"}}>
            {pieContent}
            </div>
              
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>

            <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left",marginLeft:"20px"}}>
                    Sales and Profit Graph
              </Typography>
              {chartContent}
            {/* <div style={{align:"center",width: "auto", margin: "0 auto !important"}}>
            {growthContent}
            </div> */}
            </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left",marginLeft:"20px"}}>
                    Sales and Profit Table
              </Typography>
              <div style={{align:"center",width: "auto", margin: "0 auto !important"}}>
              {tableContent}
              </div>
            </Paper>
            </Grid>
          </Grid>
        </div>

      );
}
      return (
        <div className="dashboard">
          {dashboardContent}
        </div>
      );
    }
}

Dash.propTypes = {
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  orders:PropTypes.object.isRequired,
  reports:PropTypes.object.isRequired  
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  orders: state.orders,
  reports: state.reports,
  graphData: state.graphData,
  pieData:state.pieData,
  tableData:state.tableData
});

export default withRouter(connect(mapStateToProps, { getGraphData,profitLossReport, getPieData,getTableData })(withStyles(styles)(Dash)))
