import React, { PureComponent } from 'react';

// MaterialUI
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Components
import CustomModal from './CustomModal';

const styles = () => ({
  info: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  error: {
    color: 'rgba(244, 67, 54, 1)',
  },
  success: {
    color: 'rgba(28, 194, 28, 1)',
  },
});
class ConfirmationModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleOnConfirm = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
    this.props.onClose();
  }

  handleOnCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.onClose();
  }

  render() {
    const {
      open,
      onClose,
      headerText = "Are you sure?",
      confirmLabel,
      cancelLabel,
      confirmationText,
      severity = "info",
      classes,
    } = this.props;

    let messageSeverityStyle = classes.info;
    if (severity === "error") {
      messageSeverityStyle = classes.error;
    } else if (severity === "success") {
      messageSeverityStyle = classes.success;
    }

    return (
      <CustomModal open={open}
        onClose={onClose}
        headerText={headerText}
      >
        {
          confirmationText && (
            <DialogContent>
              <DialogContentText className={messageSeverityStyle}>
                {confirmationText}
              </DialogContentText>
            </DialogContent>
          )
        }
        <DialogActions>
          {
            confirmLabel && (
              <Button onClick={this.handleOnConfirm}>
                {confirmLabel}
              </Button>
            )
          }
          {
            cancelLabel && (
              <Button onClick={this.handleOnCancel}>
                {cancelLabel}
              </Button>
            )
          }
        </DialogActions>
      </CustomModal>
    );
  }
}

export default (withStyles(styles)(ConfirmationModal));
