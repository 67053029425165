import axios from 'axios';
import {
  toast
} from 'react-toastify';

import {
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING_PAGE,
  GET_SHIPMENTS,
  SHIPMENT_PLAN_MADE,
  SHIPMENT_PREP,
  STOP_LOADING_PAGE,
  SHIPMENT_ACCEPTED,
  FNSKU_DOWNLOAD
} from './types';

// get shipment info
export const gettransportcontent = (data, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/gettransportcontent', data)
    .then(res => {
      dispatch(stopLoading());
      dispatch({
        type: SHIPMENT_ACCEPTED,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(stopLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        if (err.response.data && err.response.data.shipments) {
          toast.error(err.response.data.shipments)
        }
      }
    });
};

// get fnsku labels for product
export const downloadFNSKULabels = (data) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/getfnskulabels', data)
    .then(res => {
      dispatch(stopLoading());
      dispatch({
        type: FNSKU_DOWNLOAD,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(stopLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        if (err.response.data && err.response.data.shipments) {
          toast.error(err.response.data.shipments)
        }
      }
    });
};

// get labels for shipment
export const getlabels = (data, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/getlabels', data)
    .then(res => {
      dispatch(stopLoading());
      dispatch({
        type: SHIPMENT_ACCEPTED,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(stopLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        if (err.response.data && err.response.data.shipments) {
          toast.error(err.response.data.shipments)
        }
      }
    });
};

// Post the accpet/void shipping estimate
export const acceptOrVoid = (data, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/acceptorvoid', data)
    .then(res => {
      dispatch(stopLoading());
      dispatch({
        type: SHIPMENT_ACCEPTED,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(stopLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        if (err.response.data && err.response.data.shipments) {
          toast.error(err.response.data.shipments)
        }
      }
    });
};

// Post the shipment accepted
export const submitContent = (data, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/submitcontent', data)
    .then(res => {
      dispatch(stopLoading());
      dispatch({
        type: SHIPMENT_ACCEPTED,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(stopLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        if (err.response.data && err.response.data.shipments) {
          toast.error(err.response.data.shipments)
        }
      }
    });
};

// Post the shipment accepted
export const acceptShipment = (data, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/acceptShipment', data)
    .then(res => {dispatch(stopLoading());
      dispatch({
        type: SHIPMENT_ACCEPTED,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(stopLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        if (err.response.data && err.response.data.shipments) {
          toast.error(err.response.data.shipments)
        }
      }
    });
};

// Post the shipment plan
export const createShipmentPlan = (data, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/createplan', data)
    .then(res => {
      dispatch(stopLoading());
      dispatch({
        type: SHIPMENT_PLAN_MADE,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.response) {
        dispatch(stopLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        if (err.response.data && err.response.data.shipments) {
          toast.error(err.response.data.shipments)
        }
      }
    });
};

// get Sku prep instructions
export const prepInstructions = (data) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/shipments/prepinstructions', data)
    .then(res => {
      dispatch(stopLoading());
      if (!res) {
        dispatch({
          type: GET_ERRORS,
          payload: {
            timeout: true
          }
        })
        return
      }
      dispatch({
        type: SHIPMENT_PREP,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch(stopLoading());
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      }
    });
};

// Get the shipments for the user
export const getShipments = (shipments) => dispatch => {
  if (typeof(shipments) === 'undefined' || shipments.length === 0) {
    dispatch(clearErrors());
    dispatch(setLoading());
    axios
      .get('/api/shipments')
      .then(res => {
        dispatch(stopLoading());
        dispatch({
          type: GET_SHIPMENTS,
          payload: res.data
        })
      })
      .catch(err => {
        dispatch(stopLoading());
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        }
      });
  } else {
    dispatch({
      type: GET_SHIPMENTS,
      payload: shipments
    })

  }
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING_PAGE
  };
};

export const setLoading = () => {
  return {
    type: LOADING_PAGE
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
