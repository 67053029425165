export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_USER_LEADS = 'GET_USER_LEADS';
export const LEADS_LOADING = 'LEADS_LOADING';
export const GET_RESPONSE = 'GET_RESPONSE';
export const LOADING_PAGE = 'LOADING_PAGE';
export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
export const GET_UPDATE_INFO = 'GET_UPDATE_INFO';
export const GET_PIE_DATA = 'GET_PIE_DATA';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const GET_TABLE_DATA = 'GET_TABLE_DATA';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const PAYMENT_MADE = 'PAYMENT_MADE';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_PENDING = 'PAYMENT_PENDING';
export const PURCHASE_PENDING = 'PURCHASE_PENDING';
export const GET_SHIPMENTS = 'GET_SHIPMENTS';
export const SHIPMENT_PLAN_MADE = 'SHIPMENT_PLAN_MADE';
export const SHIPMENT_PREP = 'SHIPMENT_PREP';
export const STOP_LOADING_PAGE = 'STOP_LOADING_PAGE';
export const SHIPMENT_ACCEPTED = 'SHIPMENT_ACCEPTED';
export const GET_REPORT = 'GET_REPORT';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const GET_SUB_INFO ='GET_SUB_INFO';
export const FNSKU_DOWNLOAD ='FNSKU_DOWNLOAD';
export const LOADED_INFO ='LOADED_INFO';
export const LOADING_SUBSCRIPTIONS = 'LOADING_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const ERROR_SUBSCRIPTIONS = 'ERROR_SUBSCRIPTIONS';
export const EMAIL_RESPONSE = 'EMAIL_RESPONSE';
export const GET_ANNOUNCE = 'GET_ANNOUNCE';
export const ANNOUNCE_LOADING = 'ANNOUNCE_LOADING';
export const GET_UNGATING_TYPES = 'GET_UNGATING_TYPES';
export const GET_PUBLIC_UNGATING_TYPES = 'GET_PUBLIC_UNGATING_TYPES';
export const LOADING_UNGATING_TYPE = 'LOADING_UNGATING_TYPE';
export const STOP_LOADING_UNGATING_TYPE = 'STOP_LOADING_UNGATING_TYPE';
export const DELETE_UNGATING_TYPE = 'DELETE_UNGATING_TYPE';
export const UPDATE_UNGATING_TYPE = 'UPDATE_UNGATING_TYPE';
export const CREATE_UNGATING_TYPE = 'CREATE_UNGATING_TYPE';
export const GET_UNGATING_TYPE = 'GET_UNGATING_TYPE';
export const LOADING_UPDATE_UNGATING_TYPE = 'LOADING_UPDATE_UNGATING_TYPE';
export const LOADING_DELETE_UNGATING_TYPE = 'LOADING_DELETE_UNGATING_TYPE';
export const LOADING_CREATE_UNGATING_TYPE = 'LOADING_CREATE_UNGATING_TYPE';
export const LOADING_GET_UNGATING_TYPE = 'LOADING_GET_UNGATING_TYPE';
export const LOADING_GET_PUBLIC_UNGATING_TYPES = 'LOADING_GET_PUBLIC_UNGATING_TYPES';
