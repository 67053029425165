import {
  LOADING_PAGE,
  GET_SHIPMENTS,
  SHIPMENT_PREP,
  SHIPMENT_PLAN_MADE,
  STOP_LOADING_PAGE,
  SHIPMENT_ACCEPTED,
  FNSKU_DOWNLOAD
} from '../actions/types';

const initialState = {
  shipments: [],
  loading: false,
  prep: [],
  shipmentPlans: [],
  acceptedShipment: {},
  fnskuLabels:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case FNSKU_DOWNLOAD:
      return {
        ...state,
        loading: false,
        fnskuLabels: action.payload
      }
    case SHIPMENT_ACCEPTED:
      return {
        ...state,
        loading: false,
        acceptedShipment: action.payload
      }
    case STOP_LOADING_PAGE:
      return {
        ...state,
        loading: false,
        pending: false
      }
    case LOADING_PAGE:
      return {
        ...state,
        loading: true
      }
    case GET_SHIPMENTS:
      return {
        ...state,
        loading: false,
        shipments: action.payload
      }
    case SHIPMENT_PREP:
      return {
        ...state,
        loading: false,
        prep: action.payload
      }
    case SHIPMENT_PLAN_MADE:
      return {
        ...state,
        loading: false,
        shipmentPlans: action.payload
      }
    default:
      return state;
  }
}
