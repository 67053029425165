import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link as Inlink } from "react-router-dom";
import {
  authToAmz,
  setNewPassword,
  setNewEmail
} from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Link, Paper, Typography, Button } from "@material-ui/core";
import logo from "../common/logo.png";
import StripeCheckout from "react-stripe-checkout";
import { changePayment } from "../../actions/authActions";
import FormDialog from "./Modal";
import HelpModal from "./HelpModal";
import PurchaseModal from "./PurchaseModal";
import WhyDataModal from "./WhyDataModal";
import Invoices from "./Invoices";
import ChangeCard from "./ChangeCard";

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    //textAlign: 'center',3
    color: theme.palette.text.secondary,
    minHeight: "332px"
    //maxHeight: "332px"
  },
  button: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "2px",
    margin: "3px",
    backgroundColor: "#149cea",
    "&:hover": {
      color: "#132f4a"
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white",
    "&:hover": {
      color: "white"
    }
  }
});

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      sellerID: "",
      authtoken: "",
      marketplace: "ATVPDKIKX0DER",
      auth: "",
      currpassword: "",
      newpassword: "",
      newpassword2: "",
      password: "",
      email: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPassSubmit = this.onPassSubmit.bind(this);
    this.onEmailSubmit = this.onEmailSubmit.bind(this);
    this.onToken = this.onToken.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        auth: this.props.auth,
        currpassword: "",
        newpassword: "",
        newpassword2: "",
        password: "",
        email: ""
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onPassSubmit(e) {
    e.preventDefault();

    const newPassword = {
      currpassword: this.state.currpassword,
      newpassword: this.state.newpassword,
      newpassword2: this.state.newpassword2
    };

    this.props.setNewPassword(newPassword, this.props.history);
  }

  onEmailSubmit(e) {
    e.preventDefault();

    const newEmail = {
      password: this.state.password,
      email: this.state.email.toLowerCase()
    };

    this.props.setNewEmail(newEmail, this.props.history);
  }

  onSubmit(e) {
    e.preventDefault();
    const amzAuthData = {
      sellerID: this.state.sellerID,
      authtoken: this.state.authtoken,
      marketplace: this.state.marketplace
    };
    this.props.authToAmz(amzAuthData, this.props.history);
  }

  onToken(token, addresses) {
    const data = {
      token
    };
    this.props.changePayment(data);
  }

  render() {
    const { classes } = this.props;
    const { user } = this.props.auth;
    const errors = this.props.errors;
    let settingContent;
    if (user.sellerID) {
      settingContent = (
        <Paper className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            style={{ textAlign: "left" }}
          >
            Amazon Credentials
          </Typography>
          <p>
            You have authorized Atlas with your Amazon Seller Account! If you
            need to reconnect, you can enter your information again and submit.
          </p>
          <form style={{ position: "relative" }} onSubmit={this.onSubmit}>
            <TextFieldGroup
              // disabled={true}
              placeholder={
                "Seller ID: " + user.sellerID.slice(0, 3) + "**********"
              }
              name="sellerID"
              type="sellerID"
              value={this.state.sellerID}
              onChange={this.handleInputChange}
              error={errors.sellerID}
            />
            <TextFieldGroup
              // disabled={true}
              placeholder="MWS Auth Token: amzn.mws.***********************************"
              name="authtoken"
              type="authtoken"
              value={this.state.authtoken}
              onChange={this.handleInputChange}
              error={errors.authtoken}
            />
            <div style={{ color: "red" }}>
              {errors.mwsAuth ? errors.mwsAuth : null}
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Submit
            </Button>
              <WhyDataModal />
              <HelpModal />
          </form>
        </Paper>
      );
    } else {
      settingContent = (
        <Paper className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            style={{ textAlign: "left" }}
          >
            Amazon Credentials
          </Typography>
          <p style={{ color: "black" }}>
            Please authorize Atlas with your Amazon Seller Account by&nbsp;
            <Link
              style={{ color: "#149cea", textDecoration: "underline" }}
              rel="noreferrer"
              target="_blank"
              href={
                "https://sellercentral.amazon.com/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Atlas%20Labs&devMWSAccountId=4852-6316-1689"
              }
            >
              clicking here.
            </Link>
          </p>
          <form style={{ position: "relative" }} onSubmit={this.onSubmit}>
            <TextFieldGroup
              placeholder="Seller ID"
              name="sellerID"
              type="sellerID"
              value={this.state.sellerID}
              onChange={this.handleInputChange}
              error={errors.sellerID}
            />
            <TextFieldGroup
              placeholder="MWS Auth Token"
              name="authtoken"
              type="authtoken"
              value={this.state.authtoken}
              onChange={this.handleInputChange}
              error={errors.authtoken}
            />
            <div style={{ color: "red" }}>
              {errors.mwsAuth ? errors.mwsAuth : null}
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Submit
            </Button>
              <WhyDataModal /> 
              <HelpModal />
          </form>
        </Paper>
      );
    }
    return (
      <div className="settings">
        <div className="container">
          <div className={classes.root}>
            <Grid container spacing={1} style={{display:'flex', justifyContent:'center'}}>
              {/* <Grid item xs>
                {settingContent}
              </Grid> */}
              <Grid item xs={6} >
                <Paper className={classes.paper}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    style={{ textAlign: "left" }}
                  >
                    Account Info
                  </Typography>
                  <br />
                  <PurchaseModal auth={this.props.auth} errors={errors} />
                  <div style={{ textAlign: "center" }}>
                    <ChangeCard auth={this.props.auth} />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Inlink className="link" to="/affiliate">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={{ width: "100%" }}
                      >
                        Affiliate Dashboard
                      </Button>
                    </Inlink>
                    <Invoices auth={this.props.auth} />

                    <FormDialog user={user} />
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    style={{ textAlign: "left" }}
                  >
                    Change Your Email
                  </Typography>
                  <br />
                  <TextFieldGroup
                    placeholder="New Email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    error={errors.email}
                  />
                  <form onSubmit={this.onEmailSubmit}>
                    <TextFieldGroup
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      error={errors.password}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Submit
                    </Button>
                    <br />
                    <br />
                  </form>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    style={{ textAlign: "left" }}
                  >
                    Change Your Password
                  </Typography>
                  <br />
                  <form onSubmit={this.onPassSubmit}>
                    <TextFieldGroup
                      placeholder="Current Password"
                      name="currpassword"
                      type="password"
                      value={this.state.currpassword}
                      onChange={this.handleInputChange}
                      error={errors.currpassword}
                    />
                    <TextFieldGroup
                      placeholder="New Password"
                      name="newpassword"
                      type="password"
                      value={this.state.newpassword}
                      onChange={this.handleInputChange}
                      error={errors.newpassword}
                    />
                    <TextFieldGroup
                      placeholder="Confirm New Password"
                      name="newpassword2"
                      type="password"
                      value={this.state.newpassword2}
                      onChange={this.handleInputChange}
                      error={errors.newpassword2}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Submit
                    </Button>
                    <br />
                    <br />
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(
  connect(mapStateToProps, {
    authToAmz,
    setNewPassword,
    setNewEmail,
    changePayment
  })(withStyles(styles)(Settings))
);
