import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../common/Spinner";
import {
  getInventory,
  postBuyPrice,
  postBulkBuy,
} from "../../actions/inventoryActions";
import {} from "../../actions/inventoryActions";
import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  Menu,
  Switch,
  Table,
  TableBody,
  TableCell,
  Button,
  TableHead,
  InputAdornment,
  TablePagination,
  TextField,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Link,
  Tooltip,
} from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import BuyCosts from "./BuyCosts";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link as LinkIn } from "react-router-dom";

let counter = 0;
function createData(
  status,
  imageUrl,
  title,
  asin,
  sku,
  fnsku,
  stock,
  sellPrice,
  buyPrice,
  salesTaxCost,
  shippingFee,
  miscFee,
  notes,
  dateCreated,
  supplier,
  buyLink
) {
  counter += 1;
  return {
    id: counter,
    status,
    imageUrl,
    title,
    asin,
    sku,
    fnsku,
    stock,
    sellPrice,
    buyPrice,
    salesTaxCost,
    shippingFee,
    miscFee,
    notes,
    dateCreated,
    supplier,
    buyLink,
  };
}

function desc(a, b, orderBy) {
  let compA = a[orderBy];
  let compB = b[orderBy];
  if (compA !== null && compB !== null && !isNaN(compA) && !isNaN(compB)) {
    compA = parseFloat(compA.toString().replace(/\$|,/g, ""));
    compB = parseFloat(compB.toString().replace(/\$|,/g, ""));
    if (compB < compA) {
      return -1;
    }
    if (compB > compA) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "status", disablePadding: false, label: "Status" },
  { id: "imageUrl", disablePadding: false, label: "Image" },
  { id: "title", disablePadding: false, label: "Title" },
  { id: "identifiers", disablePadding: false, label: "Identifiers" },
  { id: "stock", disablePadding: false, label: "Stock" },
  { id: "sellPrice", disablePadding: false, label: "Price" },
  { id: "buyPrice", disablePadding: false, label: "Purchase Costs" },
  { id: "dateCreated", disablePadding: false, label: "Date Created" },
  { id: "tools", disablePadding: false, label: "Purchase Instructions" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } =
      this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={"center"}
                padding={"none"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={
                    row.id === "imageUrl" ||
                    row.id === "buyPrice" ||
                    row.id === "identifiers" ||
                    row.id === "tools"
                      ? row.label
                      : "Sort by " + row.label
                  }
                  placement={"bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    hideSortIcon={
                      row.id === "imageUrl" ||
                      row.id === "buyPrice" ||
                      row.id === "identifiers" ||
                      row.id === "tools"
                        ? true
                        : false
                    }
                    active={orderBy === row.id}
                    direction={order}
                    onClick={
                      row.id === "imageUrl" ||
                      row.id === "buyPrice" ||
                      row.id === "identifiers" ||
                      row.id === "tools"
                        ? null
                        : this.createSortHandler(row.id)
                    }
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    fontFamily: "Quicksand,serif",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
    aligh: "center",
  },
  search: {
    display: "inline",
    marginLeft: "auto",
    marginRight: "20px",
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    value,
    createShipment,
    handleSearch,
    rowCount,
    postBulkBuy,
    checkedA,
    handleChange,
    anchorEl,
    handleClose,
    handleDropDown,
  } = props;
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle'>
        Inventory ({numSelected > 0 ? numSelected : rowCount})
      </Typography>
      {numSelected > 0 ? (
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          style={{
            backgroundColor: "#149cea",
            "&:hover": {
              color: "#132f4a",
            },
            color: "white",
            "&:hover": {
              color: "white",
            },
            textTransform: "none",
            marginLeft: "10px",
            display: "inline",
            whiteSpace: "wrap",
            overflow: "hidden",
          }}
          value={"createShipment"}
          onClick={(e) => createShipment(e)}
        >
          Create Shipment
        </Button>
      ) : null}
      <BuyCosts postBulkBuy={postBulkBuy} />
      <Switch
        checked={checkedA}
        onChange={handleChange("checkedA")}
        value='checkedA'
      />
      <Tooltip
        title={"Shows Inventory Info for Deleted SKUs"}
        placement={"bottom-start"}
        enterDelay={300}
      >
        <div>
          Archived Inventory <i className='far fa-question-circle'></i>
        </div>
      </Tooltip>

      <div align='right' className={classes.search}>
        <TextField placeholder='Search' onChange={handleSearch} value={value} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  date: {
    width: "90%",
    maxWidth: "75px",
    whiteSpace: "wrap",
  },
  tableRow: {
    minHeight: "100px",
    height: "100px",
    maxHeight: "100px",
  },
  cell: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "150px",
    overflow: "hidden",
    padding: "2px",
    minHeight: "100px",
    height: "100px",
    maxHeight: "100px",
  },
  table: {
    tableLayout: "auto",
  },
  textField: {
    margin: "2px",
    width: "75%",
    fontSize: "13px",
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 13,
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
  button: {
    backgroundColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
    },
    color: "white",
    "&:hover": {
      color: "white",
    },
    textTransform: "none",
    margin: "1px",
    display: "inline",
    whiteSpace: "wrap",
    overflow: "hidden",
    padding: 1,
    //width: "130px"
  },
});

class Inventory extends React.Component {
  state = {
    order: "desc",
    orderBy: "dateCreated",
    selected: [],
    inventory: [],
    filteredInventory: [],
    pureInventory: [],
    page: 0,
    rowsPerPage: 50,
    query: "none",
    checkedA: false,
    anchorEl: null,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({
      order,
      orderBy,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({
        selected: state.pureInventory.map((n) => n.id),
      }));
      return;
    }
    this.setState({
      selected: [],
    });
  };

  handleKeyDown = (event, id) => {
    this.handleClick(event, id);
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selected: newSelected,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChange = (name) => (event) => {
    if (name === "checkedA") {
      counter = 0;
      this.setState({ selected: [] });
      this.setState({ [name]: event.target.checked }, () => {
        this.sortInventory();
      });
    } else {
      this.setState({ [name]: event.target.checked });
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleSearch = (event) => {
    let input, filter, a, i, txtValue, filteredData;
    input = event.target.value;
    filter = event.target.value.toUpperCase();
    filteredData = [];
    const { pureInventory } = this.state;
    for (i = 0; i < pureInventory.length; i++) {
      a = pureInventory[i];
      txtValue = JSON.stringify(a);
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        filteredData.push(pureInventory[i]);
      }
    }
    this.setState({
      filteredInventory: filteredData,
      query: input,
    });
  };

  getInventory = (inv) => {
    this.props.getInventory(inv);
  };

  componentDidMount = () => {
    counter = 0;
    if (
      this.props.auth &&
      this.props.auth.user &&
      this.props.auth.user.sellerID &&
      this.props.auth.user.sellerID !== null
    ) {
      this.getInventory(this.props.inventory.inventory);
    }
  };

  sendBuyPrice = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    let buyPrice = 0;
    let salesTaxCost = 0;
    let shippingFee = 0;
    let miscFee = 0;
    if (!isNaN(data.get("buyPrice"))) {
      buyPrice = data.get("buyPrice");
    }
    if (!isNaN(data.get("salesTaxCost"))) {
      salesTaxCost = data.get("salesTaxCost");
    }
    if (!isNaN(data.get("shippingFee"))) {
      shippingFee = data.get("shippingFee");
    }
    if (data.get("miscFee")) {
      miscFee = data.get("miscFee");
    }

    let postData = {
      sku: data.get("sku"),
      buyPrice,
      salesTaxCost,
      shippingFee,
      miscFee,
    };
    this.props.postBuyPrice(postData);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      if (this.props.errors.bulkbuy) {
        toast.error(this.props.errors.bulkbuy);
      }
      this.setState({
        errors: this.props.errors,
      });
    }
    if (prevProps.inventory !== this.props.inventory) {
      this.sortInventory();
    }
  }

  sortInventory() {
    let inv = [];
    let aInv = [];
    for (let i = 0; i < this.props.inventory.inventory.length; i++) {
      let tempinv = this.props.inventory.inventory;
      let inventoryStock = 0;
      let sellingPrice;
      let buyPrice;
      if (this.props.inventory.inventory[i].buyPrice) {
        buyPrice = tempinv[i].buyPrice;
      } else buyPrice = 0;
      if (
        this.props.inventory.inventory[i].pricing &&
        this.props.inventory.inventory[i].pricing.mine &&
        this.props.inventory.inventory[i].pricing.mine.price
      ) {
        if (Number(tempinv[i].pricing.mine.price) === "NaN") sellingPrice = 0;
        else sellingPrice = Number(tempinv[i].pricing.mine.price);
      } else sellingPrice = 0;
      buyPrice = buyPrice.toFixed(2);
      sellingPrice = sellingPrice.toFixed(2);
      let displayDate =
        "" +
        new Date(tempinv[i].dateCreated).getUTCFullYear() +
        "/" +
        ("0" + (new Date(tempinv[i].dateCreated).getUTCMonth() + 1)).slice(-2) +
        "/" +
        ("0" + new Date(tempinv[i].dateCreated).getUTCDate()).slice(-2) +
        "\n" +
        new Date(tempinv[i].dateCreated).toUTCString().slice(17, 17 + 9);
      if (tempinv[i].availability)
        inventoryStock = tempinv[i].availability.availible;
      if (tempinv[i].status !== "Archived")
        inv.push(
          createData(
            tempinv[i].status,
            tempinv[i].imageUrl,
            tempinv[i].title,
            tempinv[i].asin,
            tempinv[i].sku,
            tempinv[i].fnsku,
            inventoryStock,
            sellingPrice,
            buyPrice,
            tempinv[i].salesTaxCost,
            tempinv[i].shippingFee,
            tempinv[i].miscFee,
            tempinv[i].notes,
            displayDate,
            tempinv[i].supplier,
            tempinv[i].buyLink
          )
        );
      else
        aInv.push(
          createData(
            tempinv[i].status,
            tempinv[i].imageUrl,
            tempinv[i].title,
            tempinv[i].asin,
            tempinv[i].sku,
            tempinv[i].fnsku,
            inventoryStock,
            sellingPrice,
            buyPrice,
            tempinv[i].salesTaxCost,
            tempinv[i].shippingFee,
            tempinv[i].miscFee,
            tempinv[i].notes,
            null,
            tempinv[i].supplier,
            tempinv[i].buyLink
          )
        );
    }
    this.setState({
      inventory: this.props.inventory.inventory,
      pureInventory: this.state.checkedA ? aInv : inv,
      filteredInventory: this.state.checkedA ? aInv : inv,
    });
  }

  handleDropDown = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  createShipment = () => {
    let skuList = [];
    for (let i = 0; i < this.state.selected.length; i++) {
      if (this.state.inventory[this.state.selected[i] - 1])
        skuList.push({
          fnsku: this.state.inventory[this.state.selected[i] - 1].fnsku,
          sku: this.state.inventory[this.state.selected[i] - 1].sku,
          title: this.state.inventory[this.state.selected[i] - 1].title,
          units: "",
          caseNumb: "",
        });
    }
    this.props.history.push({
      pathname: "/shipments/new/1",
      state: {
        skuList: skuList,
      },
    });
  };
  render() {
    const { classes } = this.props;
    const { user } = this.props.auth;
    const {
      inventory,
      filteredInventory,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      anchorEl,
    } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, inventory.length - page * rowsPerPage);
    //const errors = this.props.errors;
    const { loading } = this.props.inventory;
    let inventoryContent;
    if (!user.sellerID) {
      inventoryContent = (
        <Paper className={classes.root}>
          To view your inventory, you need to authenticate with Amazon in &nbsp;
          <LinkIn className={classes.link} to='/settings'>
            Settings
          </LinkIn>
        </Paper>
      );
    } else if (
      inventory === [] ||
      loading ||
      this.props.inventory.inventory === []
    ) {
      inventoryContent = (
        <Paper className={classes.root}>
          Looking for Inventory...
          <Spinner />
        </Paper>
      );
    } else {
      inventoryContent = (
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            createShipment={this.createShipment}
            handleDropDown={this.handleDropDown}
            handleClose={this.handleClose}
            anchorEl={anchorEl}
            postBulkBuy={this.props.postBulkBuy}
            numSelected={selected.length}
            handleSearch={this.handleSearch}
            value={this.searchValue}
            checkedA={this.state.checkedA}
            handleChange={this.handleChange}
            rowCount={filteredInventory.length}
          />

          <div
            className={classes.tableWrapper}
            style={{ height: "calc(60vh)" }}
          >
            <Table className={classes.table} aria-labelledby='tableTitle'>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={filteredInventory.length}
                className={classes.cell}
              />
              <TableBody>
                {stableSort(
                  this.state.filteredInventory,
                  getSorting(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        role='checkbox'
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id + n.asin}
                        selected={isSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            onClick={(event) => this.handleClick(event, n.id)}
                            checked={isSelected}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          {n.status}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          <img
                            src={n.imageUrl}
                            id='invimg'
                            alt='amz-pic'
                            width='75'
                            height='75'
                          />
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          {n.title ? n.title.slice(0, 25) : ""}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          ASIN:{" "}
                          <Link
                            className={classes.link}
                            rel='noreferrer'
                            target='_blank'
                            href={"https://www.amazon.com/gp/product/" + n.asin}
                          >
                            {n.asin}
                          </Link>
                          <br />
                          SKU: {n.sku}
                          <br />
                          FNSKU: {n.fnsku ? n.fnsku : "N/A"}
                          <br />
                          Supplier: {n.supplier ? n.supplier : "N/A"}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          {n.stock}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          <Tooltip
                            title={inventory.map((e, i) => {
                              let fees = { ReferralFee: 0, FBAFees: 0 };
                              let buyPrice = Number(0).toFixed(2);
                              let salesTaxCost = 0;
                              let shippingFee = 0;
                              let miscFee = 0;
                              let profit = Number(0).toFixed(2);
                              if (e.sku === n.sku) {
                                if (e.buyPrice && !isNaN(e.buyPrice))
                                  buyPrice = Number(e.buyPrice).toFixed(2);

                                if (e.salesTaxCost && !isNaN(e.salesTaxCost))
                                salesTaxCost = Number(e.salesTaxCost).toFixed(2);
                                if (e.shippingFee && !isNaN(e.shippingFee))
                                  shippingFee = Number(e.shippingFee).toFixed(
                                    2
                                  );
                                if (e.miscFee && !isNaN(e.miscFee))
                                  miscFee = Number(e.miscFee).toFixed(2);

                                if (e.fees) fees = e.fees;
                                profit =
                                  n.sellPrice -
                                  buyPrice -
                                  shippingFee -
                                  salesTaxCost -
                                  miscFee +
                                  fees.ReferralFee +
                                  fees.FBAFees;
                                return (
                                  <div key={e + i}>
                                    Sell Price: {n.sellPrice}
                                    <br />
                                    Buy Price: {buyPrice}
                                    <br />
                                    Sales Tax: {salesTaxCost}
                                    <br />
                                    Shipping Cost: {shippingFee}
                                    <br />
                                    Prep / Misc Fee: {miscFee}
                                    <br />
                                    Estimated Referral Fee: {fees.ReferralFee}
                                    <br />
                                    Estimated FBA Fee: {fees.FBAFees}
                                    <br />
                                    <strong>
                                      Estimated Profit: {profit.toFixed(2)}
                                    </strong>
                                  </div>
                                );
                              } else return null;
                            })}
                            placement={"bottom-start"}
                            enterDelay={100}
                            disableFocusListener
                            classes={{
                              tooltip: classes.lightTooltip,
                              popper: classes.arrowPopper,
                            }}
                          >
                            <span>${n.sellPrice}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='left'
                        >
                          <span>
                            <form
                              style={{ display: "inline" }}
                              noValidate
                              autoComplete='off'
                              onSubmit={this.sendBuyPrice}
                            >
                              <input
                                type='hidden'
                                id='sku'
                                name='sku'
                                value={n.sku}
                              />

                              <TextField
                                label='Base Price (per item)'
                                sku={n.sku}
                                className={classes.textField}
                                name='buyPrice'
                                defaultValue={n.buyPrice}
                                margin='normal'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                label='Sales Tax (per item)'
                                sku={n.sku}
                                className={classes.textField}
                                name='salesTaxCost'
                                defaultValue={n.salesTaxCost ? n.salesTaxCost.toFixed(2) : 0}
                                margin='normal'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                label='Shipping Fee (per item)'
                                sku={n.sku}
                                className={classes.textField}
                                name='shippingFee'
                                defaultValue={n.shippingFee ? n.shippingFee.toFixed(2) : 0}
                                margin='normal'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                label='Prepping / Bundling / Misc.'
                                sku={n.sku}
                                className={classes.textField}
                                name='miscFee'
                                defaultValue={n.miscFee ? n.miscFee.toFixed(2) : 0}
                                margin='normal'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                className={classes.button}
                              >
                                Save
                              </Button>
                            </form>
                            {n.buyLink ? (
                              <Button
                                onClick={() => {
                                  window.open(n.buyLink);
                                }}
                                variant='contained'
                                color='primary'
                                className={classes.button}
                              >
                                Buy <i className='fas fa-external-link-alt' />
                              </Button>
                            ) : null}
                          </span>
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          {n.dateCreated ? n.dateCreated.slice(0, 11) : null}
                          <br />
                          {n.dateCreated ? n.dateCreated.slice(11, 20) : null}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          padding='none'
                          align='center'
                        >
                          {n.notes}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 0 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component='div'
            count={inventory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      );
    }
    return <div>{inventoryContent}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  inventory: state.inventory,
});

Inventory.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, { getInventory, postBuyPrice, postBulkBuy })(
    withStyles(styles)(Inventory)
  )
);
