import axios from 'axios';

import {
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING_PAGE,
  GET_INVENTORY,
  STOP_LOADING_PAGE,
  GET_RESPONSE
} from './types';
import {
  toast
} from 'react-toastify';

// Get the inventory for the user
export const getInventory = (inv) => dispatch => {
  if (typeof(inv) === 'undefined' || inv.length === 0) {
    dispatch(clearErrors());
    axios
      .get('/api/inventory')
      .then(res =>
        dispatch({
          type: GET_INVENTORY,
          payload: res.data
        })
      )
      .catch(err => {
        if (err.response)
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
      });
  } else {
    dispatch({
      type: GET_INVENTORY,
      payload: inv
    })

  }
};

export const postBuyPrice = (postData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post('/api/inventory/singlebuy', postData)
    .then(res => {
      toast.success("Saved!")
      dispatch({
        type: GET_INVENTORY,
        payload: res.data
      })}
    )
    .catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
    });
};

export const postBulkBuy = (postData, history) => dispatch => {
  dispatch(clearErrors());
  console.log('posted:', postData)
  axios
    .post('/api/inventory/bulkpricing', postData)
    .then(res =>
      dispatch({
        type: GET_INVENTORY,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
    });
};

export const addToInventory = (postData, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/inventory/addToInventory', postData)
    .then(res =>
      dispatch({
        type: GET_RESPONSE,
        payload: res.data
      })
    ).catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
    });
};

export const checkFeed = (postData, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .post('/api/inventory/checkFeed', postData)
    .then(res =>{
      dispatch({
        type: GET_RESPONSE,
        payload: res.data
      })}
    )
    .catch(err => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
    });
};

export const setLoading = () => {
  return {
    type: LOADING_PAGE
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING_PAGE
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
