import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { createLead, submitNewPayment } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import { withStyles } from "@material-ui/core/styles";
import * as qs from "query-string";
import {
  Grid,
  Paper,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { StripeProvider } from "react-stripe-elements";
import { Elements } from "react-stripe-elements";
import { injectStripe } from "react-stripe-elements";
import { CardElement } from "react-stripe-elements";
import ReactCardFlip from "react-card-flip";
import { Link } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 768,
      lg: 900,
      xl: 1200,
    },
  },
});

const listOptions = ["Ungated", "Replenishables", "Toys", "Only Software"];

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    flexGrow: 1,
  },
  paper: {
    minHeight: "400px",
    height: "520px",
    minHeight: "1px",
    maxHeight: "1000px",
    // [theme.breakpoints.up(768)]: {

    //   //maxHeight:'383px',
    // },

    position: "relative",
    padding: theme.spacing.unit * 2,
    // color: theme.palette.text.secondary,
    border: "1px solid rgba(9,9,9,.1)",
    borderRadius: "4px",
    boxShadow: "none",
  },
  button: {
    width: "100%",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "10px",
    margin: "0px",
    backgroundColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "#919EAA",
    },
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
  planButton: {
    position: "absolute",
    right: "16px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "3px",
    color: "#149cea",
    borderColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
      borderColor: "#132f4a",
    },
    "&:disabled": {
      color: "grey",
      backgroundColor: "#919EAA",
    },
    "&:hover": {
      color: "#132f4a",
    },
  },
  headline: {
    fontFamily: "Raleway",
    fontSize: "16px",
    textAlign: "left",
  },
  link: {
    textDecoration: "underline",
    "&:hover": {
      color: "#132f4a",
    },
    marginTop: "15px",
  },
});

class _CardForm extends React.Component {
  constructor() {
    super();
    this.state = {
      terms: false,
      termError: "",
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    if (!this.state.terms) {
      this.setState({
        termError: "Please accept the Terms & Conditions",
      });
      return 0;
    }
    if (this.props.cardholder.length < 1 || this.props.email.length < 1) {
      this.setState({ termError: "Please add name and email" });
      return 0;
    }
    this.setState({ termError: "" });
    this.props.stripe
      .createToken({
        name: this.props.cardholder,
        email: this.props.email.toLowerCase(),
      })
      .then((payload) => {
        this.props.submitNewPayment(payload);
      });
  };

  render() {
    const { errors, pending } = this.props;
    const { termError } = this.state;
    return (
      <>
        <div style={{ width: "100%", paddingBottom: "16px" }}>
          <small>Card Details</small>
          <CardElement
            disabled={pending}
            className="form-control"
            {...this.props}
            style={{
              base: {
                fontFamily: "Quicksand",
                fontSize: "17px",
                lineHeight: "1.429",
              },
            }}
          />
        </div>
        {errors.card || errors.payment ? (
          <small className="text-danger">
            {errors.card} {errors.payment}
          </small>
        ) : null}
        {termError !== "" ? (
          <small className="text-danger">{termError}</small>
        ) : null}
        <div>
          <Checkbox
            checked={this.state.terms}
            onChange={this.onChange}
            name="terms"
            color="primary"
            inputProps={{
              "aria-label": "terms checkbox",
            }}
            style={{
              color: "#029cea",
            }}
            id="terms_checkbox"
          />{" "}
          <label htmlFor="terms_checkbox">
            Accept{" "}
            <a
              target="_blank"
              style={{
                textDecoration: "underline",
                "&:hover": {
                  color: "#132f4a",
                },
              }}
              href="https://www.go-atlas.io/atlas-terms-of-service/"
            >
              Terms & Conditions
            </a>
          </label>
        </div>
        <div className="wp-button-container">
          <button
            type="button"
            disabled={pending}
            onClick={this.handleSubmit}
            className="wp-style-button primary"
          >
            Confirm Order
          </button>
        </div>
      </>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class Checkout extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      cardholder: "",
      email: "",
      list: "Ungated",
      plan: "monthly",
      password: "",
      emailGiven: false,
      errors: {},
      stripe: null,
      isFlipped: false,
      anchorEl: null,
      promoCode: "",
      goodPromo: false,
      promoError: "",
      aaid: "",
      couponCode: "",
      couponValidityStatus: null,
      discountPercent: 100,
    };

    this.onChange = this.onChange.bind(this);
  }

  getCookie = (cname, parsed) => {
    var name = cname + "=";
    console.log(
      "----------------------cookies from iFrame------------------",
      document.cookie
    );
    var decodedCookie = parsed;
    if (decodedCookie) {
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  };

  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  componentDidMount() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (window.Stripe)
        this.setState({
          stripe: window.Stripe("pk_test_yTxtNK50fnYQ20mawyqVWY2y"),
        });
    } else {
      if (window.Stripe)
        this.setState({
          stripe: window.Stripe("pk_live_aszZgWDTsAm84X8YDj7N1H0i"),
        });
    }
    //this.setCookie('AAID','testaffid',3) //erase cookie
    const parsed = qs.parse(this.props.location.search);
    let { email, firstName, lastName, list, plan, emailGiven } = this.state;
    if (parsed.email) {
      email = parsed.email.toLowerCase();
      emailGiven = true;
    }
    if (parsed.firstName) firstName = parsed.firstName;
    if (parsed.lastName) lastName = parsed.lastName;
    if (parsed.list) list = parsed.list;
    if (parsed.plan) plan = parsed.plan;
    console.log("------------------parsed------------------", parsed);
    const aaid = this.getCookie("AAID", parsed.cookies);
    this.setState({
      email,
      firstName,
      lastName,
      list,
      plan,
      emailGiven,
      aaid,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
      if (this.props.errors.email || this.props.errors.password)
        this.setState({
          isFlipped: false,
        });
    }
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.data && this.props.auth.data.flip) {
        if (!this.state.isFlipped) this.setState({ isFlipped: true });
        window.fbq("track", "Lead"); //facebook pixel lead
      }
    }
  }

  onChange(e) {
    if (e.target.name === "promoCode") {
      this.setState({ [e.target.name]: e.target.value }, () => {
        this.checkPromoCode();
      });
    } else if (e.target.name === "couponCode") {
      if (this.state.couponValidityStatus == null) {
        this.setState({ [e.target.name]: e.target.value });
      } else {
        this.setState({
          couponCode: "",
          couponValidityStatus: null,
          discountPercent: 0,
        });
      }
    } else this.setState({ [e.target.name]: e.target.value });
  }

  checkPromoCode = () => {
    let goodPromo = false;
    let promoError = "";
    if (this.state.promoCode.toLowerCase() === "q4deal") {
      if (
        this.state.plan === "biannual" &&
        this.state.list !== "Only Software"
      ) {
        goodPromo = "Valid Code!";
      } else if (this.state.plan !== "biannual") {
        promoError = "That code is only valid for biannual payments on lists!";
      }
    }
    this.setState({ goodPromo, promoError });
  };

  onSubmit = (e) => {
    e.preventDefault();
    //step 1 - user Information
    const newUser = {
      // name: `${this.state.firstName} ${this.state.lastName}`,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      password2: this.state.password,
      aaid: this.state.aaid,
    };
    this.props.createLead(newUser, this.props.history);
    //this.setState({isFlipped:true});
  };

  handleCouponValidation = async () => {
    try {
      const response = await axios.get(
        `/api/users/coupon?coupon=${this.state.couponCode}`
      );
      if (!response?.data?.success) {
        this.setState({ couponValidityStatus: false });
      } else if (!response?.data?.result?.valid) {
        this.setState({ couponValidityStatus: false });
      } else if (response?.data?.success && response?.data?.result?.valid) {
        this.setState({
          couponValidityStatus: true,
          discountPercent: response.data.result.percent_off,
        });
      }
      console.log("response from coupon validation api", response);
    } catch (error) {
      console.log("error message", error.message);
      this.setState({ couponValidityStatus: false });
    }
  };

  submitNewPayment = async (token) => {
    //step 2 payment Information
    if (
      this.state.couponValidityStatus === null &&
      this.state.couponCode !== ""
    ) {
      await this.handleCouponValidation();
    }
    let errors = {};
    if (token.error) {
      errors.card = token.error.message;
    }
    this.setState({ errors });
    if (Object.keys(errors).length !== 0) return;

    const newUser = {
      name: `${this.state.firstName} ${this.state.lastName}`,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      password2: this.state.password,
      flavor: this.state.list,
      plan: this.state.plan,
      token: token.token,
      promoCode: this.state.promoCode,
      couponCode: this.state.couponCode,
      aaid: this.state.aaid,
    };

    if (!this.state.couponValidityStatus) {
      delete newUser.couponCode;
    }
    this.props.submitNewPayment(newUser, this.props.history);
  };

  changeList = (e, i) => {
    if (listOptions[i] === "Only Software")
      this.setState({ plan: "monthly" }, () => {
        this.checkPromoCode();
      });
    this.setState({ anchorEl: null, list: listOptions[i] });
  };

  handleOptionChange = (plan) => {
    this.setState({
      plan,
    });
  };

  render() {
    const { errors, stripe, list, plan, anchorEl, cardholder, email } =
      this.state;
    const { classes, auth } = this.props;
    const { pending } = auth;
    let listBenefits = [];
    let cost = {};
    cost.monthly = 159;
    cost.biannual = 115 * 6;
    cost.weekly = 39;
    if (this.state.goodPromo) cost.biannual = 89 * 6;
    if (list.startsWith("Ungated")) {
      listBenefits = [
        "100% ungated leads",
        "25 leads / week",
        "Maximum 9 subscribers / list",
        // "25% discount w/ prep partners",
        // "Financial dashboard & tools",
      ];
      cost.monthly = 105;
      cost.biannual = 75 * 6;
    }
    if (list.startsWith("Ungated Plus")) {
      listBenefits = [
        "100% ungated (unrestricted) leads",
        "75leads / week",
        "Maximum 9 subscribers / list",
        "7-day, 100% money-back guarantee",
      ];
      cost.monthly = 159;
      cost.biannual = 75 * 6;
    }
    if (list.startsWith("Replen"))
      listBenefits = [
        "40% of leads are in the 'Beauty & Personal Care' category",
        "30% in 'Grocery & Gourmet Food'",
        "30% in 'Health & Household'",
        "60 leads / week",
        "Maximum 9 subscribers / list",
        " 7-day, 100% money-back guarantee",
      ];
    if (list.startsWith("Toys"))
      listBenefits = [
        "100% toys from top-selling brands",
        "50+ leads / week",
        "Maximum 9 subscribers / list",
        "Frequent brands: Pokemon, Melissa & Doug, Ty",
        // "Financial dashboard & tools",
        "7-day, 100% money-back guarantee",
      ];
    if (list.startsWith("Only Software")) {
      listBenefits = [
        "True Profit Tracking",
        "Per SKU Analysis",
        "Keep Track of All Sales",
        "Create Inbound Shipments",
        "In Depth Reports",
        "Sales Tax Calcuation",
      ];
      cost.monthly = 37;
      cost.biannual = "";
      cost.weekly = "";
    }

    let userContent = (
      <>
        <TextFieldGroup
          placeholder="Enter your email"
          label="Email Address"
          name="email"
          type="email"
          value={this.state.email}
          onChange={this.onChange}
          error={errors.email}
        />

        <TextFieldGroup
          label="Password"
          placeholder="Enter a password"
          name="password"
          type="password"
          value={this.state.password}
          onChange={this.onChange}
          error={errors.password}
        />
        {/* <Grid
          container
          style={{
            width: "calc(100% + 24px)",
            margin: "-12px",
            paddingTop: "12px",
          }}
        ></Grid> */}

        <br />
        <div className="wp-button-container">
          <button
            type="button"
            onClick={this.onSubmit}
            className="wp-style-button primary"
          >
            Continue
          </button>
        </div>
        <hr />
        <div style={{ textAlign: "center" }}>
          <a
            href={"/settings"}
            onClick={() => {
              window.top.location = "/settings";
            }}
            className={classes.link}
          >
            Already have an account?
          </a>
        </div>
      </>
    );

    let paymentContent = (
      <>
        <Grid item sm={12}>
          <TextFieldGroup
            label="First Name"
            placeholder="Enter your first name"
            name="firstName"
            value={this.state.firstName}
            onChange={this.onChange}
            error={errors.name}
          />
        </Grid>
        <Grid item sm={12}>
          <TextFieldGroup
            placeholder="Enter your last name"
            label="Last Name"
            name="lastName"
            value={this.state.lastName}
            onChange={this.onChange}
            error={errors.name}
          />
        </Grid>
        {
          // <TextFieldGroup
          //   placeholder="Promo Code"
          //   name="promoCode"
          //   type="promoCode"
          //   success={this.state.goodPromo}
          //   value={this.state.promoCode}
          //   onChange={this.onChange}
          //   error={this.state.promoError}
          // />
        }
        <div className="coupon-field-container">
          <Grid item sm={10}>
            <TextFieldGroup
              placeholder="Enter your coupon"
              label="Coupon Code"
              name="couponCode"
              value={this.state.couponCode}
              onChange={this.onChange}
              error={errors.name}
            />
          </Grid>
          <div item sm={2} className="apply-btn-container">
            <button
              type="button"
              onClick={this.handleCouponValidation}
              className="wp-style-button wp-style-apply-coupon-button primary"
            >
              Apply
            </button>
          </div>
        </div>
        {this.state.couponValidityStatus === false && (
          <Grid item sm={12}>
            <Typography className="coupon-invalid-alert">
              Coupon in not valid
            </Typography>
          </Grid>
        )}
        {this.state.couponValidityStatus && (
          <Typography className="coupon-valid-alert">
            {this.state.discountPercent}% discount applied!
          </Typography>
        )}
        <div>
          <StripeProvider stripe={stripe}>
            <Elements
              fonts={[
                {
                  cssSrc:
                    "https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700",
                },
              ]}
            >
              <CardForm
                label="Card Details"
                cardholder={`${this.state.firstName} ${this.state.lastName}`}
                email={email}
                errors={errors}
                pending={pending}
                submitNewPayment={this.submitNewPayment}
              />
            </Elements>
          </StripeProvider>
        </div>
      </>
    );

    return (
      <div className="container">
        <div className="spinner" hidden={!pending}></div>
        <div className={classes.root}>
          <form name="checkout">
            <MuiThemeProvider theme={theme}>
              <Grid container spacing={1}>
                <Grid item sm={12} md={6}>
                  <ReactCardFlip
                    isFlipped={this.state.isFlipped}
                    flipDirection="horizontal"
                    infinite={true}
                  >
                    <Paper key="front" className={classes.paper}>
                      <Typography variant="h6" className={classes.headline}>
                        <i className="fas fa-user"></i> Create Account
                      </Typography>
                      <br />
                      {userContent}
                    </Paper>
                    <Paper key="back" className={classes.paper}>
                      <Typography variant="h6" className={classes.headline}>
                        <i className="fas fa-user"></i> Payment Information
                      </Typography>
                      {paymentContent}
                    </Paper>
                  </ReactCardFlip>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.headline}>
                      <i className="fas fa-shopping-cart"></i> Order Summary
                      {/* <button
                        type="button"
                        onClick={(event) => {
                          this.setState({ anchorEl: event.currentTarget });
                        }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        disabled={pending}
                        className="wp-style-button primary-outline right-16"
                        style={{ padding: 5 }}
                      >
                        Change List
                      </button> */}
                      {/* <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => {
                          this.setState({ anchorEl: null });
                        }}
                      >
                        {listOptions.map((option, index) => (
                          <MenuItem
                            key={option}
                            onClick={(event) => this.changeList(event, index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Menu> */}
                    </Typography>

                    <Typography
                      variant="h6"
                      className={classes.headline}
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                      }}
                    >
                      {this.state.list}
                    </Typography>
                    <div
                      id="benefits"
                      style={{
                        display: "flex",
                        margin: "auto",
                      }}
                    >
                      <ul style={{ margin: "auto" }} className="fa-ul">
                        {listBenefits.map((row, i) => (
                          <li key={i}>
                            <i
                              style={{
                                color: "black",
                                color: "#029cea",
                              }}
                              className="far fa-check-circle"
                            ></i>{" "}
                            {row}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#029cea",
                        fontFamily: "Raleway",
                        fontWeight: 1000,
                      }}
                    >
                      <span style={{ fontSize: "18px" }}>$</span>
                      <span style={{ fontSize: "50px" }}>
                        {this.state.couponValidityStatus
                          ? plan === "monthly"
                            ? cost.monthly -
                              Number.parseFloat(
                                cost.monthly *
                                  (this.state.discountPercent / 100)
                              ).toFixed(2)
                            : plan === "biannual"
                            ? "" +
                              (cost.biannual -
                                Number.parseFloat(
                                  cost.biannual *
                                    (this.state.discountPercent / 100)
                                ).toFixed(2)) +
                              ""
                            : plan === "weekly"
                            ? cost.weekly -
                              Number.parseFloat(
                                cost.weekly(this.state.discountPercent / 100)
                              ).toFixed(2)
                            : ""
                          : plan === "monthly"
                          ? cost.monthly
                          : plan === "biannual"
                          ? "" + cost.biannual + "*"
                          : plan === "weekly"
                          ? cost.weekly
                          : ""}
                      </span>
                      <span
                        style={{
                          fontSize: "18x",
                          color: "black",
                        }}
                      >
                        {plan === "monthly"
                          ? "/mo"
                          : plan === "weekly"
                          ? "/wk"
                          : ""}
                      </span>
                    </div>
                    <div
                      hidden={pending || list === "Only Software"}
                      style={{
                        color: "#149cea",
                        textAlign: "center",
                        fontSize: "13px",
                      }}
                      className={classes.link}
                    >
                      {/* <div className="switch-toggle switch-ios">
                        <input
                          readOnly
                          id="monthly"
                          name="view"
                          type="radio"
                          checked={this.state.plan === "monthly"}
                        />
                        <label
                          htmlFor="monthly"
                          onClick={() => this.handleOptionChange("monthly")}
                        >
                          Monthly
                        </label>

                        <input
                          readOnly
                          id="biannual"
                          name="view"
                          type="radio"
                          checked={this.state.plan === "biannual"}
                        />
                        <label
                          htmlFor="biannual"
                          onClick={() => this.handleOptionChange("biannual")}
                        >
                          Biannually
                        </label>

                        <a></a>
                      </div>
                      {plan === "monthly" ? (
                        <Link
                          to={"/signup"}
                          onClick={() => {
                            this.setState({ plan: "biannual" }, () =>
                              this.checkPromoCode()
                            );
                          }}
                        >
                          Change to biannual payment and{" "}
                          <strong>
                            save ${cost.monthly * 6 - cost.biannual}
                          </strong>
                        </Link>
                      ) : plan === "weekly" ? (
                        <Link
                          to={"/signup"}
                          onClick={() => {
                            this.setState({ plan: "biannual" }, () =>
                              this.checkPromoCode()
                            );
                          }}
                        >
                          Change to biannual payment and{" "}
                          <strong>
                            save ${cost.weekly * 6 * 4 - cost.biannual}
                          </strong>
                        </Link>
                      ) : null} */}
                    </div>
                    <div
                      hidden={pending}
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                      }}
                    >
                      {plan === "monthly" || plan === "weekly"
                        ? ""
                        : "*$" +
                          (cost.biannual / 6).toFixed(2) +
                          " per month billed biannually*"}
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </MuiThemeProvider>
          </form>
        </div>
      </div>
    );
  }
}

Checkout.propTypes = {
  createLead: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(
  connect(mapStateToProps, { createLead, submitNewPayment })(
    withStyles(styles)(Checkout)
  )
);

//Cookie Setting for page
// function setCookie(cname, cvalue, exdays) {
//   var d = new Date();
//   d.setTime(d.getTime() + (exdays*24*60*60*1000));
//   var expires = "expires="+ d.toUTCString();
//   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// }
//
// function getCookie(cname) {
//   var name = cname + "=";
//   var decodedCookie = decodeURIComponent(document.cookie);
//   var ca = decodedCookie.split(';');
//   for(var i = 0; i <ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) == ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return undefined;
// }
//
// function getUrlVars() {
//     var vars = {};
//     var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
//         vars[key] = value;
//     });
//     return vars;
// }
//
// function checkNsetCookie(){
// 	var AAID = getUrlVars()["AAID"];
//     if (typeof(AAID) !== 'undefined'){
//     	var oldAAID = getCookie('AAID');
//     	if (typeof(oldAAID) === 'undefined'){
//     		setCookie('AAID', AAID, 60)
//
//     	}
//     }
// }
// checkNsetCookie();
