import axios from 'axios';

import {
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_UNGATING_TYPE,
  STOP_LOADING_UNGATING_TYPE,
  GET_UNGATING_TYPES,
  DELETE_UNGATING_TYPE,
  UPDATE_UNGATING_TYPE,
  CREATE_UNGATING_TYPE,
  GET_UNGATING_TYPE,
  LOADING_UPDATE_UNGATING_TYPE,
  LOADING_DELETE_UNGATING_TYPE,
  LOADING_CREATE_UNGATING_TYPE,
  LOADING_GET_UNGATING_TYPE,
  LOADING_GET_PUBLIC_UNGATING_TYPES,
  GET_PUBLIC_UNGATING_TYPES,
} from "./types";

const BASE_URL = '/api/ungating';

export const getPublicUngatingTypes = () => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoadingGetPublic());
  axios
    .get(`${BASE_URL}/public`)
    .then(res => {
      dispatch({
        type: GET_PUBLIC_UNGATING_TYPES,
        payload: res.data
      });
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      }
    });
};

export const getUngatingTypes = () => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  axios
    .get(BASE_URL)
    .then(res => {
      dispatch({
        type: GET_UNGATING_TYPES,
        payload: res.data
      });
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      }
    });
};

export const getUngatingType = (_id) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoadingGet());
  axios
    .get(`${BASE_URL}/${_id}`)
    .then(res => {
      dispatch({
        type: GET_UNGATING_TYPE,
        payload: res.data
      });
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      }
    });
};

export const deleteUngatingType = (_id) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoadingDelete());
  axios
    .delete(`${BASE_URL}/${_id}`)
    .then(res => {
      dispatch({
        type: DELETE_UNGATING_TYPE,
        payload: res.data
      });
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      }
    });
};

export const updateUngatingType = (_id, data) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoadingUpdate());
  axios
    .put(`${BASE_URL}/${_id}`, data)
    .then(res => {
      dispatch({
        type: UPDATE_UNGATING_TYPE,
        payload: res.data
      });
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      }
    });
};

export const createUngatingType = (data) => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoadingCreate());
  axios
    .post(BASE_URL, data)
    .then(res => {
      dispatch({
        type: CREATE_UNGATING_TYPE,
        payload: res.data
      });
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      }
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

export const setLoading = () => {
  return {
    type: LOADING_UNGATING_TYPE
  };
};

export const setLoadingUpdate = () => {
  return {
    type: LOADING_UPDATE_UNGATING_TYPE
  };
};

export const setLoadingDelete = () => {
  return {
    type: LOADING_DELETE_UNGATING_TYPE
  };
};

export const setLoadingCreate = () => {
  return {
    type: LOADING_CREATE_UNGATING_TYPE
  };
};

export const setLoadingGet = () => {
  return {
    type: LOADING_GET_UNGATING_TYPE
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING_UNGATING_TYPE
  };
};

export const setLoadingGetPublic = () => {
  return {
    type: LOADING_GET_PUBLIC_UNGATING_TYPES
  };
};
