import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextFieldGroup from "../common/TextFieldGroup";

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  button: {
    border: "0px",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "20px",
    marginLeft: "10px",
    backgroundColor: "#149cea",
    "&:hover": {
      border: "0px",
      backgroundColor: "#132f4a"
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
});

class FeedbackModal extends React.Component {
  state = {
    open: false,
    range: 30,
    checked: false,
    feedback: "",
    sent: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  sendFeedback = () => {
    const { feedback } = this.state;
    const { email } = this.props.user;
    const { row, flavor } = this.props;
    const toSend = {
      feedback,
      email,
      asin: row["ASIN"],
      title: row["Description"],
      flavor,
      date: row["Date"],
      buyLink: row["Product Link"]
    };
    this.props.sendFeedback(toSend);
    this.setState({ sent: true });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, row } = this.props;
    let modalContent;
    const { checked, sent } = this.state;
    let onClose = this.handleClose;
    if (this.state.open) {
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">
              Provide Feedback For {row["ASIN"]}
            </Typography>
            {onClose ? (
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>

          <DialogContent>
            We are always trying to improve the quality of our service. Please let us know your
            thoughts about this product. This will go directly to our sourcing
            team.
            <strong> Positive</strong> feedback is always welcome as well!😄
            <br />
            <br />
            Product Name:{" "}
            {row["Description"]
              ? row["Description"].slice(0, 50) + "..."
              : "N/A"}
            <br />
            <br />
            <TextFieldGroup
              placeholder={"Feedback for ASIN: " + row["ASIN"]}
              name="feedback"
              type="text"
              value={this.state.feedback}
              onChange={this.onChange}
              disabled={sent}
            />
            <div style={{ textAlign: "center" }}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.sendFeedback}
                disabled={sent}
              >
                Send Feedback for ASIN: {row["ASIN"]}
              </Button>
              <br />
              {sent ? (
                <span style={{ color: "green" }}>Feedback Sent!</span>
              ) : (
                " "
              )}
            </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      modalContent = <div></div>;
    }
    return (
      <span>
        <Button
          style={{
            margin: 0,
            minWidth: "16px"
          }}
          onClick={this.handleClickOpen}
        >
          <i className="fas fa-comment-alt"></i>
        </Button>
        {modalContent}
      </span>
    );
  }
}

FeedbackModal.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FeedbackModal);
