import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Typography,Radio,Button,Grid,Paper} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../common/Spinner';
import TextFieldGroup from '../common/TextFieldGroup';
import {toast} from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { fetchInvoices } from '../../actions/authActions';

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  button:{
    width:'100%',
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding:"5px",
    margin:"3px",
    backgroundColor: '#149cea',
    "&:hover": {
      color: "#132f4a"
    },
    "&:disabled":{
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
    "&:hover": {
      color: "white"
    },
  },
  input: {
    display: 'none',
  },
  table: {
    width: "100%",
    tableLayout: 'auto',
  },
  cell: {
    textAlign:'center',
    borderStyle: 'solid',
    borderWidth:"1px",
    color: 'black',
    padding:"3px"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  link: {
    color: "blue",
    textDecoration: "underline"
  },
  exitOptions:{
    //borderStyle:'groove',
    padding:12,
    height:300,
    //backgroundColor:'#69829B',
    //color:'white'
  } ,
  killOptions:{
    padding:12,
  }
});


class Invoices extends React.Component {
  state = {
    open: false,
    errors: {},
    auth:'',
    invoices: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      if (this.props.errors.subscription){
          this.handleClose();
          toast.error(this.props.errors.subscription);
      }
      this.setState({
        errors: this.props.errors
      });
    }
    if (prevProps.auth !== this.props.auth) {
      this.setState({ auth: this.props.auth });
      if (this.props.auth.data && this.props.auth.data.method === 'fetchInvoices'){
        this.setState({ invoices: this.props.auth.data.invoices });
      }
    }
  }

  handleClickOpen = () => {
  this.setState({open: true});
  if (this.state.invoices.length < 1) {
    this.fetchInvoices();
  }
};

  handleClose = () => {
    this.setState({ open: false});
  };

  fetchInvoices = () => {
    this.props.fetchInvoices();
  }


  render() {
    const { classes,auth } = this.props;
    const { user } = auth;
    const {errors, invoices} = this.state;
    let modalContent;
    let onClose=this.handleClose;
    let content = (
      <DialogContent>
    <div align="center" style={{textAlign:'center'}}>
    <div className="spinner" hidden={!auth.pending}></div>
     <table className={classes.table} >
       <thead>
         <tr>
           <th>Date</th>
           <th>Amount</th>
           <th>Receipt</th>
         </tr>
       </thead>
        <tbody>
             {invoices.map((row, i) => {
               return (
               <tr key={i}>
                  <td className={classes.cell} align="center">{row.dateCreated.slice(0,10)}</td>
                 <td className={classes.cell}  align="center">${Number(row.amount_due/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                 <td className={classes.cell}  align="center">
                   <Button
                    className={classes.button}
                    variant="contained" color="primary"
                    onClick={()=>{window.open(row.invoicePDF)}}
                   >
                   View Receipt
                   </Button>
                 </td>
                </tr>)
              })}
        </tbody>
     </table>
     </div>
   </DialogContent>);

    if (this.state.open){
      modalContent = (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={'sm'}
          fullWidth={true}
        >
        <MuiDialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">View Invoices</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>

          {content}
        </Dialog>
      );
    } else {
      modalContent = (
        <div></div>
      );
    }
    return (
      <div>
      <Button
      className={classes.button}
      variant="contained" color="primary"
      onClick={this.handleClickOpen}
      >
      View Receipts
      </Button>
        {modalContent}
      </div>
    );
  }
}

Invoices.propTypes = {
  classes: PropTypes.object.isRequired,
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps, { fetchInvoices })(withStyles(styles)(Invoices)))
