import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from '../common/Spinner';
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {Collapse,Radio, Drawer,Dialog,Grid,Checkbox, Paper, Link,Typography,TextField,Button,Tooltip,DialogActions,ExpansionPanel,ExpansionPanelDetails,ExpansionPanelSummary } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import  {RemoveSKUPop} from './Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as LinkIn } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {createShipmentPlan,prepInstructions,acceptShipment,downloadFNSKULabels} from '../../actions/shipmentActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 2
  },
  paper: {
    position: 'relative',
    padding: theme.spacing.unit * 2,
    //textAlign: 'center',
    color: 'black',
    minHeight: "332px"
  },
  table: {
    fontFamily:'Raleway',
    margin: 'auto',
    tableLayout: 'auto',
    height: "100%",
    textAlign: 'center',
    fontSize: "14px",
    width: '30%'
  },
  tableIn: {
    pading: '20px',
    fontFamily:'Raleway',
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
    tableLayout: 'auto',
    height: "100%",
    textAlign: 'center',
    fontSize: "14px",
    width: '70%',
    borderBottom: '2px solid #ddd',
  },
  button: {
    textTransform: 'none',
    margin: 0,
  },
  nextButton: {
    color:'white',
    backgroundColor: '#149cea',
    '&:hover': {
      backgroundColor: '#132f4a',
    },
    //position: 'absolute',
    textTransform: "none",
    //height: '30px',
    fontSize: "17px",
    width:"100px",
    // fontWeight: "normal",
    padding: "10px",
    margin: "3px",
    bottom: 10,
    verticalAlign: 'middle',
  },
  link: {
    color: "blue",
    textDecoration: "underline"
  },
  addressField: {
    margin: "0px",
    width: '150px',
    padding: '2px',
    height: '22px'
  },
  textField: {
    margin: "0px",
    maxWidth: "50px",
    padding: '2px',
    height: '22px'
  },
  cell: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: '250px',
    overflow: 'hidden',
    minHeight: "30px",
    height: "30px",
    maxHeight: "30px",
    fontSize: "14px",
    borderTop: '2px solid #ddd',
    borderBottom: '2px solid #ddd',
    padding: "15px",
    paddingRight: "10px",
    color: 'black'
  },
  cellIn: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: '250px',
    overflow: 'hidden',
    minHeight: "20px",
    height: "20px",
    maxHeight: "20px",
    fontSize: "14px",
    border: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    padding: "2px",
    paddingRight: "10px",
    color: 'black'
  },
  cell1: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: '250px',
    overflow: 'hidden',
    minHeight: "20px",
    height: "20px",
    maxHeight: "20px",
    fontSize: "14px",
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    padding: "2px",
    paddingRight: "10px",
    color: 'black'
  },
  tooltip: {
  boxShadow: theme.shadows[1],
  fontSize: 14,
}
});

class NewShipment extends React.Component {
    state = {
      drawer:false,
      skuList: [],
      casePacked:false,
      errors: {},
      address:{
        a_name:'',
        a_1:'',
        a_2:'',
        a_city:'',
        a_zip:'',
        a_state:'',
        a_phone:'',
        a_country:'US'
      },
      selfPrep:false,
      prep:[],
      removePopOpen:false,
      totalUnits:0,
      shipmentPlans:[],
      updated:false
    };

    componentDidMount = () => {
      if (this.props && this.props.location && this.props.location.state) {
        let loc = this.props.location.state;
        if (loc.skuList) {
          let skuList = loc.skuList;
          for (let i = 0;i<skuList.length;i++){
            if (!("shippingCost" in skuList[i])) {skuList[i].shippingCost = 0}
            if (!("prepCost" in skuList[i])) {skuList[i].prepCost = 0}
            if (!("etcCost" in skuList[i])) {skuList[i].etcCost = 0}
          }
          this.setState({
            skuList: loc.skuList
          },()=>this.calcTotalUnits());
          // this.props.prepInstructions({
          //   skuList: loc.skuList,
          //   address: this.state.address
          // })
        }
        if (loc.shipmentPlans) {
          this.setState({
            shipmentPlans: loc.shipmentPlans
          },()=>this.calcTotalUnits());
        }
        if (loc.address) {
          this.setState({
            address: loc.address
          },()=>this.calcTotalUnits());
        }
        if (loc.casePacked) {
          this.setState({
            casePacked: loc.casePacked
          },()=>this.calcTotalUnits());
        }
        if (typeof loc.selfPrep !== 'undefined') {
          this.setState({
            selfPrep: loc.selfPrep
          },()=>this.calcTotalUnits());
        }
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.errors !== this.props.errors) {
        this.setState({
          errors: this.props.errors
        });
      }
      if (prevProps.shipments !== this.props.shipments && this.props.shipments.prep && prevProps.shipments.prep !== this.props.shipments.prep) {
        if (this.props.auth.user.tier > 1) {
          let skuList = this.state.skuList;
          for (let i = 0; i < skuList.length; i++) {
            if (this.props.shipments.prep[i])
              skuList[i].prep = this.props.shipments.prep[i];
          }
          this.setState({
            skuList
          },()=>this.calcTotalUnits());
        }
      }
      if (prevProps.shipments !== this.props.shipments && this.props.shipments.shipmentPlans && prevProps.shipments.shipmentPlans !== this.props.shipments.shipmentPlans){
        this.setState({
          shipmentPlans:this.props.shipments.shipmentPlans
        });
      }
      if (prevProps.shipments !== this.props.shipments && this.props.shipments.acceptedShipment && prevProps.shipments.acceptedShipment !== this.props.shipments.acceptedShipment){
        let shipmentPlans = this.state.shipmentPlans;
        let newShipmentId = this.props.shipments.acceptedShipment.shipmentId
        let index = shipmentPlans.findIndex(function(e){
          return e.shipmentId === newShipmentId
        })
        shipmentPlans[index]=this.props.shipments.acceptedShipment
        this.setState({
          shipmentPlans
        });
      }

      if (prevProps.shipments !== this.props.shipments && this.props.shipments.fnskuLabels && prevProps.shipments.fnskuLabels !== this.props.shipments.fnskuLabels){
        let labels = this.props.shipments.fnskuLabels;
        if (labels && labels !== ''){
          const base64Data = labels
          var arrBuffer = base64ToArrayBuffer(base64Data);
          function base64ToArrayBuffer(data) {
              var binaryString = window.atob(data);
              var binaryLen = binaryString.length;
              var bytes = new Uint8Array(binaryLen);
              for (var i = 0; i < binaryLen; i++) {
                  var ascii = binaryString.charCodeAt(i);
                  bytes[i] = ascii;
              }
              return bytes;
          };
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          var data = window.URL.createObjectURL(newBlob);

          var link = document.createElement('a');
          document.body.appendChild(link); //required in FF, optional for Chrome
          link.href = data;
          link.download = "fnsku-labels.pdf";
          link.click();
          window.URL.revokeObjectURL(data);
          link.remove();
        }
      }
    }

    nextClick = (e) => {

      if (this.props.shipments.loading) return
      if (Number(this.props.match.params.page) === 1 && e.currentTarget.value === 'next'){
        if (this.state.skuList.length > 200){
          toast.dismiss();
          return toast.error('You can only send 200 SKUs per shipment. Please remove '+Number(this.state.skuList.length - 200 )+' SKUs and create a separate shipment for them');
        }
        if (this.state.skuList.length < 1){
          toast.dismiss();
          return toast.error('Please select SKUs from the Inventory page to ship in');
        }
        for (let i =0;i<this.state.skuList.length;i++){
          let units = this.state.skuList[i].units;
          let caseNumb = this.state.skuList[i].caseNumb;
          if (this.state.casePacked){
            if (caseNumb === '' || Number(caseNumb) === 0 || isNaN(caseNumb) || !caseNumb.replace(/\s/g, '').length){
              toast.dismiss();
              return toast.error('Please specify number of cases for '+ this.state.skuList[i].sku);
            }
            if (units > 150){
              toast.dismiss();
              return toast.error('Only 150 units allowed per case!');
            }
          }
          if (this.state.skuList[i].prep && this.state.skuList[i].prep && this.state.skuList[i].prep.PrepGuidance === 'ConsultHelpDocuments'){
            toast.dismiss();
            return toast.error('Please check SKUs with errors');
          }
          if (units === '' || Number(units) === 0 || isNaN(units) || !units.replace(/\s/g, '').length){
            toast.dismiss();
            return toast.error('Please specify number of units for all SKUs');
          }
        }
      }
      toast.dismiss();
      if (Number(this.props.match.params.page) === 2 && e.currentTarget.value === 'next'){
        if (this.state.updated)
        this.props.createShipmentPlan({
          skuList:this.state.skuList,
          address:this.state.address,
          casePacked:this.state.casePacked,
          selfPrep:this.state.selfPrep
        },this.props.history)
        this.setState({updated:false})
      }
      if (Number(this.props.match.params.page) === 3 && e.currentTarget.value === 'next'){
        for (let i = 0;i<this.state.shipmentPlans.length;i++){
          if (this.state.shipmentPlans[i].accepted !== true){
            toast.dismiss();
            return toast.error('Please accept all shipments');
          }
        }
        this.props.history.push({
          pathname: '/shipments/edit',
          state: {
            prevPgCreate:true,
            shipmentPlans:this.state.shipmentPlans,
            skuList:this.state.skuList,
            address:this.state.address,
            casePacked:this.state.casePacked,
            selfPrep:this.state.selfPrep
          }
        });
      }
      let pagenumb = 1
      if (e.currentTarget.value === 'back') pagenumb = -1;
      if ((1 < Number(this.props.match.params.page) && pagenumb < 0) || (Number(this.props.match.params.page) < 3 && pagenumb > 0)) {
        this.props.history.push({
          pathname: '/shipments/new/' + (Number(this.props.match.params.page) + pagenumb),
          state: {
            shipmentPlans:this.state.shipmentPlans,
            skuList:this.state.skuList,
            address:this.state.address,
            casePacked:this.state.casePacked,
            selfPrep:this.state.selfPrep
          }
        });
      }
    }

    handleClickOpen=()=>{
      this.setState({ removePopOpen: !this.state.removePopOpen });
    }

    removeSku = (i) => {
      let skuList = this.state.skuList;
      skuList.splice(i,1);
      this.setState({
        skuList,
        updated:true
      },()=>this.calcTotalUnits());
    }

    onChange = (event) => {
      //event.preventDefault();
      const target = event.target;
      let skuList = this.state.skuList;
      let shipmentPlans = this.state.shipmentPlans;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      if (name === 'casePacked'){
        this.setState({
          [name]:!this.state.casePacked,
          updated:true
        },() => this.calcTotalUnits());
        return
      }
      if ((isNaN(value) || (value.indexOf(".") >= 0)) && (name === 'units' || name === 'caseNumb')) {
        return
      }
      if (isNaN(value) && (name === 'shippingCost' || name === 'prepCost' || name === 'etcCost')) {
        return
      }
      if (name === 'selfPrep'){
        this.setState({
          [name]:!this.state.selfPrep,
          updated:true
        });
        return
      }
      if (name === 'shipment_name'){
        for (let i = 0; i < shipmentPlans.length; i++) {
          if (target.id === shipmentPlans[i].shipmentId){
            shipmentPlans[i][name] = value;
            this.setState({
              shipmentPlans,
              updated:true
            });
          }
        }
        return
      }
      for (let i = 0; i < skuList.length; i++) {
        if (target.id === skuList[i].sku){
          skuList[i][name] = value;
          this.setState({
            skuList,
            updated:true
          },() => this.calcTotalUnits());
        }
      }
    }

    calcTotalUnits = () => {
      let totalUnits = 0;
      let skuList = this.state.skuList;
      for (let i = 0; i < skuList.length; i++) {
        totalUnits += this.state.casePacked ? (Number(skuList[i].units) * Number(skuList[i].caseNumb)) : Number(skuList[i].units);
      }
      this.setState({
        totalUnits,
        updated:true
      });
    }

    addressChange = event => {
      const target = event.target;
      let address = this.state.address;
      const value = (target.type === 'checkbox' ||target.type === 'radio') ? target.checked : target.value;
      const name = target.name;
      address[name] = value
      this.setState({
        address,
        updated:true
      });
    }

    popOutShipment = i =>{
      let shipmentPlans = this.state.shipmentPlans
      shipmentPlans[i].collapse = !shipmentPlans[i].collapse
      this.setState({shipmentPlans})
    }

    acceptShipment = i =>{
      let shipment2send = this.state.shipmentPlans[i];
      shipment2send.ShipFromAddress=this.state.address;
      shipment2send.casePacked=this.state.casePacked;
      shipment2send.status='WORKING';
      this.props.acceptShipment(shipment2send,this.props.history);
    }

    toggleDrawer = (open) => () => {
      this.setState({
        drawer: open,
      });
    };

    onBoxChange = (i,event) => {
      event.preventDefault();
      const target = event.target;
      let shipmentPlans = this.state.shipmentPlans;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      if (name === 'sp' || name === 'partnered'){
        shipmentPlans[i][name]=!shipmentPlans[i][name];
      } else {
        shipmentPlans[i][name] = value;
      }
      this.setState({shipmentPlans})
    }

    downloadFNSKULabels = () => {
      const {skuList} = this.state;
      let payload = [];
      for (let i = 0; i < skuList.length; i++) {
        let caseNumb = skuList[i].caseNumb;
        if (typeof(skuList[i].fnsku) === 'undefined' || !skuList[i].fnsku){
          toast.dismiss();
          return toast.error('SKU: '+skuList[i].sku+' does not have an FNSKU (Cannot ship into FBA)');

        }
        payload.push({
          fnsku: skuList[i].fnsku,
          title: skuList[i].title,
          quantity: this.state.casePacked ? (Number(skuList[i].units) * Number(skuList[i].caseNumb)) : Number(skuList[i].units)
        })
      }
      this.props.downloadFNSKULabels({barcodes:payload})
    }
  render() {
    const collapseComponent = (props) => (
        <td colSpan={8}> {/* put the number of col of your table in this field */}
            <div className={props.className}>
                {props.children}
            </div>
        </td>
    )
    const { errors, skuList,shipmentPlans } = this.state;
    const { classes, match} = this.props;
    const {user} = this.props.auth;
    let currStep;
    let loading;
    let properTier;
    if (this.props.shipments.loading){
      loading = (
        <div align='center'><br/><br/>Checking with Amazon...{Number(match.params.page) === 3?null:"(takes ~ 2s per SKU)"}<br/><br/> <Spinner/><br/></div>
      )
    }
    if (Number(match.params.page) === 1){
      currStep = (
        <div>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
        <br/>
        <Grid container spacing={1}>
         <Grid item xs>
         <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
           Where are You Shipping From?
         </Typography>
         <br/>
         <div className="table-responsive">
          <table className={classes.table}>
            <tbody>
            <tr>
              <td className={classes.cell1} align="right">
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={"Could be your name or the name of the business you're shipping from (prepper, supplier, misc)."}
                placement={'right'}
                enterDelay={100}>
              <strong>Name*:</strong>
              </Tooltip>
              </td>
              <td className={classes.cell1} align="right">
             <input className={classes.addressField} name={'a_name'} value={this.state.address.a_name}
             onChange={this.addressChange}/>
             </td>
             </tr>

             <tr>
               <td className={classes.cell1} align="right"><strong>Address 1*:</strong></td>
               <td className={classes.cell1} align="right">
              <input className={classes.addressField} name={'a_1'} value={this.state.address.a_1}
              onChange={this.addressChange}/>
              </td>
              </tr>

              <tr>
                <td className={classes.cell1} align="right"><strong>Address 2:</strong></td>
                <td className={classes.cell1} align="right">
               <input className={classes.addressField} name={'a_2'} value={this.state.address.a_2}
               onChange={this.addressChange}/>
               </td>
               </tr>

               <tr>
                 <td className={classes.cell1} align="right"><strong>City*:</strong></td>
                 <td className={classes.cell1} align="right">
                <input className={classes.addressField} name={'a_city'} value={this.state.address.a_city}
                onChange={this.addressChange}/>
                </td>
                </tr>

                <tr>
                  <td className={classes.cell1} align="right"><strong>State/Province/Region*:</strong></td>
                  <td className={classes.cell1} align="right">
                 <input className={classes.addressField} name={'a_state'} value={this.state.address.a_state}
                 onChange={this.addressChange}/>
                 </td>
                 </tr>

                 <tr>
                   <td className={classes.cell1} align="right"><strong>Zip/ Postal Code*:</strong></td>
                   <td className={classes.cell1} align="right">
                  <input className={classes.addressField} name={'a_zip'} value={this.state.address.a_zip}
                  onChange={this.addressChange}/>
                  </td>
                  </tr>
                  <tr>
                    <td className={classes.cell1} align="right"><strong>Phone:</strong></td>
                    <td className={classes.cell1} align="right">
                   <input className={classes.addressField} name={'a_phone'} value={this.state.address.a_phone}
                   onChange={this.addressChange}/>
                   </td>
                   </tr>

            </tbody>
          </table>
          <br/><br/>
          </div>
         </Grid>
         <Grid item xs>
         <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
          How Many Units Are You Shipping?
         </Typography>
       <br/>
       <div className="table-responsive">
        <table className={classes.table}>
          <thead>
            <tr>
               <th className={classes.cell1}>Title</th>
               <th className={classes.cell1}>SKU</th>
               <th className={classes.cell1}>{this.state.casePacked?<span># of Units<br/>Per Case</span>:"# of Units"}</th>
               <th hidden={!this.state.casePacked} className={classes.cell1}># of Cases</th>
               <th className={classes.cell1}></th>
            </tr>
          </thead>
          <tbody >

               {this.state.skuList.map((row,i) => (
                 <tr key={i}>
                   <td className={classes.cell1} align="center">{row.title}</td>
                   <td className={classes.cell1} align="center">{row.sku+'  '}
                   {(row.prep && row.prep.PrepGuidance === 'ConsultHelpDocuments')?
                   <Tooltip
                     title={'Cannot Ship In This SKU.. Please Remove'}
                     placement={'bottom-start'}
                     enterDelay={100}
                   >
                   <span><i color='red' className="fas fa-exclamation-triangle"></i></span>
                   </Tooltip>
                   :null}
                   </td>
                   <td className={classes.cell1} align="center">
                  <input
                        id={row.sku}
                        className={classes.textField}
                        name={"units"}
                        value={row.units}
                        onChange={this.onChange}
                      />{'  '}
                  </td>
                    <td className={classes.cell1} align="center">
                   <input
                     className={classes.textField}
                     id={row.sku}
                     name="caseNumb"
                     value={row.caseNumb}
                     hidden={!this.state.casePacked}
                     onChange={this.onChange} />
                     </td>
                     <td className={classes.cell1} align="center">
                     <RemoveSKUPop
                        removeSku={this.removeSku}
                        i={i}
                        sku={row.sku}
                        title={row.title}
                     />
                     </td>
                 </tr>
               ))}

          </tbody>
        </table><br/>
        Are the Products Packed in Cases? :
        <Checkbox
          className={classes.textField}
          name="casePacked"
          type="checkbox"
          checked={this.state.casePacked}
          onChange={this.onChange}
        />
          {'  Total Units: '} <strong>{this.state.totalUnits}</strong>
        <br/><br/>
        </div>
        </Grid>
         </Grid>
          </form>
        </div>
      );
    } else if (Number(match.params.page) === 2){
      currStep = (
        <div>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
        <br/>
        <Grid container spacing={1}>
          <Grid item xs>
          <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
                How Would You Like to Label?
          </Typography>
            <br/>
            <div style={{marginLeft:'30px',lineHeight:'48px'}}>
              <label style={{lineHeight:'48px'}}>
                <Radio
                  type="radio"
                  name="selfPrep"
                  checked={this.state.selfPrep}
                  onChange={this.onChange}
                />
                {' '}<span  style={{verticalAlign: 'middle' }}>
                Self Label (Or Using a Prepper)</span>
              </label><br/>
              <label>
                <Radio
                  type="radio"
                  name="selfPrep"
                  checked={!this.state.selfPrep}
                  onChange={this.onChange}
                /><span style={{verticalAlign: 'middle' }}>
                {' '}Have Amazon Label ($0.20/unit)</span>
              </label>
              <br/>
              </div>
              {this.state.selfPrep?(
                <div>
                <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
                <Button variant="contained"
                  className={classes.button}
                  onClick={this.downloadFNSKULabels}
                  >
                  Download FNSKU (product) Labels
                </Button>
                </Typography><br/>
                <div style={{textAlign:"center",fontSize:'12px'}}>
                  *Print on <a className={classes.link} target="_blank" href="https://www.amazon.com/s?k=30+up+label+paper">30-up label paper</a> and cover all existing barcodes*
                </div>
                </div>
              ):null}

              <br/>
          </Grid>
          <Grid item xs>
          <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
                Any Extra Costs?
          </Typography>
          <p style={{"textAlign":"center",fontSize:12}}>Shipping from supplier, prep, misc</p>
          <br/>
          <div className="table-responsive">
           <table className={classes.table}>
             <thead>
               <tr>
                  <th className={classes.cell1}>Title</th>
                  <th className={classes.cell1}>SKU</th>
                  <th className={classes.cell1}>Ship/Unit</th>
                  <th className={classes.cell1}>Prep/Unit</th>
                  <th className={classes.cell1}>Misc/Unit</th>
               </tr>
             </thead>
             <tbody >

                  {this.state.skuList.map((row,i) => (
                    <tr key={i}>
                      <td className={classes.cell1} align="center">{row.title}</td>
                      <td className={classes.cell1} align="center">{row.sku}</td>
                      <td className={classes.cell1} align="center">
                     <input
                           id={row.sku}
                           className={classes.textField}
                           name={"shippingCost"}
                           value={row.shippingCost}
                           onChange={this.onChange}
                         />{'  '}
                     </td>
                     <td className={classes.cell1} align="center">
                    <input
                          id={row.sku}
                          className={classes.textField}
                          name={"prepCost"}
                          value={row.prepCost}
                          onChange={this.onChange}
                        />{'  '}
                    </td>
                    <td className={classes.cell1} align="center">
                   <input
                         id={row.sku}
                         className={classes.textField}
                         name={"etcCost"}
                         value={row.etcCost}
                         onChange={this.onChange}
                       />{'  '}
                   </td>
                    </tr>
                  ))}

             </tbody>
           </table>
           </div>
          </Grid>
         </Grid>
        </form>
        </div>
      );
    } else if (Number(match.params.page) === 3){
      currStep = (
        <div>
        <br/>
        <Grid container spacing={1}>
         <Grid item xs>
         Amazon is Asking To Ship To {shipmentPlans.length>1?'These':'This'} Location{shipmentPlans.length>1?'s':null}:<br/>
         {shipmentPlans.length>1?<div style={{fontSize:'11px'}}>
           If you want to ship to a single location, turn on{' "'}
           <Link className={classes.link} rel="noreferrer" target="_blank"
           href={"https://sellercentral.amazon.com/gp/ssof/configuration/inbound-settings.html"}>
           Inventory Placement Service
           </Link>
            {'" '}in Seller Central Settings and re-create the shipment. (They charge a fee of $0.30+ per unit{' '}
              <Button style={{minWidth:'15px',padding:'1px'}} className={classes.button} onClick={this.toggleDrawer(true)}><i color='blue' className="far fa-question-circle"/> </Button>)
           </div>:null}
         <br/><br/>
         <Drawer anchor="right" open={this.state.drawer} onClose={this.toggleDrawer(false)}>
          <div
            style={{maxWidth:'350px',padding:'10px'}}
            tabIndex={0}
            role="button"
            //onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
          <Typography className={classes.title} variant="h6" style={{textAlign:"center"}}>
           Inventory Placement Information
          </Typography><br/>
            Here is what Amazon has to say about Inventory Placement:<br/>
            <div style={{fontSize:"12px"}}>
            {inventoryPlacementInfo}
            </div>
          </div>
        </Drawer>
         <div className="table-responsive">
          <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.cell}></th>
               <th className={classes.cell}>Shipment Name</th>
               <th className={classes.cell}>Shipment ID</th>
               <th className={classes.cell}># of SKUs</th>
               <th className={classes.cell}># of Units</th>
               <th className={classes.cell}>Fulfillment Center</th>
               <th className={classes.cell}>Labeling</th>
               <th className={classes.cell}></th>
            </tr>
          </thead>

            {shipmentPlans.map((row,i) => (
              <tbody key={i+'body'}>
                <tr key={i}>
                <td className={classes.cell} align="center" onClick={()=>this.popOutShipment(i)}>
                <i className="fas fa-angle-double-down"/>
                </td>
                <td className={classes.cell} align="center">
                <input className={classes.addressField} id={row.shipmentId} name={'shipment_name'} value={row.shipment_name}
                onChange={this.onChange} autoComplete="off"/>
                </td>
                <td className={classes.cell} align="center">{row.shipmentId}</td>
                <td className={classes.cell} align="center">{row.skus.length}</td>
                <td className={classes.cell} align="center">{row.totalUnits}</td>
                <td className={classes.cell} align="center">
                  <div><strong>{row.fc}</strong><br/>{row.address.AddressLine1}
                  <br/>
                  {row.address.City+', '+row.address.StateOrProvinceCode+' '+row.address.PostalCode}
                  </div>
                </td>
                <td className={classes.cell} align="center">{row.selfPrep?'By Seller':'By Amazon'}</td>
                <td className={classes.cell} align="center">
                <Button variant="contained"
                  className={classes.button}
                  disabled={row.accepted}
                  onClick={()=>this.acceptShipment(i)}
                  >
                  <span style={{color:row.accepted?'green':'black'}}><i className="fas fa-check-circle"/>{row.accepted?'Accepted':'Accept'}</span>
                </Button>
                </td>
                </tr>
                <tr>
                  <Collapse
                   in = {row.collapse}
                   timeout="auto"
                   component={collapseComponent}
                   unmountOnExit
                   >
                   <div className="table-responsive">
                    <table className={classes.tableIn}>
                    <thead>
                      <tr>
                        <th className={classes.cellIn}>Title</th>
                        <th className={classes.cellIn}>SKU</th>
                        <th className={classes.cellIn}>Quantity</th>
                        <th className={classes.cellIn}>FNSKU</th>
                      </tr>
                    </thead>
                    <tbody key={i+'body'}>
                   {row.skus.map((item,j)=>(
                     <tr key={j}>
                     <td className={classes.cellIn} align="left">{item.title}</td>
                     <td className={classes.cellIn} align="center">{item.sku}</td>
                     <td className={classes.cellIn} align="center">{item.quantity}</td>
                     <td className={classes.cellIn} align="center">{item.fnsku}</td>
                     </tr>
                   ))}
                   </tbody>
                   </table>
                   </div>
                   <br/>

                  </Collapse>
                  </tr>
                  </tbody>
            ))}
          </table>
          <br/><br/>
          </div>
         </Grid>
         </Grid>
        </div>
      );
    } else {
      currStep = (
        <div>
        Not Found
        </div>
      );
      this.props.history.push({
        pathname: '/shipments/'
      });
    }
    properTier = (
      <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h5" id="tableTitle" style={{textAlign:"left"}}>
            Create a New Shipment to Amazon (Step {match.params.page} of 4)
      </Typography>
      {(this.props.shipments.loading)?loading:currStep}
      <br/><br/><br/>
        <Button variant="contained"
          value={'back'}
          style={{left:10,position:'absolute'}}
          className={classes.nextButton}
          onClick={this.nextClick}
          >
          Previous
        </Button>
        <Button variant="contained"
          style={{right:10,position:'absolute'}}
          value={'next'}
          className={classes.nextButton}
          onClick={this.nextClick}
          >
          {Number(match.params.page) === 3?"Next":"Next"}
        </Button>
        </Paper>
    );
  return (
    <div className="newshipment">
      <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs>
        {properTier}
        </Grid>
      </Grid>
      </div>
    </div>
  );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  shipments: state.shipments,
});

NewShipment.propTypes = {
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  shipments: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, { createShipmentPlan,prepInstructions,acceptShipment,downloadFNSKULabels })(withStyles(styles)(NewShipment)))

const inventoryPlacementInfo = (
  <div>
  <p>"By default, when you create a shipping plan, your shipment may be divided into multiple shipments, each directed to a different receive center or fulfillment center (referred to as distributed inventory placement). The fulfillment centers are selected based on the products you're shipping and where you are shipping from. By having your inventory spread across multiple fulfillment centers across the country, customers who buy your product may receive it faster than they would if your inventory was located farther away.</p>
<p>To better spread your products, Amazon may route your shipment through a receive center, which receives units and then reships them to other fulfillment centers in our network. Your products are available for sale as soon as we successfully receive them at any of our receive centers or fulfillment centers. Units that are being reshipped to other fulfillment centers are available for customers to buy, but customers may be shown a future ship date if no other units are available for immediate fulfillment.</p>
<p>You can sign up for the Inventory Placement Service and send all of your eligible inventory to a single receive center or fulfillment center (see the&nbsp;<strong>Inventory placement exceptions</strong>&nbsp;section below). When the shipment arrives, it is split up and sent to different fulfillment centers for you. A per-item service fee applies.</p>
<p>When you use the Inventory Placement Service, the destination receive center or fulfillment center is determined by Amazon. You cannot choose the receive center or fulfillment center to which you send your shipment. The destination chosen by Amazon may vary from shipment to shipment."</p>
  </div>
)
