import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextTransition, { presets } from 'react-text-transition';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Button, AppBar, Popover } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';

import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Actions
import { logoutUser, getUpdatedInfo, resendConfirmationEmail } from '../../actions/authActions';
import { getAnnounce } from '../../actions/announceActions';

import logo from '../common/logo.png';
import { ToastContainer } from 'react-toastify';

// Components
import TuturialDialog from './TutorialModal';
import ConfirmationModal from '../admin/ungating/ConfirmationModal';

// Utils
import * as SidebarUtils from 'utils/sidebar';

const navItems = [
  { faIcon: "gem", name: "OA Leads", link: "/oaleads" },
  { faIcon: "unlock", name: "Ungating", link: "/ungating" },
  // { faIcon: "tachometer-alt", name: "Dashboard", link: "/dashboard" },
  // { faIcon: "fas fa-warehouse", name: "Inventory", link: "/inventory" },
  // { faIcon: "shopping-cart", name: "Orders", link: "/orders" },
  // { faIcon: "shipping-fast", name: "Shipments", link: "/shipments" },
  // { faIcon: "chart-bar", name: "Reports", link: "/reports" },
  // { faIcon: "graduation-cap", name: "OA Course", link: "/oacourse" }
];
let isNavBarHidden = false;

if (
  [
    "/signup",
    "/ungatingsignup",
    "/login",
    "/register",
    "/confirm",
    "/ungatingpackages",
    "/loading",
  ].includes(
    window.location.pathname
  )
) {
  isNavBarHidden = true;
}

const drawerWidth = 125;

const styles = theme => ({
  navButton: {
    color: "black",
    "&:hover": {
      color: "black"
    },
    textTransform: "none",
    margin: 0,
    display: "inline",
    height: "33px"
  },
  button: {
    "&:hover": {
      color: "#7386D5",
      background: "#fff"
    }
  },
  root: {
    display: "flex"
  },
  appBar: {
    height: 50,
    backgroundColor: "#FFF",
    padding: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    color: "#000",
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    fontSize: 12,
    color: "#FFF",
    backgroundColor: "#132f4a",
    width: drawerWidth
  },
  drawerHeader: {
    backgroundColor: "#149cea",
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    marginTop: 50,
    // marginLeft:125,
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
    //marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  unverifiedEmailBox: {
    backgroundColor: '#ffb74d',
    padding: '5px 5px 5px 31px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    position: 'relative',
    zIndex: 1,
  },
  unverifiedClickHere: {
    color: '#149cea',
  },
  attentionText: {
    color: '#FF0000',
    fontWeight: 'bold',
  },
  announceBox: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: '#2196f3',
    color: '#fff',
    padding: '5px 5px 5px 31px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  weenkendCountDown: {
    backgroundColor :'#FD5454',
    padding: '9px 106px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
  },
  coundownText: {
    font: 'normal normal 600 20px/25px Quicksand',
  },
  coundownDay: {
    font: 'normal normal normal 20px/25px Quicksand',
  },
  timeName: {
    font: 'normal normal normal 12px/15px Quicksand',
  },
  countDownTimer: {
    display: 'flex',
    paddingRight: 23,
    paddingLeft: 23,
  },
  timeValue: {
    display: 'flex',
    justifyContent: 'space-around',
    font: 'normal normal 600 20px/25px Quicksand',
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    color: '#ffffff'
  },
  closeUnverifiedEmailButton : {
    position: "absolute",
    right: theme.spacing.unit,
    padding: "0px",
    outline: "none",
  }
});

class Sidebar extends React.Component {
  state = {
    open: true,
    anchorEl: null,
    onMobile: false,
    displayCoundtDown: true,
    displayUnverifiedEmail: true,
    showDisableUnverifiedEmailModal: false,
    nextLeadsLoad: SidebarUtils.getNextWeekday().set({
      hour: SidebarUtils.COUNTDOWN_OPTIONS.hours24,
      minute: SidebarUtils.COUNTDOWN_OPTIONS.minutes,
      second: SidebarUtils.COUNTDOWN_OPTIONS.seconds,
      millisecond: SidebarUtils.COUNTDOWN_OPTIONS.miliseconds,
    }).toDate(),
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleSendConfirmationEmail = event => {
    event.preventDefault();
    if (this.props.auth.isAuthenticated) {
      this.props.resendConfirmationEmail({ email: this.props.auth.user.email });
    }
  };

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
    if (this.props.getAnnounce) {
      this.props.getAnnounce();
    }
    this.setState({ displayUnverifiedEmail: this.shouldDisplayUnverifiedEmail() });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.setState({ displayCoundtDown: this.shouldDisplayWeekendCountdown() });
  }

  updateWindowDimensions = () => {
    this.setState({ onMobile: window.innerWidth <= 760 });
    if (window.innerWidth <= 760) this.handleDrawerClose();
    if (window.innerWidth > 760) this.handleDrawerOpen();
  };

  shouldDisplayWeekendCountdown = () => {
    const countdown = JSON.parse(localStorage.getItem('countdown'));
    const { user } = this.props.auth;
    if (
      countdown &&
      countdown.userId === user.id &&
      countdown.disabled
    ) {
      return false;
    } else {
      const now = SidebarUtils.getNow();
      const validWeekDays = [
        { day: 6 },
        { day: 7 },
        { day: 1, max: SidebarUtils.COUNTDOWN_OPTIONS.hours24 },
        { day: 2, max: SidebarUtils.COUNTDOWN_OPTIONS.hours24 },
        { day: 3, max: SidebarUtils.COUNTDOWN_OPTIONS.hours24 },
        { day: 4, max: SidebarUtils.COUNTDOWN_OPTIONS.hours24 },
        { day: 5, max: SidebarUtils.COUNTDOWN_OPTIONS.hours24 },
      ];

      const weekDay = validWeekDays.find(a => a.day === now.isoWeekday()); 
      if (weekDay) {
        if (weekDay.min !== undefined) {
          return weekDay.min <= now.get('hour');
        }
        if (weekDay.max !== undefined) {
          return weekDay.max > now.get('hour');
        }
        return true;
      }
      return false;
    }
  };

  shouldDisplayUnverifiedEmail = () => {
    const unverifiedEmail = JSON.parse(localStorage.getItem('unverifiedEmail'));
    const { user } = this.props.auth;
    if (
      unverifiedEmail &&
      unverifiedEmail.userId === user.id &&
      unverifiedEmail.disabled
    ) {
      return false;
    }
    return true;
  };

  handleDisableCountDown = () => {
    const { user } = this.props.auth;
    const countdown = {
      disabled: true,
      userId: user.id,
    };
    localStorage.setItem('countdown', JSON.stringify(countdown));
    this.setState({ displayCoundtDown: false });
  };

  handleDisableUnverifiedEmail = () => {
    const { user } = this.props.auth;
    const unverifiedEmail = {
      disabled: true,
      userId: user.id,
    };
    localStorage.setItem('unverifiedEmail', JSON.stringify(unverifiedEmail));
    this.setState({ displayUnverifiedEmail: false });
  };

  handleShowDisableUnverifiedEmailModal = () => {
    this.setState(prevState => ({
      showDisableUnverifiedEmailModal: !prevState.showDisableUnverifiedEmailModal,
    }));
  };

  render() {
    const { classes, announce } = this.props;
    const {
      open,
      anchorEl,
      onMobile,
      displayUnverifiedEmail,
      showDisableUnverifiedEmailModal,
      nextLeadsLoad,
    } = this.state;
    const { isAuthenticated, user } = this.props.auth;
    const weenkendCountDown = this.shouldDisplayWeekendCountdown();

    if (!isAuthenticated) isNavBarHidden = true;
    let adminContent;
    if (user.admin === true) {
      adminContent = (
        <>
          <Link to="/admin">
            <ListItem className={classes.button} button>
              <i className="fas fa-hammer"></i>
              {"   "}&nbsp;&nbsp;Admin
            </ListItem>
          </Link>
        </>
      );
    } else {
      adminContent = null;
    }
    const openPopper = Boolean(anchorEl);
    const updates = (
      <div style={{ margin: "auto" }}>
        <h6>
          <span role="img" aria-label="emoji">
            🔥
          </span>
          Recent Updates
          <span role="img" aria-label="emoji">
            🔥
          </span>
        </h6>
        <li>
          Atlas Access Ungating for{" "}
          <Link
            className="link"
            style={{ color: "blue", textDecoration: "underline" }}
            to="/ungating"
          >
            Pet Supplies
          </Link>{" "}
          is now availiable!
        </li>
        <li>
          <Link
            className="link"
            style={{ color: "blue", textDecoration: "underline" }}
            to="/ungating"
          >
            Atlas Access Ungating Service LIVE!
          </Link>{" "}
          Get Ungated in Toys and Topicals!
        </li>
        <li>
          <Link
            className="link"
            style={{ color: "blue", textDecoration: "underline" }}
            to="/affiliate"
          >
            Affiliate Program LIVE!
          </Link>
        </li>
        <li>
          1-Click & List added for{" "}
          <Link
            className="link"
            style={{ color: "blue", textDecoration: "underline" }}
            to="/oaleads"
          >
            OA Leads.
          </Link>{" "}
          Add products to inventory with 1 click!
        </li>
        <li>
          <Link
            className="link"
            style={{ color: "blue", textDecoration: "underline" }}
            to="/oaleads"
          >
            OA Leads Table
          </Link>{" "}
          added. See your leads right in the page!
        </li>
        <li>Shipments & Reports added!</li>
      </div>
    );
    const authLinks = (
      <List>
        {navItems.map((item, i) => (
          <Link key={i} to={item.link}>
            <ListItem key={"l" + i} className={classes.button} button>
              <i className={"fas fa-" + item.faIcon}></i>
              &nbsp;&nbsp;{item.name}
            </ListItem>
          </Link>
        ))}
        {adminContent}
        <a href=" " onClick={this.onLogoutClick.bind(this)}>
          <ListItem className={classes.button} button>
            <i className="fas fa-sign-out-alt"></i>
           &nbsp;&nbsp;Logout
          </ListItem>
        </a>
      </List>
    );
    const guestLinks = null;
    if (isNavBarHidden) return null;
    else
      return (
        <div className={classes.root}>
          <CssBaseline />
          <ToastContainer />
          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open
            })}
          >
            <Toolbar style={{ minHeight: 50 }} disableGutters={!open}>
              {open && onMobile ? (
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              ) : null}
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <TuturialDialog classes={classes} onMobile={onMobile} />

              <div style={{ position: "absolute", right: 0 }}>
                {/* <Button
                  className={classes.navButton}
                  onClick={this.props.getUpdatedInfo}
                >
                  <i className="fas fa-sync-alt"></i>
                  {onMobile ? null : " Resync"}
                </Button> */}
                <Button
                  className={classes.navButton}
                  aria-owns={openPopper ? "simple-popper" : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <i className="fas fa-bullhorn"></i>
                  {onMobile ? null : " Updates"}
                </Button>
                <Popover
                  id="simple-popper"
                  open={openPopper}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <div style={{ padding: "15px", maxWidth: "350px" }}>
                    {updates}
                  </div>
                </Popover>

                <Link to="/Settings">
                  <Button className={classes.navButton}>
                    <i className="fas fa-cogs"></i>
                    {onMobile ? null : " Settings"}
                  </Button>
                </Link>
              </div>
            </Toolbar>
          </AppBar>
          {onMobile ? (
            <Drawer
              className={classes.drawer}
              variant="temporary"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerClose}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              <div style={{ minHeight: 50 }} className={classes.drawerHeader}>
                <img
                  src={logo}
                  style={{ width: "30px", margin: "auto", display: "block" }}
                  alt="atlas"
                />
              </div>
              <Divider />
              {isAuthenticated ? authLinks : guestLinks}
            </Drawer>
          ) : (
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <div style={{ minHeight: 50 }} className={classes.drawerHeader}>
                <img
                  src={logo}
                  style={{ width: "30px", margin: "auto", display: "block" }}
                  alt="atlas"
                />
              </div>
              <Divider />
              {isAuthenticated ? authLinks : guestLinks}
            </Drawer>
          )}
          <main
            className={classNames(classes.content, {
              [classes.contentShift]: open
            })}
          >
            {/* Weekend count down banner */}
            {(isAuthenticated && weenkendCountDown) && (
              <div className={classes.weenkendCountDown}>
                <span className={classes.coundownText}>Online arbitrage leads will be released in</span> 
                <Countdown
                  date={nextLeadsLoad}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <span className={classes.countDownTimer}>
                      <div>
                        <div className={classes.timeValue}>
                          <TextTransition
                            text={days * 24 + hours}
                            springConfig={ presets.wobbly}
                            className={classes.timeNumber}
                          />:
                        </div>
                        <span className={classes.timeName} style={{paddingRight: 18}}>HOUR</span>
                      </div>
                      <div>
                        <div className={classes.timeValue}>
                          <TextTransition
                            text={minutes}
                            springConfig={ presets.wobbly }
                            className={classes.timeNumber}
                          />:
                        </div>
                        <span className={classes.timeName} style={{paddingRight: 18}}>MINS</span>
                      </div>
                      <div>
                        <div className={classes.timeValue}>
                          <TextTransition
                            text={seconds}
                            springConfig={ presets.wobbly }
                            className={classes.timeNumber}
                          />
                        </div>
                        <span className={classes.timeName}>SECS</span>
                      </div>
                    </span>
                  )}
                />
                <span className={classes.coundownDay}>
                  Monday-Friday at {SidebarUtils.COUNTDOWN_OPTIONS.hours12 + SidebarUtils.COUNTDOWN_OPTIONS.ampm} EST (GMT -5)
                </span>
                <IconButton
                  aria-label="Close"
                  className={classes.closeButton}
                  onClick={this.handleDisableCountDown}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            {/* /Weekend count down banner */}

            {(isAuthenticated && announce && announce.text) && (
              <div className={classes.announceBox}>
                <span className={classes.attentionText}>ATTENTION:</span> {announce.text}
              </div>
            )}
            {
              isAuthenticated
              && user
              && !user.isVerified
              && (!this.props.auth.email || !this.props.auth.email.sent)
              && displayUnverifiedEmail
              && (
                <div className={classes.unverifiedEmailBox}>
                  Hey! Look's like your email is not confirmed. Please confirm it!{' '}
                  If you didn't receive an email confirmation,{' '}
                  <a href="#"
                    className={classes.unverifiedClickHere}
                    onClick={this.handleSendConfirmationEmail}
                  >
                    click here
                  </a>! Check the “Updates” tab of your inbox
                  <IconButton
                    aria-label="Close"
                    className={classes.closeUnverifiedEmailButton}
                    onClick={this.handleShowDisableUnverifiedEmailModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )
            }
            {this.props.routes}
          </main>

          {/* Modals */}
          {
            (showDisableUnverifiedEmailModal) ?
              <ConfirmationModal open={showDisableUnverifiedEmailModal}
                onClose={this.handleShowDisableUnverifiedEmailModal}
                onConfirm={this.handleDisableUnverifiedEmail}
                confirmationText={
                  `Are you sure you want to close this banner? It should disappear automatically when you click the link in your confirmation email.
                  (Can’t find the confirmation email? Check your “Promos” tab in Gmail, or check your Spam folder.)`
                }
                confirmLabel="Ok"
                severity="info"
              /> :
              null
          }
        </div>
      );
  }
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  announce: state.announce,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      logoutUser,
      getUpdatedInfo,
      resendConfirmationEmail,
      getAnnounce,
    })(
    withStyles(styles, { withTheme: true })(Sidebar)
  )
);
