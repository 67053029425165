import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from '../common/Spinner';
import logo from '../common/logo.png';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { getAffiliatePayments, affSignUp , changePaypalEmail } from '../../actions/affiliateActions';


const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit *2
  },
  paper: {
    position: 'relative',
    padding: theme.spacing.unit * 2,
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    // minHeight: "332px"
  },
  button:{
    border:'0px',
    textTransform: "none",
    fontWeight: "normal",
    fontSize:'18px',
    lineHeight:'20px',
    backgroundColor: '#149cea',
    "&:hover": {
      border:'0px',
      backgroundColor: "#132f4a"
    },
    "&:disabled":{
      border:'0px',
      color: 'white',
      backgroundColor: '#919EAA',
    },
    color: 'white',
  },
  link:{
    color:"blue",
    textDecoration: "underline"
  },
  dateWrapper:{
    display:"inline",
    textTransform: "none",
    fontWeight: "normal",
  },
  table: {
    tableLayout: 'auto',
    border: '1px solid #dddddd',
  },
  table: {
    fontFamily:"Quicksand,serif",
    margin:'auto',
    //minWidth: "100px",
    tableLayout: 'auto',
    height:"100%",
    textAlign:'center',
    fontSize: "14px",
    width: '95%'
  },
  cell: {
    whiteSpace: "wrap",
    lineHeight: '15px',
    height: '45px',
    textOverflow: "hidden",
    minWidth:'50px',
    maxWidth: "150px",
    overflow: "hidden",
    padding: "2px",
    border: '1px solid #dddddd',
    maxHeight:'45px',
  },
  innerCell:{
    whiteSpace: "wrap",
    lineHeight: '15px',
    minWidth:'32px',
    height: '45px',
    textOverflow: "hidden",
    maxWidth: "150px",
    overflow: "hidden",
    maxHeight:'45px',
  },
  row:{
    height: '45px',
    maxHeight:'45px',
  }
});

class Affiliate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate:new Date(),
      errors: {},
      affiliatePayments:[],
      paypalEmail:''
    };
  }

  componentDidMount = () => {
    this.props.getAffiliatePayments();
  }

  handleDateChange = (e) => {
      this.setState({
        dateChanged:true,
        startDate: new Date(e)
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.errors !== this.props.errors) {
        this.setState({
          errors: this.props.errors
        });
      }
      if (prevProps.auth !== this.props.auth && this.props.auth.paypalEmail){
        this.setState({paypalEmail:this.props.auth.paypalEmail})
      }
      if (prevProps.auth !== this.props.auth && this.props.auth.data && this.props.auth.data.affiliatePayments) {
        this.setState({affiliatePayments:this.props.auth.data.affiliatePayments})
      }
    }

  setNewDates = (startDate, endDate) => {
    startDate = startDate.setHours(0, 0, 0, 0);
    endDate = endDate.setHours(0, 0, 0, 0);
    if (startDate > endDate){
      toast.error("Start Date must be older than End Date!");
      return 0;
    }
    this.setState({dateChanged:true,startDate:(new Date(startDate)),endDate:new Date(endDate)});
  }

  affSignUp = () => {
    this.props.affSignUp()
  }

  changePaypalEmail = (event) => {
    event.preventDefault()
    this.props.changePaypalEmail({email:this.state.paypalEmail})
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    const { classes } = this.props;
    const {errors} = this.state;
    const {user,pending} = this.props.auth;
    const errorTest = Object.keys(errors).length;

    let newAffInfo = (
      <div>
      <br/>
      Want to be an affiliate for Atlas and start receiving <strong>30% lifetime recurring</strong> commisions for every user you refer?<br/><br/>
      <div style={{textAlign:'center',}}><Button variant="contained" color="primary" onClick={this.affSignUp}className={classes.button}>Become An Affiliate!</Button></div>
      </div>
    )

    let existingAffInfo = (
      <div><br/>
      Welcome to your affiliate dashboard!<br/>
      Your current commission rate is: <strong>{user.affiliateCommission*100}% recurring</strong> or $100 for one time purchases (like Atlas Access)<br/>
      Your Atlas Affiliate ID is: <strong>{user.myAAID}</strong><br/>
      Simply add <strong>?AAID={user.myAAID}</strong> to the end of any go-atlas.io direct link to make it an affiliate link!<br/>
      For example: <a className={classes.link} rel="noreferrer" target="_blank" href={"https://go-atlas.io/onlinearbitrageleads?AAID="+user.myAAID}>https://go-atlas.io/onlinearbitrageleads?AAID={user.myAAID}</a><br/>
      Your PayPal email (how we will pay you) is: <strong>{user.paypalEmail}</strong>. Change it using the box below.<br/><br/>
      <form  onSubmit={this.changePaypalEmail}>
      <TextFieldGroup
        placeholder={"PayPal Email"}
        style={{width:250}}
        name="paypalEmail"
        type="email"
        value={this.state.paypalEmail}
        onChange={this.handleInputChange}
        error={errors.email}
      />
      <Button style={{width:250}} type="submit" variant="contained" color="primary"className={classes.button}>Change PayPal Email</Button>
    </form>
      </div>
    )

    let affTable = (
        <Paper className={classes.paper}>
        <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left"}}>
              Affiliate Commissions
        </Typography>
        {this.props.auth.pending?<div style={{textAlign:'center'}}><br/>Loading...<Spinner/></div>:
        <div className="table-responsive">
         <table className={classes.table}>
           <thead>
             <tr>
               {user.admin?<th>Transaction #</th>:null}
               <th>Referral Date</th>
               <th>Revenue</th>
               <th>Rate</th>
               <th>Commission</th>
               {user.admin?<th>Affiliate Email</th>:null}
               {user.admin?<th>AAID</th>:null}
               <th>Referred User</th>
               <th>Payment Date</th>
               {user.admin?<th>Pay?</th>:null}
             </tr>
           </thead>
           <tbody >
                {this.state.affiliatePayments.map((row,i) => (
                  <tr key={i}>
                    {user.admin?<td className={classes.cell} style={{color: 'black'}} padding="none" align="center">
                    <a className={classes.link} rel="noreferrer" target="_blank" href={"https://dashboard.stripe.com/payments/"+row.transNumber}>{row.transNumber}</a>
                    </td>:null}
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{row.date?(new Date(row.date).toLocaleString().split(',')[0]):null}</td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${row.total.toFixed(2)}</td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{Math.round(row.commission/row.total*100)}%</td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">${row.commission.toFixed(2)}</td>
                    {user.admin?<td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{row.emailOfReferrer}</td>:null}
                    {user.admin?<td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{row.referrerAAID}</td>:null}
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">
                    {user.admin?row.emailOfReferred:row.emailOfReferred.slice(0,3)+'******@'+row.emailOfReferred.split('@')[1]}
                    </td>
                    <td className={classes.cell} style={{color: 'black'}} padding="none" align="center">{row.dueDate?(new Date(row.dueDate).toLocaleString().split(',')[0]):null}</td>
                    {user.admin?<td className={classes.cell} style={{color: 'black'}} padding="none" align="center">PAY!</td>:null}
                  </tr>
                ))}
           </tbody>
         </table>
         </div>
      }

        </Paper>
      )


    return (
        <div className="Affiliate">
          <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
            <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h6" id="tableTitle" style={{textAlign:"left"}}>
                  Affiliate Area {user.admin?'(For Site Admin)':null}
            </Typography>
              {user.myAAID?existingAffInfo:newAffInfo}
            </Paper>
            </Grid>
            <Grid item xs={12}>
            {affTable}
            </Grid>
          </Grid>
          </div>
        </div>
    );
    }
}

Affiliate.propTypes = {
  errors:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps, { getAffiliatePayments,affSignUp , changePaypalEmail})(withStyles(styles)(Affiliate)))
