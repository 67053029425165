import React from 'react';

const Loading = () => {
  React.useEffect(() => {
    document.body.classList.add('hide-fc');
  }, []);

  return (
    <div className="spinner" />
  );
};

export default Loading;
